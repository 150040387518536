/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import appHelpers from '../../helpers/app-urls';
import mainLogoWhite from '../../assets/images/logo-white.svg';

interface MetaProps {
    name?: string;
    content?: string;
}

interface SEOProps {
    description?: string;
    image?: string;
    keywords?: string;
    lang?: string;
    meta?: MetaProps[];
    page?: string;
    title?: string;
}

const URL = appHelpers.getSiteUrl();
const { REACT_APP_ENV } = process.env;

const SEO: FunctionComponent<SEOProps> = ({
    description = '',
    lang = 'en',
    meta = [],
    title = 'Altify',
    keywords = 'investing, investment platform, cryptocrurency, top 10 cryptocurrency, top cryptocurrency, top performing crypto, diversification, diversified investing',
    page = '',
    image = '',
}: SEOProps) => {
    const noIndex =
        REACT_APP_ENV !== 'Production'
            ? {
                  name: 'robots',
                  content: 'noindex',
              }
            : {};

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title || 'Altify'}
            meta={[
                {
                    name: 'viewport',
                    content:
                        'width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no',
                },
                {
                    name: 'description',
                    content: description,
                },
                {
                    name: 'keywords',
                    content: keywords,
                },
                {
                    name: 'og:title',
                    content: title,
                },
                {
                    name: 'og:url',
                    content: `${URL}${page}`,
                },
                {
                    name: 'og:description',
                    content: description,
                },
                {
                    name: 'og:type',
                    content: 'website',
                },
                {
                    property: 'og:image',
                    content: image ? `${URL}${image}` : `${URL}${mainLogoWhite}`,
                },
                noIndex,
                // @ts-ignore
            ].concat(meta)}
        />
    );
};

export default SEO;
