/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPageConfigAction } from 'store/app/app-actions';
import { fetchReferencesAssetsAction } from 'store/productsV2/productsV2-actions';

import SideNav from '../components/SideNav';
import { RootState } from '../store/reducer';
import { setTheme } from '../store/slices/theme-slice';

const DEFAULT_THEME = 'dark';

const { REACT_APP_CLARITY_KEY } = process.env;

export default function PrivateLayout({ children }: { children: React.ReactNode }) {
    const dispatch = useDispatch();
    const { theme } = useSelector((state: RootState) => state.theme);

    useEffect(() => {
        // @ts-ignore
        if (['Development', 'QA', 'Staging'].includes(process.env.REACT_APP_ENV)) return;

        const setupClarity = () => {
            // @ts-ignore
            window.clarity =
                // @ts-ignore
                window.clarity ||
                function () {
                    // @ts-ignore
                    // eslint-disable-next-line prefer-rest-params
                    (window.clarity.q = window.clarity.q || []).push(arguments);
                };
            const element = document.createElement('script');
            // @ts-ignore
            element.async = 1;
            element.src = `https://www.clarity.ms/tag/${REACT_APP_CLARITY_KEY}`;
            const newScript = document.getElementsByTagName('script')[0];
            // @ts-ignore
            newScript.parentNode.insertBefore(element, newScript);
        };
        setupClarity();
    }, []);

    useEffect(() => {
        if (theme !== DEFAULT_THEME) {
            dispatch(setTheme(DEFAULT_THEME));
        }
        // FullStory.init({ orgId: 'G8MN8' });
        // DO NOT FETCH ALL CONTENT WHEN USER IS LOGGED IN
        dispatch(fetchReferencesAssetsAction(false));
        dispatch(fetchPageConfigAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (() => {
            const d = document;
            const s = d.createElement('script');
            s.id = 'ze-snippet';
            s.src =
                'https://static.zdassets.com/ekr/snippet.js?key=c3406489-488b-403b-8f38-e68a97af7c82';
            s.async = true;
            d.getElementsByTagName('head')[0].appendChild(s);
        })();
    }, []);

    return <SideNav>{children}</SideNav>;
}
