import axios from 'axios';
import appHelpers from '../helpers/app-urls';

const cdnURL = appHelpers.getCDNUrl();

// TODO: once https://revix-dev-cdn-public.azureedge.net has the graphs, remove hard coding.

const cdnApi = axios.create({
    // baseURL: 'https://revix-prod-cdn-public.azureedge.net/', // cdnURL,
    baseURL: cdnURL,
});

export default cdnApi;
