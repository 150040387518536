/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BtcDetails, BtcDetailsResponse } from '../../entities/btcDetails';

export interface BtcDetailsState {
    loading: boolean;
    error?: boolean;
    errorMessage?: any;
    btcDetails: BtcDetails;
    btcDetailsResponse: BtcDetailsResponse;
}

const initialState: BtcDetailsState = {
    loading: false,
    error: false,
    errorMessage: '',
    btcDetails: {
        address: '',
        name: '',
        accountId: '',
        twofaPin: '',
    },
    btcDetailsResponse: {
        message: '',
        statusCode: 0,
        userId: '',
    },
};

const btcDetails = createSlice({
    name: 'btcDetails',
    initialState,
    reducers: {
        addBtcDetailsStart(state) {
            state.loading = true;
            state.error = false;
            state.errorMessage = '';
        },
        addBtcDetailsEnd(state) {
            state.loading = false;
        },
        addBtcDetailsError(state, action: PayloadAction<BtcDetailsResponse | any>) {
            state.loading = false;
            state.error = true;
            state.errorMessage = action.payload;
            state.btcDetailsResponse = action.payload;
        },
        setAddBtcDetailsResponse(state, action: PayloadAction<BtcDetailsResponse>) {
            state.btcDetailsResponse = action.payload;
        },
        getBtcDetailsStart(state) {
            state.loading = true;
        },
        getBtcDetailsEnd(state) {
            state.loading = false;
        },
        getBtcDetailsError(state, action: PayloadAction<BtcDetails>) {
            state.loading = false;
            state.btcDetails = action.payload;
        },
        getAddBtcDetailsData(state, action: PayloadAction<BtcDetails>) {
            state.loading = false;
            state.btcDetails = action.payload;
        },
        resetBTCDetailsError(state) {
            state.error = false;
            state.errorMessage = '';
        },
    },
});

export const {
    addBtcDetailsStart,
    addBtcDetailsEnd,
    setAddBtcDetailsResponse,
    addBtcDetailsError,
    getAddBtcDetailsData,
    getBtcDetailsEnd,
    getBtcDetailsError,
    getBtcDetailsStart,
    resetBTCDetailsError,
} = btcDetails.actions;

export default btcDetails.reducer;
