import { Language } from '../../store/slices/language-slice';

interface TourMessages {
    tour: {
        gotItBtn: string;
        skipTourBtn: string;
        endTourBtn: string;
        verifyBtn: string;
        referBtn: string;
        newStep1: string;
        newStep2: string;
        newStep3: string;
        newStep4: string;
        newStep5: string;
        newStep6: string;
        newStep7: string;
        newStep8: string;
        newStep9: string;
        existingStep1: string;
        existingStep2: string;
        existingStep3: string;
        existingStep4: string;
        existingStep5: string;
        existingStep6: string;
        existingStep7: string;
        existingStep8: string;
        existingStep9: string;
    };
}

type Message = {
    [key in Language]: TourMessages;
};

const messages: Message = {
    eng: {
        tour: {
            gotItBtn: 'Got it!',
            skipTourBtn: 'Skip tutorial',
            endTourBtn: 'End tutorial',
            verifyBtn: 'Verifiy account',
            referBtn: 'Refer a friend',
            newStep1: `Welcome to your dashboard. The portfolio tab lets you know how your investments are performing and what funds you have available.`,
            newStep2: `Once you have made an investment it will be displayed under “Your Holdings”.`,
            newStep3: `When you make an investment a performance graph will be shown here showing how your portfolio has performed over time.`,
            newStep4: `In the products tab you can view all of our available products. Clicking on a product will take you to a page with in-depth information about that product.`,
            newStep5: `In the funds tab you can view your fiat currency balance as well as deposit and withdraw funds.`,
            newStep6: `Your available Rewards Points are shown here. Once you get to 200 Points you’ll be able to redeem your Points for Bitcoin.`,
            newStep7: `You can view your Rewards tier here. The more lifetime Points you earn, the more benefits you receive.`,
            newStep8: `We gave you 5 Points to start your journey, and you can complete the tasks below to earn even more. <br/><br/> Hint: Referrals can be done as many times as you like, and earn you the most points. Clicking on your referral code makes it easier than ever to share it via Facebook, Twitter, WhatsApp and email.`,
            newStep9: `In the profile tab you can view and update your account information, but before you can really get started, we need to verify your account. <br/><br/> Click the button below to verify your account now, or if you want to do it later, you can click the purple ‘Verify account’ button on the dashboard screen.`,
            existingStep1: `Welcome to your dashboard. The portfolio tab lets you know how your investments are performing and what funds you have available.`,
            existingStep2: `Once you have made an investment it will be displayed under “Your Holdings”.`,
            existingStep3: `Performance tracks the overall performance of your portfolio which includes your holdings and cash balances.`,
            existingStep4: `In the products tab you can view all of our available products. Clicking on a product will take you to a page with in-depth information about that product.`,
            existingStep5: `In the funds tab you can view your fiat currency balance as well as deposit and withdraw funds.`,
            existingStep6: `Your available Rewards Points are shown here. Once you get to 200 Points you’ll be able to redeem your Points for Bitcoin.`,
            existingStep7: `You can view your Rewards tier here. The more lifetime Points you earn, the more benefits you receive.`,
            existingStep8: `We gave you 5 Points to start your journey, and you can complete the tasks below to earn even more. `,
            existingStep9: `Referrals can be done as many times as you like, and earn you the most points. <br/><br/> Clicking on this makes it easier than ever to share your referral code with your network on Facebook, Twitter, WhatsApp or via email.`,
        },
    },
};

export default messages;
