import * as React from 'react';
import { isMobileOnly, isTablet, isBrowser, isMacOs } from 'react-device-detect';

const Device = () => {
    const getDevice = () => {
        return {
            isDeviceMobile: isMobileOnly,
            isDeviceTablet: isTablet,
            isDeviceDesktop: isBrowser,
            isMac: isMacOs,
        };
    };

    const [mobileDevice, setDevice] = React.useState(getDevice);

    React.useEffect(() => {
        function handleResize() {
            setDevice(getDevice());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return mobileDevice;
};

export default Device;
