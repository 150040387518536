export interface ISorting {
    key: string;
    direction: 'asc' | 'desc';
}

export const orderBy = (key: string, direction: 'asc' | 'desc') => (objectA: any, objectB: any) => {
    const valueA = objectA?.[key];
    const valueB = objectB?.[key];

    if (typeof valueA === 'string') {
        if (valueA?.toLowerCase() > valueB?.toLowerCase()) {
            return direction === 'asc' ? 1 : -1;
        }
        if (valueA?.toLowerCase() < valueB?.toLowerCase()) {
            return direction === 'asc' ? -1 : 1;
        }
    } else {
        if (valueA > valueB) {
            return direction === 'asc' ? 1 : -1;
        }
        if (valueA < valueB) {
            return direction === 'asc' ? -1 : 1;
        }
    }
    return 0;
};

export const nestedKeySort = (list: any, keyArr: string[], direction?: 'asc' | 'desc') => {
    let sortOrder = 1;
    if (direction === 'desc') sortOrder = -1;

    return list.sort((a: any, b: any) => {
        let x = a;
        let y = b;

        for (let i = 0; i < keyArr.length; i++) {
            x = x[keyArr[i]];
            y = y[keyArr[i]];
        }

        return sortOrder * (x < y ? -1 : x > y ? 1 : 0);
    });
};
