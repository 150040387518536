/* eslint-disable @typescript-eslint/ban-ts-ignore */
/* eslint-disable no-console */
import { SSOAuthProviderKey } from 'entities/sso';
import { setClientData } from 'store/auth/auth-slice';
import { Client } from 'entities/auth';
import { AppThunk } from '..';
import {
    ActivateProfileResponse,
    RegisterUserRequest,
    RegisterUserResponse,
    UpdateDetailsRequest,
    UpdateDetailsResponse,
    ValidateEmailTokenRequest,
    ValidateEmailTokenResponse,
    ValidatePromoCodeRequest,
    ValidatePromoCodeResponse,
} from '../../entities/register';
import { setToken } from '../../services/api';
import * as apiRegisterActions from '../../services/api-register-actions';
import {
    activateProfileEnd,
    activateProfileError,
    activateProfileStart,
    registerUserEnd,
    registerUserError,
    registerUserStart,
    setActivateProfileResponse,
    setRegisterUserResponse,
    setRegisterWithSsoSuccess,
    setTermsListResponse,
    setUpdateDetailsResponse,
    setValidateEmailTokenResponse,
    setValidatePromoCodeResponse,
    updateDetailsEnd,
    updateDetailsError,
    updateDetailsStart,
    validateEmailTokenError,
    validateEmailTokenStart,
    validatePromoCodeEnd,
    validatePromoCodeError,
    validatePromoCodeStart,
} from './slice';
import { UserTermDto, getUserTerms, acceptTerms } from 'services/api-terms-and-conditions';

/**
 * registerUserAction(request: RegisterUserRequest)
 *
 * Register user
 *
 * @returns void
 */
// eslint-disable-next-line import/prefer-default-export
export const registerUserAction = (request: RegisterUserRequest): AppThunk => async (dispatch) => {
    try {
        dispatch(registerUserStart());

        const data: RegisterUserResponse | null = await apiRegisterActions.registerUser(request);

        if (!data) {
            dispatch(registerUserEnd());
            return;
        }

        dispatch(setRegisterUserResponse(data));
    } catch (Exception) {
        console.error(Exception);
        dispatch(registerUserError(Exception));
    }
};

export const registerWithProviderAction = (payload: {
    provider: SSOAuthProviderKey;
    ssoJwt: string;
    clientApplicationSsoCode: string;
}): AppThunk => async (dispatch) => {
    try {
        dispatch(registerUserStart());

        const {
            status,
            data,
        }: {
            status: number;
            data: Client;
        } = await apiRegisterActions.registerWithProvider(payload);

        setToken(data?.token);
        setClientData(data as Client);

        // dispatch(
        //     setRegisterUserResponse({
        //         ...data,
        //         statusCode: status,
        //         promoCode: '',
        //     })
        // );
        dispatch(
            setValidateEmailTokenResponse({
                // @ts-ignore
                tokenExpires: data?.tokenExpires,
                token: data?.token,
                statusCode: status,
                message: data?.message,
            })
        );

        console.log('registerWithProvider', data);
        dispatch(setRegisterWithSsoSuccess(true));

        dispatch(registerUserEnd());
    } catch (Exception) {
        console.error(Exception);
    }
};

export const validateEmailTokenAction = (request: ValidateEmailTokenRequest): AppThunk => async (
    dispatch
) => {
    try {
        dispatch(validateEmailTokenStart());

        const data: ValidateEmailTokenResponse | null = await apiRegisterActions.validateEmailToken(
            request
        );

        if (!data || data.statusCode !== 200) {
            // NOTE: should we check for statusCode >= 400 && < 500 instead?
            if (data && [400, 401, 500].includes(data.statusCode || data.StatusCode)) {
                // ensure we cater for server 500 errors
                data.statusCode = data.statusCode || data.StatusCode;
                dispatch(validateEmailTokenError(data));
            }
            if (request.callback) request.callback(true);
            return;
        }

        setToken(data.token);
        dispatch(setValidateEmailTokenResponse(data));
        if (request.callback) request.callback(true);
    } catch (Exception) {
        console.error(Exception);
        dispatch(validateEmailTokenError(Exception));
        if (request.callback) request.callback(true);
    }
};

export const validatePromoCodeAction = (request: ValidatePromoCodeRequest): AppThunk => async (
    dispatch
) => {
    try {
        dispatch(validatePromoCodeStart());

        const data: ValidatePromoCodeResponse | null = await apiRegisterActions.validatePromoCode(
            request
        );

        if (!data) {
            dispatch(validatePromoCodeEnd());
            return;
        }

        dispatch(setValidatePromoCodeResponse(data));
    } catch (Exception) {
        console.error(Exception);
        dispatch(validatePromoCodeError(Exception));
    }
};

export const updateDetailsAction = (request: UpdateDetailsRequest): AppThunk => async (
    dispatch
) => {
    try {
        dispatch(updateDetailsStart());

        const data: UpdateDetailsResponse | null = await apiRegisterActions.updateDetails(request);

        if (!data) {
            dispatch(updateDetailsEnd());
            return;
        }

        dispatch(setUpdateDetailsResponse(data));
    } catch (Exception) {
        console.error(Exception);
        dispatch(updateDetailsError(Exception));
    }
};

export const activateProfileAction = (
    revixRoundUp: boolean | undefined,
    termsCodes: string[]
): AppThunk => async (dispatch) => {
    try {
        dispatch(activateProfileStart());

        const data: ActivateProfileResponse | null = await apiRegisterActions.activateProfile(
            revixRoundUp,
            termsCodes
        );

        if (!data) {
            dispatch(activateProfileEnd());
            return;
        }

        setToken(data.accessToken.token);
        dispatch(setActivateProfileResponse(data));
    } catch (Exception) {
        console.error(Exception);
        dispatch(activateProfileError(Exception));
    }
};

export const getTermsListAction = (): AppThunk => async (dispatch) => {
    try {
        const terms: UserTermDto[] | null = await getUserTerms();

        if (terms) {
            dispatch(setTermsListResponse(terms));
            return;
        }
    } catch (Exception) {
        console.error(Exception);
    }
};

export const acceptTermsAction = (termCode: string): AppThunk => async (dispatch) => {
    try {
        await acceptTerms({ termCode });
        dispatch(getTermsListAction());
    } catch (Exception) {
        console.error(Exception);
    }
};
