import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Language = 'eng'; // | 'fre';

const initialState: {
    language: Language;
} = {
    language: 'eng',
};

const language = createSlice({
    name: 'language',
    initialState,
    reducers: {
        setLanguage(state, action: PayloadAction<Language>) {
            // eslint-disable-next-line no-param-reassign
            state.language = action.payload;
            return state;
        },
    },
});

export const { setLanguage } = language.actions;

export default language.reducer;
