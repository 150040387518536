import * as React from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import useLogout from 'hooks/use-logout';
import { useEffect } from 'react';
import { RootState } from '../store/reducer';
import InactivityTimer from '../components/InactivityTimer';
import { refreshToken } from '../store/auth/auth-actions';
import { Client } from '../entities/auth';
import { getUserProfileAction } from '../store/profile/profile-actions';
import CustomLoader from '../components/CustomLoader';
import { addRefreshTokenInterceptor } from '../services/api';
import ProductTour from '../components/Tour';

const TOKEN_EXPIRES_BUFFER = 2; // in minutes

function TabFocusHandler({ client }: { client: Client }) {
    const dispatch = useDispatch();
    const logout = useLogout();

    const hasTokenExpired = React.useCallback(() => {
        if (client) return moment().isAfter(client.tokenExpires);
        return false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client]);

    const onFocus = React.useCallback(() => {
        if (!document.hidden && hasTokenExpired()) {
            logout();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, hasTokenExpired]);

    React.useEffect(() => {
        if (hasTokenExpired()) {
            logout();
        }
        window.addEventListener('visibilitychange', onFocus);
        return () => {
            window.removeEventListener('visibilitychange', onFocus);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, hasTokenExpired, onFocus]);

    return null;
}

// eslint-disable-next-line import/prefer-default-export
export function AuthWrapper({ children }: { children: React.ReactNode }): JSX.Element {
    const location = useLocation();
    const dispatch = useDispatch();

    const client = useSelector((state: RootState) => state.auth.client);
    const userProfile = useSelector((state: RootState) => state.profile).userProfileResponse;
    const { activateProfileResponse } = useSelector((rootState: RootState) => rootState.register);

    const isPotentiallyAuthed = !!client?.data || activateProfileResponse.registered;

    /**
     * If client have a "tokenExpires", and it has expired > execute "refreshToken".
     */
    const checkRefreshToken = React.useCallback(() => {
        if (
            !client?.data?.tokenExpires ||
            moment().add(TOKEN_EXPIRES_BUFFER, 'minutes').isBefore(client.data.tokenExpires)
        ) {
            // still valid.
            return undefined;
        }
        return new Promise((resolve, reject) => {
            dispatch(
                refreshToken((result) => {
                    if (result) resolve(result);
                    else reject();
                })
            );
        });
    }, [client, dispatch]);

    /**
     * Inject checkRefreshToken into api request interceptor, and ejects it
     * again on unmount.
     */
    useEffect(() => {
        const eject = addRefreshTokenInterceptor(checkRefreshToken);
        return () => {
            eject();
        };
    }, [checkRefreshToken]);

    useEffect(() => {
        if (!userProfile && isPotentiallyAuthed) {
            dispatch(getUserProfileAction());
        }
    }, [dispatch, isPotentiallyAuthed, userProfile]);

    if (!isPotentiallyAuthed) {
        return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
    }
    /**
     * A user is deemed to be fully registered if either the client status != 'PENDING' or
     * right after registration, their activateProfileResponse says registered
     */
    if (client?.data?.jwtDecoded?.status === 'PENDING' && !activateProfileResponse.registered) {
        return <Redirect to={{ pathname: '/register', state: { from: location } }} />;
    }

    return (
        <>
            <InactivityTimer />
            <TabFocusHandler client={client?.data as Client} />
            {userProfile ? children : <CustomLoader page />}
            <ProductTour />
        </>
    );
}
