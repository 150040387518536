/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    LinkedAccount,
    LinkedAccountProvider,
    LinkedAccountBank,
    LinkedAccountDeposit,
    ConfirmUserInteraction,
    LinkedAccountProduct,
} from 'entities/account-details';
import { AsyncReduxItem, AsyncReduxList } from 'store/types';

export interface AccountDetails {
    linkedAccounts: {
        banks: AsyncReduxList<LinkedAccountBank>;
        list: AsyncReduxList<LinkedAccount>;
        item: AsyncReduxItem<LinkedAccount>;
        providers: AsyncReduxList<LinkedAccountProvider>;
        initialize: AsyncReduxItem<LinkedAccount>;
        complete: AsyncReduxItem<LinkedAccount>;
        friendlyName: AsyncReduxItem<LinkedAccount>;
        unlink: AsyncReduxItem<LinkedAccount>;
        deposit: AsyncReduxItem<LinkedAccountDeposit>;
        confirmUserInteraction: AsyncReduxItem<ConfirmUserInteraction>;
        product: AsyncReduxItem<LinkedAccountProduct>;
    };
}

const initialAccountDetailsState: AccountDetails = {
    linkedAccounts: {
        banks: {
            loading: true,
            data: [],
            error: null,
        },
        list: {
            loading: true,
            data: [],
            error: null,
        },
        item: {
            loading: false,
            data: null,
            error: null,
        },
        providers: {
            loading: false,
            data: [],
            error: null,
        },
        initialize: {
            loading: false,
            data: null,
            error: null,
        },
        complete: {
            loading: false,
            data: null,
            error: null,
        },
        friendlyName: {
            loading: false,
            data: null,
            error: null,
        },
        unlink: {
            loading: false,
            data: null,
            error: null,
        },
        deposit: {
            loading: false,
            data: null,
            error: null,
        },
        confirmUserInteraction: {
            loading: false,
            data: null,
            error: null,
        },
        product: {
            loading: false,
            data: null,
            error: null,
        },
    },
};

const accountDetails = createSlice({
    name: 'account-details',
    initialState: initialAccountDetailsState,
    reducers: {
        setLinkedAccountProviderBanksLoading(
            state: AccountDetails,
            action: PayloadAction<boolean>
        ) {
            if (state.linkedAccounts.banks) {
                state.linkedAccounts.banks.loading = action.payload;
            }
        },
        setLinkedAccountProviderBanksData(
            state: AccountDetails,
            action: PayloadAction<LinkedAccountBank[]>
        ) {
            if (state.linkedAccounts.banks) {
                state.linkedAccounts.banks.data = action.payload;
            }
        },
        setLinkedAccountProviderBanksError(
            state: AccountDetails,
            action: PayloadAction<string | any>
        ) {
            if (state.linkedAccounts.banks) {
                state.linkedAccounts.banks.error = action.payload;
            }
        },
        setLinkedAccountProvidersLoading(state: AccountDetails, action: PayloadAction<boolean>) {
            if (state.linkedAccounts.providers) {
                state.linkedAccounts.providers.loading = action.payload;
            }
        },
        setLinkedAccountProvidersData(
            state: AccountDetails,
            action: PayloadAction<LinkedAccountProvider[]>
        ) {
            if (state.linkedAccounts.providers) {
                state.linkedAccounts.providers.data = action.payload;
            }
        },
        setLinkedAccountProvidersError(state: AccountDetails, action: PayloadAction<string | any>) {
            if (state.linkedAccounts.providers) {
                state.linkedAccounts.providers.error = action.payload;
            }
        },
        setLinkedAccountsListLoading(state: AccountDetails, action: PayloadAction<boolean>) {
            if (state.linkedAccounts.list) {
                state.linkedAccounts.list.loading = action.payload;
            }
        },
        setLinkedAccountsListData(state: AccountDetails, action: PayloadAction<LinkedAccount[]>) {
            if (state.linkedAccounts.list) {
                state.linkedAccounts.list.data = action.payload;
            }
        },
        setLinkedAccountsListError(state: AccountDetails, action: PayloadAction<string | any>) {
            if (state.linkedAccounts.list) {
                state.linkedAccounts.list.error = action.payload;
            }
        },
        setLinkedAccountsItemLoading(state: AccountDetails, action: PayloadAction<boolean>) {
            if (state.linkedAccounts.item) {
                state.linkedAccounts.item.loading = action.payload;
            }
        },
        setLinkedAccountsItemData(
            state: AccountDetails,
            action: PayloadAction<LinkedAccount | null>
        ) {
            if (state.linkedAccounts.item) {
                state.linkedAccounts.item.data = action.payload;
            }
        },
        setLinkedAccountsItemError(state: AccountDetails, action: PayloadAction<string | any>) {
            if (state.linkedAccounts.item) {
                state.linkedAccounts.item.error = action.payload;
            }
        },
        setLinkedAccountsInitializeData(
            state: AccountDetails,
            action: PayloadAction<string | any>
        ) {
            if (state.linkedAccounts.initialize) {
                state.linkedAccounts.initialize.data = action.payload;
            }
        },
        setLinkedAccountsInitializeError(
            state: AccountDetails,
            action: PayloadAction<string | any>
        ) {
            if (state.linkedAccounts.initialize) {
                state.linkedAccounts.initialize.error = action.payload;
            }
        },
        setLinkedAccountsInitializeLoading(
            state: AccountDetails,
            action: PayloadAction<string | any>
        ) {
            if (state.linkedAccounts.initialize) {
                state.linkedAccounts.initialize.loading = action.payload;
            }
        },
        setLinkedAccountsCompleteData(state: AccountDetails, action: PayloadAction<string | any>) {
            state.linkedAccounts.complete.data = action.payload;
        },
        setLinkedAccountsCompleteError(state: AccountDetails, action: PayloadAction<string | any>) {
            if (state.linkedAccounts.complete) {
                state.linkedAccounts.complete.error = action.payload;
            }
        },
        setLinkedAccountsCompleteLoading(
            state: AccountDetails,
            action: PayloadAction<string | any>
        ) {
            if (state.linkedAccounts.complete) {
                state.linkedAccounts.complete.loading = action.payload;
            }
        },
        setLinkedAccountFriendlyNameData(
            state: AccountDetails,
            action: PayloadAction<string | any>
        ) {
            if (state.linkedAccounts.friendlyName) {
                state.linkedAccounts.friendlyName.data = action.payload;
            }
        },
        setLinkedAccountFriendlyNameError(
            state: AccountDetails,
            action: PayloadAction<string | any>
        ) {
            if (state.linkedAccounts.friendlyName) {
                state.linkedAccounts.friendlyName.error = action.payload;
            }
        },
        setLinkedAccountFriendlyNameLoading(
            state: AccountDetails,
            action: PayloadAction<string | any>
        ) {
            if (state.linkedAccounts.friendlyName) {
                state.linkedAccounts.friendlyName.loading = action.payload;
            }
        },
        setLinkedAccountUnlinkData(state: AccountDetails, action: PayloadAction<string | any>) {
            if (state.linkedAccounts.unlink) {
                state.linkedAccounts.unlink.data = action.payload;
            }
        },
        setLinkedAccountUnlinkError(state: AccountDetails, action: PayloadAction<string | any>) {
            if (state.linkedAccounts.unlink) {
                state.linkedAccounts.unlink.error = action.payload;
            }
        },
        setLinkedAccountUnlinkLoading(state: AccountDetails, action: PayloadAction<string | any>) {
            if (state.linkedAccounts.unlink) {
                state.linkedAccounts.unlink.loading = action.payload;
            }
        },
        setLinkedAccountDepositData(state: AccountDetails, action: PayloadAction<string | any>) {
            if (state.linkedAccounts.deposit) {
                state.linkedAccounts.deposit.data = action.payload;
            }
        },
        setLinkedAccountDepositError(state: AccountDetails, action: PayloadAction<string | any>) {
            if (state.linkedAccounts.deposit) {
                state.linkedAccounts.deposit.error = action.payload;
            }
        },
        setLinkedAccountDepositLoading(state: AccountDetails, action: PayloadAction<string | any>) {
            if (state.linkedAccounts.deposit) {
                state.linkedAccounts.deposit.loading = action.payload;
            }
        },
        setConfirmUserInteractiontData(state: AccountDetails, action: PayloadAction<string | any>) {
            if (state.linkedAccounts.confirmUserInteraction) {
                state.linkedAccounts.confirmUserInteraction.data = action.payload;
            }
        },
        setConfirmUserInteractiontError(
            state: AccountDetails,
            action: PayloadAction<string | any>
        ) {
            if (state.linkedAccounts.confirmUserInteraction) {
                state.linkedAccounts.confirmUserInteraction.error = action.payload;
            }
        },
        setConfirmUserInteractiontLoading(
            state: AccountDetails,
            action: PayloadAction<string | any>
        ) {
            if (state.linkedAccounts.confirmUserInteraction) {
                state.linkedAccounts.confirmUserInteraction.loading = action.payload;
            }
        },
        setLinkedAccountProductData(state: AccountDetails, action: PayloadAction<string | any>) {
            if (state.linkedAccounts.product) {
                state.linkedAccounts.product.data = action.payload;
            }
        },
        setLinkedAccountProductError(
            state: AccountDetails,
            action: PayloadAction<LinkedAccountProduct | any>
        ) {
            if (state.linkedAccounts.product) {
                state.linkedAccounts.product.error = action.payload;
            }
        },
        setLinkedAccountProductLoading(state: AccountDetails, action: PayloadAction<string | any>) {
            if (state.linkedAccounts.product) {
                state.linkedAccounts.product.loading = action.payload;
            }
        },
    },
});

export const {
    setLinkedAccountProvidersLoading,
    setLinkedAccountProvidersData,
    setLinkedAccountProvidersError,
    setLinkedAccountProviderBanksLoading,
    setLinkedAccountProviderBanksData,
    setLinkedAccountProviderBanksError,
    setLinkedAccountsListLoading,
    setLinkedAccountsListData,
    setLinkedAccountsListError,
    setLinkedAccountsItemLoading,
    setLinkedAccountsItemData,
    setLinkedAccountsItemError,
    setLinkedAccountsInitializeData,
    setLinkedAccountsInitializeError,
    setLinkedAccountsInitializeLoading,
    setLinkedAccountsCompleteData,
    setLinkedAccountsCompleteError,
    setLinkedAccountsCompleteLoading,
    setLinkedAccountFriendlyNameData,
    setLinkedAccountFriendlyNameError,
    setLinkedAccountFriendlyNameLoading,
    setLinkedAccountUnlinkData,
    setLinkedAccountUnlinkError,
    setLinkedAccountUnlinkLoading,
    setLinkedAccountDepositData,
    setLinkedAccountDepositError,
    setLinkedAccountDepositLoading,
    setConfirmUserInteractiontData,
    setConfirmUserInteractiontError,
    setConfirmUserInteractiontLoading,
    setLinkedAccountProductData,
    setLinkedAccountProductError,
    setLinkedAccountProductLoading,
} = accountDetails.actions;

export default accountDetails.reducer;
