/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    ActivateTotpResponse,
    DisableTotpResponse,
    RegisterTwoFactorResponse,
} from '../../entities/two-factor';

export interface TwoFactorState {
    loading: boolean;
    registerResponse: RegisterTwoFactorResponse;
    activateTotpResponse: ActivateTotpResponse;
    disableTotpResponse: DisableTotpResponse;
}

const initialState: TwoFactorState = {
    loading: false,
    registerResponse: {
        message: '',
        success: false,
    },
    activateTotpResponse: {
        message: '',
        success: false,
    },
    disableTotpResponse: {
        message: '',
        success: false,
    },
};

const register = createSlice({
    name: 'twoFactor',
    initialState,
    reducers: {
        registerStart(state) {
            state.loading = true;
        },
        registerEnd(state) {
            state.loading = false;
        },
        registerError(state, action: PayloadAction<RegisterTwoFactorResponse>) {
            state.loading = false;
            state.registerResponse = action.payload;
        },
        setRegisterResponse(state, action: PayloadAction<RegisterTwoFactorResponse>) {
            state.registerResponse = action.payload;
        },

        activateTotpStart(state) {
            state.loading = true;
        },
        activateTotpEnd(state) {
            state.loading = false;
        },
        activateTotpError(state, action: PayloadAction<ActivateTotpResponse>) {
            state.loading = false;
            state.activateTotpResponse = action.payload;
        },
        setActivateTotpResponse(state, action: PayloadAction<ActivateTotpResponse>) {
            state.activateTotpResponse = action.payload;
        },

        disableTotpStart(state) {
            state.loading = true;
        },
        disableTotpEnd(state) {
            state.loading = false;
        },
        disableTotpError(state, action: PayloadAction<DisableTotpResponse>) {
            state.loading = false;
            state.disableTotpResponse = action.payload;
        },
        setDisableTotpResponse(state, action: PayloadAction<DisableTotpResponse>) {
            state.disableTotpResponse = action.payload;
        },
    },
});

export const {
    registerStart,
    registerEnd,
    setRegisterResponse,
    registerError,
    activateTotpStart,
    activateTotpEnd,
    activateTotpError,
    setActivateTotpResponse,
    disableTotpEnd,
    disableTotpError,
    disableTotpStart,
    setDisableTotpResponse,
} = register.actions;

export default register.reducer;
