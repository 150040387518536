const verification = {
    Verified: 'Verified',
    Pending: 'Pending',
    InProgress: 'InProgress',
    SMS: 'SMS',
    Source: 'Source',
    Unverified: 'Unverified',
    Incomplete: 'Incomplete',
};

export default verification;
