import api from './api';

export const fetchPortfolioV2Aggregated = async (timeFrame: string) => {
    try {
        const result = await api.get(`/portfolio/v2/aggregated/${timeFrame}`);
        return result.data;
    } catch (Exception) {
        // eslint-disable-next-line no-console
        console.error(Exception);
        return Exception.response.data;
    }
};

export const fetchPortfolioV2Rewards = async () => {
    try {
        const result = await api.get(`/portfolio/v2/rewards`);
        return result.data;
    } catch (Exception) {
        // eslint-disable-next-line no-console
        console.error(Exception);
        return Exception.response.data;
    }
};

export const fetchPortfolioV2ProductsAndHoldings = async () => {
    try {
        const result = await api.get(`/portfolio/v2/products-and-holdings`);
        return result.data;
    } catch (Exception) {
        // eslint-disable-next-line no-console
        console.error(Exception);
        return Exception.response.data;
    }
};

export const fetchPortfolioV2VaultGains = async () => {
    try {
        const result = await api.get(`/portfolio/v2/vault-gains`);
        return result.data;
    } catch (Exception) {
        // eslint-disable-next-line no-console
        console.error(Exception);
        return Exception.response.data;
    }
};

export const fetchPortfolioV2ReservedAmounts = async () => {
    try {
        const result = await api.get(`/portfolio/v2/reserved-amounts`);
        return result.data;
    } catch (Exception) {
        // eslint-disable-next-line no-console
        console.error(Exception);
        return Exception.response.data;
    }
};

export const fetchReferencesAssets = async () => {
    try {
        const result = await api.get(`/references/assets`);
        return result.data;
    } catch (Exception) {
        // eslint-disable-next-line no-console
        console.error(Exception);
        return Exception.response.data;
    }
};

export const fetchProductsDisplayRates = async (
    companyCode: string,
    targetProductCode: string,
    sourceProductCodes: string[]
) => {
    try {
        const result = await api.post(
            `/rates/v2/displayable-rates?companyCode=${companyCode}&targetProductCode=${targetProductCode}`,
            sourceProductCodes
        );
        return result.data;
    } catch (Exception) {
        // eslint-disable-next-line no-console
        console.error(Exception);
        return Exception.response.data;
    }
};

export const fetchPendingOrders = async (productCode?: string) => {
    try {
        const result = await api.get(`/pending-orders/simple?productCodes=${productCode}`);
        return result.data;
    } catch (Exception) {
        // eslint-disable-next-line no-console
        console.error(Exception);
        return Exception.response.data;
    }
};

export const fetchReferencesAssetByCode = async (productCode: string) => {
    try {
        const result = await api.get(`/references/assets/${productCode}`);
        return result?.data;
    } catch (Exception) {
        // eslint-disable-next-line no-console
        console.error(Exception);
        return Exception.response?.data;
    }
};
