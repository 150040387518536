/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    RewardsDetails,
    RewardsDetailsResponse,
    Tiers,
    ReferralsResponse,
} from '../../entities/rewardsDetails';

export interface RewardsDetailsState {
    loading: boolean;
    redeemLoading: boolean;
    rewardsDetails: RewardsDetails;
    rewardsDetailsResponse: RewardsDetailsResponse;
    tiers: Tiers[];
    referrals: {
        loading: boolean;
        error: any;
        data: ReferralsResponse;
    };
}

const initialState: RewardsDetailsState = {
    loading: false,
    redeemLoading: false,
    rewardsDetails: {
        rewardsBalance: 0,
        stars: 0,
        currentTierName: '',
        nextTierName: '',
        rewardsPointsRequiredForNextTier: 0,
        rewardsPointsRequiredToRedeem: 0,
        completedActionsRewardsTotal: 0,
        completedActionsRewardsCount: 0,
        rewardsDetails: [],
        referralCode: '',
        rewardsHistory: [],
        nextTierBenefits: [],
        lifeTimePointsBalance: 0,
        rewardsBalanceFiatSymbol: '',
        rewardsBalanceInFiat: 0,
        totalLifeTimePointsBalance: 0,
    },
    rewardsDetailsResponse: {
        statusCode: 0,
        bitcoinAmountReceived: 0,
        amountInDisplayCurrency: 0,
    },
    tiers: [],
    referrals: {
        loading: false,
        error: null,
        data: {
            items: [],
        },
    },
};

const rewardsDetails = createSlice({
    name: 'rewardsDetails',
    initialState,
    reducers: {
        setRewardsLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        addRewardsDetailsStart(state) {
            state.loading = true;
        },
        addRewardsDetailsEnd(state) {
            state.loading = false;
        },
        redeemRewardsStart(state) {
            state.redeemLoading = true;
        },
        redeemRewardsEnd(state) {
            state.redeemLoading = false;
        },
        addRewardsDetailsError(state, action: PayloadAction<RewardsDetailsResponse>) {
            state.loading = false;
            state.rewardsDetailsResponse = action.payload;
        },
        setAddRewardsDetailsResponse(state, action: PayloadAction<RewardsDetailsResponse>) {
            state.loading = false;
            state.rewardsDetailsResponse = action.payload;
        },
        enterPromoDetailsStart(state) {
            state.loading = true;
        },
        enterPromoDetailsEnd(state) {
            state.loading = false;
        },
        enterPromoDetailsError(state, action: PayloadAction<RewardsDetailsResponse>) {
            state.loading = false;
            state.rewardsDetailsResponse = action.payload;
        },
        setEnterPromoDetailsResponse(state, action: PayloadAction<RewardsDetailsResponse>) {
            state.rewardsDetailsResponse = action.payload;
        },
        getRewardsDetailsStart(state) {
            state.loading = true;
        },
        getRewardsDetailsEnd(state) {
            state.loading = false;
        },
        getRewardsDetailsError(state, action: PayloadAction<RewardsDetails>) {
            state.loading = false;
            state.rewardsDetails = action.payload;
        },
        getRewardsDetailsData(state, action: PayloadAction<RewardsDetails>) {
            state.rewardsDetails = action.payload;
        },
        getTiersDetailsStart(state) {
            state.loading = true;
        },
        getTiersDetailsEnd(state) {
            state.loading = false;
        },
        getTiersDetailsError(state, action: PayloadAction<Tiers[]>) {
            state.loading = false;
            state.tiers = action.payload;
        },
        getTiersDetailsData(state, action: PayloadAction<Tiers[]>) {
            state.tiers = action.payload;
        },
        setRewardReferralsLoading(state, action: PayloadAction<boolean>) {
            state.referrals.loading = action.payload;
        },
        setRewardReferralsData(state, action: PayloadAction<ReferralsResponse>) {
            state.referrals.data = action.payload;
        },
        setRewardReferralsError(state, action: PayloadAction<ReferralsResponse>) {
            state.referrals.error = action.payload;
        },
    },
});

export const {
    setRewardsLoading,
    addRewardsDetailsStart,
    addRewardsDetailsEnd,
    redeemRewardsStart,
    redeemRewardsEnd,
    addRewardsDetailsError,
    setAddRewardsDetailsResponse,
    getRewardsDetailsStart,
    getRewardsDetailsEnd,
    getRewardsDetailsError,
    getRewardsDetailsData,
    getTiersDetailsStart,
    getTiersDetailsEnd,
    getTiersDetailsError,
    getTiersDetailsData,
    enterPromoDetailsStart,
    enterPromoDetailsEnd,
    enterPromoDetailsError,
    setEnterPromoDetailsResponse,
    setRewardReferralsLoading,
    setRewardReferralsData,
    setRewardReferralsError,
} = rewardsDetails.actions;

export default rewardsDetails.reducer;
