/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TradeProduct } from 'entities';
import { AsyncReduxItem, AsyncReduxList, InitAsyncData } from 'store/types';

export interface TradeState {
    buy: {
        products: AsyncReduxList<TradeProduct[]>;
        amount: AsyncReduxItem<any> & {
            value: number | null | undefined;
        };
        confirm: AsyncReduxItem<any>;
        complete: AsyncReduxItem<any>;
        smartTrade: AsyncReduxItem<any>;
    };
    sell: {
        products: AsyncReduxList<TradeProduct[]>;
        amount: AsyncReduxItem<any> & {
            value: number | null | undefined;
        };
        confirm: AsyncReduxItem<any>;
        complete: AsyncReduxItem<any>;
        smartTrade: AsyncReduxItem<any>;
    };
}

const initialTradeState: TradeState = {
    buy: {
        products: {
            data: [],
            loading: false,
            error: null,
        },
        amount: {
            loading: false,
            data: {},
            error: null,
            value: null,
        },
        confirm: {
            loading: false,
            data: {},
            error: null,
        },
        complete: {
            loading: false,
            data: {},
            error: null,
        },
        smartTrade: {
            loading: false,
            data: {},
            error: null,
        },
    },
    sell: {
        products: {
            data: [],
            loading: false,
            error: null,
        },
        amount: {
            loading: false,
            data: {},
            error: null,
            value: null,
        },
        confirm: {
            loading: false,
            data: {},
            error: null,
        },
        complete: {
            loading: false,
            data: {},
            error: null,
        },
        smartTrade: {
            loading: false,
            data: {},
            error: null,
        },
    },
};

const trade = createSlice({
    name: 'trade',
    initialState: initialTradeState,
    reducers: {
        // #region BUY
        // Products
        setBuyProductsLoading(state, action: PayloadAction<boolean>) {
            state.buy.products.loading = action.payload;
            state.buy.products.error = null;
        },
        setBuyProductsData(state, action: PayloadAction<any>) {
            state.buy.products.data = action.payload;
            state.buy.products.loading = false;
            state.buy.products.error = null;
        },
        setBuyProductsError(state, action: PayloadAction<any>) {
            state.buy.products.error = action.payload;
            state.buy.products.loading = false;
        },
        // Amount
        setBuyAmountValue(state, action: PayloadAction<number | null | undefined>) {
            state.buy.amount.value = action.payload;
        },
        setBuyAmountLoading(state, action: PayloadAction<boolean>) {
            state.buy.amount.loading = action.payload;
            state.buy.amount.error = null;
        },
        setBuyAmountData(state, action: PayloadAction<any>) {
            state.buy.amount.data = action.payload;
            state.buy.amount.loading = false;
            state.buy.amount.error = null;
        },
        setBuyAmountError(state, action: PayloadAction<any>) {
            state.buy.amount.error = action.payload;
            state.buy.amount.loading = false;
        },
        // Confirm
        setBuyConfirmLoading(state, action: PayloadAction<boolean>) {
            state.buy.confirm.loading = action.payload;
            state.buy.confirm.error = null;
        },
        setBuyConfirmData(state, action: PayloadAction<any>) {
            state.buy.confirm.data = action.payload;
            state.buy.confirm.loading = false;
            state.buy.confirm.error = null;
        },
        setBuyConfirmError(state, action: PayloadAction<any>) {
            state.buy.confirm.error = action.payload;
            state.buy.confirm.loading = false;
        },
        // Complete
        setBuyCompleteLoading(state, action: PayloadAction<boolean>) {
            state.buy.complete.loading = action.payload;
            state.buy.complete.error = null;
        },
        setBuyCompleteData(state, action: PayloadAction<any>) {
            state.buy.complete.data = action.payload;
            state.buy.complete.loading = false;
            state.buy.complete.error = null;
        },
        setBuyCompleteError(state, action: PayloadAction<any>) {
            state.buy.complete.error = action.payload;
            state.buy.complete.loading = false;
        },
        // SmartTrade
        setBuySmartTradeData(state, action: PayloadAction<boolean>) {
            state.buy.smartTrade.data = action.payload;
            state.buy.smartTrade.loading = false;
            state.buy.smartTrade.error = null;
        },
        // #endregion

        // #region SELL
        // Products
        setSellProductsLoading(state, action: PayloadAction<boolean>) {
            state.sell.products.loading = action.payload;
            state.sell.products.error = null;
        },
        setSellProductsData(state, action: PayloadAction<any>) {
            state.sell.products.data = action.payload;
            state.sell.products.loading = false;
            state.sell.products.error = null;
        },
        setSellProductsError(state, action: PayloadAction<any>) {
            state.sell.products.error = action.payload;
            state.sell.products.loading = false;
        },
        // Amount
        setSellAmountValue(state, action: PayloadAction<number | null | undefined>) {
            state.sell.amount.value = action.payload;
        },
        setSellAmountLoading(state, action: PayloadAction<boolean>) {
            state.sell.amount.loading = action.payload;
            state.sell.amount.error = null;
        },
        setSellAmountData(state, action: PayloadAction<any>) {
            state.sell.amount.data = action.payload;
            state.sell.amount.loading = false;
            state.sell.amount.error = null;
        },
        setSellAmountError(state, action: PayloadAction<any>) {
            state.sell.amount.error = action.payload;
            state.sell.amount.loading = false;
        },
        // Confirm
        setSellConfirmLoading(state, action: PayloadAction<boolean>) {
            state.sell.confirm.loading = action.payload;
            state.sell.confirm.error = null;
        },
        setSellConfirmData(state, action: PayloadAction<any>) {
            state.sell.confirm.data = action.payload;
            state.sell.confirm.loading = false;
            state.sell.confirm.error = null;
        },
        setSellConfirmError(state, action: PayloadAction<any>) {
            state.sell.confirm.error = action.payload;
            state.sell.confirm.loading = false;
        },
        // Complete
        setSellCompleteLoading(state, action: PayloadAction<boolean>) {
            state.sell.complete.loading = action.payload;
            state.sell.complete.error = null;
        },
        setSellCompleteData(state, action: PayloadAction<any>) {
            state.sell.complete.data = action.payload;
            state.sell.complete.loading = false;
            state.sell.complete.error = null;
        },
        setSellCompleteError(state, action: PayloadAction<any>) {
            state.sell.complete.error = action.payload;
            state.sell.complete.loading = false;
        },
        // SmartTrade
        setSellSmartTradeData(state, action: PayloadAction<boolean>) {
            state.sell.smartTrade.data = action.payload;
            state.sell.smartTrade.loading = false;
            // state.sell.complete.error = null;
        },
        // #endregion
    },
});

export const {
    // BUY
    setBuyProductsLoading,
    setBuyProductsData,
    setBuyProductsError,
    setBuyAmountValue,
    setBuyAmountLoading,
    setBuyAmountData,
    setBuyAmountError,
    setBuyConfirmLoading,
    setBuyConfirmData,
    setBuyConfirmError,
    setBuyCompleteLoading,
    setBuyCompleteData,
    setBuyCompleteError,
    setBuySmartTradeData,
    // SELL
    setSellProductsLoading,
    setSellProductsData,
    setSellProductsError,
    setSellAmountValue,
    setSellConfirmLoading,
    setSellConfirmData,
    setSellConfirmError,
    setSellCompleteLoading,
    setSellCompleteData,
    setSellCompleteError,
    setSellAmountLoading,
    setSellAmountData,
    setSellAmountError,
    setSellSmartTradeData,
} = trade.actions;

export default trade.reducer;
