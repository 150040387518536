export default function isSafariOnIos() {
    const { userAgent } = window.navigator;
    return (
        /(iPad|iPhone|iPod)/gi.test(userAgent) &&
        /Safari/.test(userAgent) &&
        !/CriOS/.test(userAgent) &&
        !/FxiOS/.test(userAgent) &&
        !/OPiOS/.test(userAgent) &&
        !/Android/.test(userAgent) &&
        !/mercury/.test(userAgent)
    );
}
