/* eslint-disable import/prefer-default-export */
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    UpdateEmailRequest,
    UpdateEmailVerificationRequest,
    UpdatePasswordRequest,
    UserProfileResponse,
} from '../entities/profile';
import {
    disableEmailNotificationAction,
    enableEmailNotificationAction,
    getUserProfileAction,
    updateEmailAction,
    updateEmailVerificationAction,
    updatePasswordAction,
} from '../store/profile/profile-actions';
import { RootState } from '../store/reducer';

/**
 * This assumes that user profile has already been called and is not null.
 */
const useUserProfile = () => {
    const dispatch = useDispatch();

    const profile = useSelector((state: RootState) => state.profile);
    const registerState = useSelector((state: RootState) => state.register);

    const getUserProfile = useCallback(() => {
        /*
         * TODO: A better approach needs to be applied for handling this timer scenario.
         * Something more reusable that can be easily adopted by other calls in the platform
         */
        const fiveMinutesAgo: number = new Date().getTime() - 5 * 60000;

        if (
            (!profile.userProfileResponse ||
                profile.userProfileResponse.lastRefreshedAt <= fiveMinutesAgo) &&
            !!registerState.activateProfileResponse.accessToken.token
        ) {
            dispatch(getUserProfileAction());
        }
    }, [
        dispatch,
        profile.userProfileResponse,
        registerState.activateProfileResponse.accessToken.token,
    ]);

    useEffect(() => {
        getUserProfile();
    }, [getUserProfile]);

    return profile.userProfileResponse as UserProfileResponse;
};

const useEnableEmailNotification = () => {
    const dispatch = useDispatch();

    const enableEmailNotification = async () => {
        await dispatch(enableEmailNotificationAction());
    };

    return enableEmailNotification;
};

const useDisableEmailNotification = () => {
    const dispatch = useDispatch();

    const disableEmailNotification = async () => {
        await dispatch(disableEmailNotificationAction());
    };

    return disableEmailNotification;
};

const useUpdateEmail = () => {
    const dispatch = useDispatch();

    const updateEmail = async (request: UpdateEmailRequest) => {
        await dispatch(updateEmailAction(request));
    };

    return updateEmail;
};

const useUpdateEmailVerification = () => {
    const dispatch = useDispatch();

    return async (request: UpdateEmailVerificationRequest) => {
        await dispatch(updateEmailVerificationAction(request));
    };
};

const useUpdatePassword = () => {
    const dispatch = useDispatch();

    const updatePassword = async (request: UpdatePasswordRequest) => {
        await dispatch(updatePasswordAction(request));
    };

    return updatePassword;
};

export {
    useUserProfile,
    useEnableEmailNotification,
    useDisableEmailNotification,
    useUpdateEmail,
    useUpdateEmailVerification,
    useUpdatePassword,
};
