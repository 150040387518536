import appHelpers from './app-urls';

const imageLoadError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const cdnURL = appHelpers.getCDNUrl();
    const fallbackIcon = `${cdnURL}assets/products/icons/fallback-icon.svg`;

    // eslint-disable-next-line no-param-reassign
    event.currentTarget.src = fallbackIcon;
};

export default imageLoadError;
