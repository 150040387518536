/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import Modal from 'components/Modal';
import Button from 'components/Button';
import { RootState } from 'store/reducer';
import { useSelector } from 'react-redux';
import appUrls from 'helpers/app-urls';
import PhoneNumberV2 from 'components/PhoneNumberV2/PhoneNumberV2';
import api from 'services/api';
import { Country } from 'entities/country';
import { isValidPhoneNumber } from 'utils/validation';
import { maxMobileNumberLength } from 'constants/constants';
import ButtonSpinner from 'components/ButtonSpinner';
import determineTimeRemaining from 'utils/time-remaining-ms';
import trimZero from 'utils/mobile-number';
import clsx from 'clsx';
import colors from 'styles/colors';
import GetAppButton from 'components/GetAppButton';
import useWindowSize from '../../hooks/use-window-size';
import desktopBackground from './background.png';
import closeIcon from './close-icon.svg';
import translations from './translations';
import modalItems, { OnboardingModalItem } from './modalItems';

const cdnUrl = appUrls.getCDNUrl();
const marketingUrl = appUrls.getMarketingUrl();

const numberImageUrl = `${cdnUrl}assets/onboarding/`;
const localStorageKey = 'revix/rateLimitTimestamp';
// Rate limit resets after 5 minutes
const rateLimitExpirySeconds = 300;

const OnboardingModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
    // #regions HOOKS
    const { language } = useSelector((state: RootState) => state.language);
    const windowSize = useWindowSize();
    const modalBackgroundMobile =
        (windowSize?.width || 0) < 1024
            ? 'linear-gradient(0deg, rgba(10, 83, 162, 1) 0%, rgba(17, 28, 64, 1) 100%)'
            : 'linear-gradient(109.41deg, #111C40 28.65%, #0E114E 67.4%)';
    // #endregion

    // #regions STATE
    const [selectedCountry, setSelectedCountry] = useState<Country>();
    const [mobileNumber, setMobileNumber] = useState<string>('');
    const [sendingSmsRequest, setSendingSmsRequest] = useState<boolean>(false);
    const [requestError, setRequestError] = useState<boolean>(false);
    const [sendSmsSuccess, setSendSmsSuccess] = useState<boolean>(false);
    // #endregion

    // #region FUNCTIONS
    const clearErrorAfterTimeout = (delayMs: string | number) => {
        setTimeout(() => {
            localStorage.removeItem(localStorageKey);
            setRequestError(false);
        }, Number(delayMs));
    };

    const timeRemaining = () => {
        const rateLimitTimestamp = localStorage.getItem(localStorageKey);
        if (rateLimitTimestamp) {
            const rateLimitExpiryMs = rateLimitExpirySeconds * 1000;
            return determineTimeRemaining(rateLimitTimestamp, rateLimitExpiryMs);
        }
        return 0;
    };

    const handleNumberInput = (value: string) => {
        setSendSmsSuccess(false);
        if (!value) {
            setMobileNumber('');
            return;
        }
        const lastInputValue = value[value.length - 1]?.replaceAll(' ', '');
        if (
            lastInputValue &&
            // eslint-disable-next-line no-restricted-globals
            !isNaN(Number(lastInputValue)) &&
            value.length <= maxMobileNumberLength
        ) {
            setMobileNumber(value);
        }
    };

    const handleSmsErrorResponse = () => {
        setSendSmsSuccess(false);
        setRequestError(true);
        const currentTime = new Date();
        localStorage.setItem(localStorageKey, `${currentTime}`);
        clearErrorAfterTimeout(rateLimitExpirySeconds * 1000);
    };

    const sendSms = async () => {
        const rateLimitPresent = timeRemaining() > 0;
        if (rateLimitPresent) {
            setRequestError(true);
            return;
        }
        setSendSmsSuccess(false);
        const zeroTrimmedNumber = trimZero(mobileNumber);
        const dialCodeAndNumber = encodeURIComponent(
            `${selectedCountry?.dialCode}${zeroTrimmedNumber}`
        );
        const requestUrl = `smsSignup?mobileNumber=${dialCodeAndNumber}`;
        setSendingSmsRequest(true);
        try {
            const response = await api.post(requestUrl);
            if (response?.status === 200) setSendSmsSuccess(true);
            else handleSmsErrorResponse();
        } catch (error) {
            handleSmsErrorResponse();
        } finally {
            setSendingSmsRequest(false);
        }
    };

    const handleCloseModal = () => {
        onClose();
        setSendSmsSuccess(false);
        setRequestError(false);
    };
    // #endregion

    const isValidNumber = isValidPhoneNumber(mobileNumber, selectedCountry?.code);
    const hasValidInput = isValidNumber && selectedCountry?.dialCode;
    const messages = translations[language];

    useEffect(() => {
        const rateLimitTimeout = timeRemaining();
        if (rateLimitTimeout > 0) {
            clearErrorAfterTimeout(rateLimitTimeout);
        }
    }, []);

    const modalContent = (
        <div className="flex flex-col lg:flex-row lg:min-w-800 overflow-hidden lg:min-h-460">
            {/* Left side */}
            <div className="w-full lg:w-11/20">
                <button
                    className="fixed top-4 right-4 focus:outline-none"
                    type="button"
                    onClick={() => handleCloseModal()}
                >
                    <img src={closeIcon} alt="close" />
                </button>
                <div className="text-4xl mb-10 text-white lg:text-left font-semibold">
                    {messages.getVerified}
                </div>
                <div>
                    {modalItems.map((item: OnboardingModalItem, i: number) => {
                        return (
                            <div className="flex items-center mb-8" key={i}>
                                <img
                                    src={`${numberImageUrl}${item.image}`}
                                    style={{ height: '50px', width: '50px', minWidth: '50px' }}
                                    alt={item.text}
                                />
                                <div className="ml-4 text-white text-lg font-light lg:font-semibold text-left">
                                    {messages[item.text]}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="hidden lg:block">
                    <div className="text-white text-left mb-4">{messages.downLoadHeader}</div>
                    <div className="flex items-center">
                        <PhoneNumberV2
                            onNumberChange={handleNumberInput}
                            selectedCountry={selectedCountry}
                            onCountryChange={setSelectedCountry}
                            phoneNumber={mobileNumber || ''}
                            isNumberValid={sendSmsSuccess}
                            hasNumberError={requestError}
                        />
                        <Button
                            className="ml-2 px-2 relative z-10"
                            disabled={!hasValidInput || requestError}
                            onClick={sendSms}
                        >
                            {sendingSmsRequest ? <ButtonSpinner /> : messages.send}
                        </Button>
                    </div>
                    <div
                        className={clsx('text-left mt-8', {
                            'text-error': requestError,
                            'text-white': sendSmsSuccess,
                        })}
                        style={{ height: '20px' }}
                    >
                        {requestError
                            ? messages.requestError
                            : sendSmsSuccess
                            ? `${messages.smsSuccess} (+${selectedCountry?.dialCode}) ${mobileNumber}`
                            : ''}
                    </div>
                </div>
            </div>
            {/* Right side */}
            <div className="w-full lg:w-9/20">
                <div className="mt-2 lg:hidden flex flex-col justify-center align-center">
                    <GetAppButton isFullWidth />
                    <div className="mb-2 mt-8">
                        <a
                            href={`${marketingUrl}app`}
                            className="text-white border-b border-white pb-1"
                        >
                            Learn more
                        </a>
                    </div>
                </div>
                <div
                    className="overflow-hidden hidden lg:flex justify-end items-end"
                    style={{
                        position: 'absolute',
                        bottom: '-1px',
                        right: '-7px',
                        width: '700px',
                        height: '100%',
                        marginRight: '7px',
                        zIndex: -1,
                        borderBottomRightRadius: '0.375rem',
                    }}
                >
                    <div
                        className="hidden lg:block position: absolute bottom-4 right-4"
                        style={{
                            backgroundColor: colors.blueSteel,
                            filter: 'blur(105.852px)',
                            height: '350px',
                            width: '350px',
                            zIndex: -10,
                        }}
                    />
                    <img
                        src={desktopBackground}
                        alt="background"
                        className="hidden lg:block -mb-1 -mr-1"
                        style={{ height: '487px' }}
                    />
                </div>
                <a
                    href={`${marketingUrl}app`}
                    className="fixed bottom-16 border-b border-white text-white text-sm hidden lg:block"
                    style={{ right: '180px', zIndex: 10 }}
                >
                    Learn more
                </a>
            </div>
            <div className="absolute -bottom-14 lg:-bottom-11 left-0 text-xs flex flex-col lg:w-848">
                <span className="text-center">{messages.warningTop}</span>
                <span className="text-center">{messages.warningBottom}</span>
            </div>
        </div>
    );

    return (
        <Modal
            show={isOpen}
            hideModal={handleCloseModal}
            content={modalContent}
            hasBlurredBackground
            isFullWidth
            innerBackground={modalBackgroundMobile}
            preventOverflow
        />
    );
};

export default OnboardingModal;
