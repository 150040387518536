import { Country } from '../entities/country';
import api from './api';

const getCountries = async (): Promise<Country[] | null> => {
    try {
        const result = await api.get(`references/countries`, {});
        return result.data;
    } catch (Exception) {
        console.error(Exception);
        return null;
    }
};

const apiCountryActions = {
    getCountries,
};

export default apiCountryActions;
