/* eslint-disable @typescript-eslint/ban-ts-ignore */
// eslint:disable:no-var-requires
import { Route } from '.';
import NotFound from '../pages/404';
import asyncComponent from '../utils/async-component';

// PORTFOLIO
// const Portfolio = asyncComponent(() => import('../pages/Portfolio'));
const PortfolioV2 = asyncComponent(() => import('../pages/Portfolio/v2'));
const PortfolioV3 = asyncComponent(() => import('../pages/Portfolio/v3'));
// PRODUCTS
const ProductExplore = asyncComponent(() => import('pages/Products/ProductExplore'));
const ProductDetails = asyncComponent(() => import('pages/Products/v2/ProductDetails'));
// AUTH
const LoginView = asyncComponent(() => import('../pages/Login'));
const RegisterView = asyncComponent(() => import('../pages/Register'));
const ResetPasswordView = asyncComponent(() => import('../pages/ResetPassword'));
// VERIFICATION
const ProfileVerification = asyncComponent(() => import('../pages/ProfileVerification'));
const CustomerPending = asyncComponent(() => import('../pages/Profile/Pending'));
const DocumentUploaded = asyncComponent(() => import('../pages/DocumentUploaded/DocumentUploaded'));
const VerificationError = asyncComponent(
    () => import('../pages/VerificationError/VerificationError')
);
// PROFILE
const Profile = asyncComponent(() => import('../pages/Profile'));
const ProfileLoginMethod = asyncComponent(() => import('../pages/Profile/ProfileLoginMethod'));
const BankDetailsView = asyncComponent(() => import('../pages/BankDetails'));
const ResetTwoFactor = asyncComponent(() => import('../pages/ResetTwoFactor'));
const Funds = asyncComponent(() => import('../pages/Funds'));
const Rewards = asyncComponent(() => import('../pages/Rewards'));
const DepositFundsSelect = asyncComponent(() => import('../pages/Funds/DepositFundsSelect'));
const BankDeposit = asyncComponent(() => import('../pages/Funds/BankDeposit'));
const BankDepositPOP = asyncComponent(() => import('../pages/Funds/BankDepositPOP'));
const CryptoDeposit = asyncComponent(() => import('../pages/Funds/CryptoDeposit'));
const CryptoDepositV2 = asyncComponent(() => import('../pages/Funds/v2/CryptoDeposit'));
const FiatDeposit = asyncComponent(() => import('../pages/Funds/FiatDeposit'));
const LinkedAccounts = asyncComponent(() => import('../pages/Funds/LinkedAccounts'));
const LinkedDeposit = asyncComponent(() => import('../pages/Funds/LinkedDeposit'));
const ManageLinkedAccount = asyncComponent(() => import('../pages/Funds/ManageLinkedAccount'));
const DepositAccountName = asyncComponent(() => import('../pages/Funds/DepositAccountName'));
const TwoFactor = asyncComponent(() => import('../pages/TwoFactor'));
const EnableOtp = asyncComponent(() => import('../pages/EnableOtp'));
const BTCView = asyncComponent(() => import('../pages/BtcDetails'));
const CryptoWalletView = asyncComponent(() => import('../pages/CryptoWalletDetails'));
const UpdateEmail = asyncComponent(() => import('../pages/UpdateEmail'));
const UpdateEmailVerification = asyncComponent(() => import('../pages/UpdateEmailVerification'));
const UpdatePassword = asyncComponent(() => import('../pages/UpdatePassword'));
// DEPOSIT
const CryptoDepositCode = asyncComponent(() => import('../pages/Funds/CryptoDepositCode'));
const CryptoDepositCodeV2 = asyncComponent(() => import('../pages/Funds/v2/CryptoDepositCode'));
const CryptoDepositDisclaimer = asyncComponent(
    () => import('../pages/Funds/CryptoDepositDisclaimer')
);
const CryptoDepositDisclaimerV2 = asyncComponent(
    () => import('../pages/Funds/v2/CryptoDepositDisclaimer')
);
const CryptoDepositComplete = asyncComponent(() => import('../pages/Funds/CryptoDepositComplete'));
// WITHDRAW
const WithdrawFundsSelect = asyncComponent(() => import('../pages/Funds/WithdrawFundsSelect'));
const BankWithdraw = asyncComponent(() => import('../pages/Funds/BankWithdraw'));
const BankWithdrawDetails = asyncComponent(() => import('../pages/Funds/BankWithdrawDetails'));
const BankWithdrawDetailsEdit = asyncComponent(
    () => import('../pages/Funds/BankWithdrawDetailsEdit')
);
const BankWithdraw2FA = asyncComponent(() => import('../pages/Funds/BankWithdraw2FA'));
const BankWithdrawOtp = asyncComponent(() => import('../pages/Funds/BankWithdrawOtp'));
const BankWithdrawComplete = asyncComponent(() => import('../pages/Funds/BankWithdrawComplete'));
const CryptoWithdrawDetails = asyncComponent(() => import('../pages/Funds/CryptoWithdrawDetails'));
const CryptoWithdrawDetailsEdit = asyncComponent(
    () => import('../pages/Funds/CryptoWithdrawDetailsEdit')
);
const CryptoWithdraw = asyncComponent(() => import('../pages/Funds/CryptoWithdraw'));
const CryptoWithdraw2FA = asyncComponent(() => import('../pages/Funds/CryptoWithdraw2FA'));
const CryptoWithdrawOtp = asyncComponent(() => import('../pages/Funds/CryptoWithdrawOtp'));
const CryptoWithdrawComplete = asyncComponent(
    () => import('../pages/Funds/CryptoWithdrawComplete')
);
const WithdrawPending = asyncComponent(() => import('../pages/Funds/Pending'));

// TRANSACTIONS
const PendingTransactions = asyncComponent(
    () => import('../pages/Transactions/PendingTransactions')
);
const CompletedTransactions = asyncComponent(
    () => import('../pages/Transactions/CompletedTransactions')
);
const AllTransactions = asyncComponent(() => import('../pages/Transactions/AllTransactions'));
const VaultTransactions = asyncComponent(() => import('../pages/Transactions/VaultTransactions'));
const RewardsTransactions = asyncComponent(
    () => import('../pages/Transactions/RewardsTransactions')
);

// TRADE
const SellProductsPage = asyncComponent(() => import('pages/Trade/v3/Sell/SellProducts'));
const BuyAmount = asyncComponent(() => import('../pages/Trade/v3/Buy/BuyAmount/BuyAmount'));
const BuyConfirm = asyncComponent(() => import('../pages/Trade/v3/Buy/BuyConfirm/BuyConfirm'));
const BuyComplete = asyncComponent(() => import('../pages/Trade/v3/Buy/BuyComplete/BuyComplete'));

const BuyProductsPage = asyncComponent(() => import('pages/Trade/v3/Buy/BuyProducts'));
const SellAmount = asyncComponent(() => import('../pages/Trade/v3/Sell/SellAmount/SellAmount'));
const SellConfirm = asyncComponent(() => import('../pages/Trade/v3/Sell/SellConfirm/SellConfirm'));
const SellComplete = asyncComponent(
    () => import('../pages/Trade/v3/Sell/SellComplete/SellComplete')
);

// VAULT
const LockAmount = asyncComponent(() => import('../pages/Vault/Lock/LockAmount'));
const LockConfirm = asyncComponent(() => import('../pages/Vault/Lock/LockConfirm'));
const LockComplete = asyncComponent(() => import('../pages/Vault/Lock/LockComplete'));

const UnlockAmount = asyncComponent(() => import('../pages/Vault/Unlock/UnlockAmount'));
const UnlockConfirm = asyncComponent(() => import('../pages/Vault/Unlock/UnlockConfirm'));
const UnlockComplete = asyncComponent(() => import('../pages/Vault/Unlock/UnlockComplete'));

/**
 * If a route is a sub-route of another, the parent must pass down the child route via its
 * children property.
 */
const routes: Route[] = [
    {
        path: '/404',
        Component: NotFound,
    },
    {
        path: '/dashboard',
        Component: PortfolioV3,
    },
    {
        path: '/dashboard-v2',
        Component: PortfolioV2,
    },
    {
        path: '/login',
        Component: LoginView,
        public: true,
    },
    {
        path: '/register',
        Component: RegisterView,
        public: true,
    },
    {
        path: '/reset-password',
        Component: ResetPasswordView,
        public: true,
    },
    {
        path: '/profile/bank-details',
        Component: BankDetailsView,
        public: false,
    },
    {
        path: '/reset-two-factor',
        Component: ResetTwoFactor,
        public: true,
    },
    {
        path: '/products/explore',
        Component: ProductExplore,
        public: false,
    },
    {
        path: '/products/:productCode',
        Component: ProductDetails,
        public: false,
    },
    {
        path: '/profile/account-details/linked-accounts/stitch/complete',
        Component: DepositAccountName,
        public: false,
    },
    {
        path: '/funds/add/select/eft/:currency/pop',
        Component: BankDepositPOP,
        public: false,
    },
    {
        path: '/funds/add/select/fiat/eft/:currency',
        Component: BankDeposit,
        public: false,
    },
    {
        path: '/profile/account-details/linked-accounts/manage-accounts',
        Component: ManageLinkedAccount,
        public: false,
    },
    {
        path: '/profile/account-details/linked-accounts',
        Component: LinkedAccounts,
        public: false,
    },
    {
        path: '/funds/add/select/fiat/linked',
        Component: LinkedAccounts,
        public: false,
    },
    {
        path: '/funds/add/select/fiat/linked-account/:currency/complete',
        Component: LinkedDeposit,
        public: false,
    },
    {
        path: '/funds/add/select/crypto/:currency/complete',
        Component: CryptoDepositComplete,
        public: false,
    },
    {
        path: '/funds/add/select/crypto/code/:currency',
        Component: CryptoDepositCode,
        public: false,
    },
    {
        path: '/funds/add/select/crypto-v2/code/:currency',
        Component: CryptoDepositCodeV2,
        public: false,
    },
    {
        path: '/funds/add/select/crypto/disclaimer/:currency',
        Component: CryptoDepositDisclaimer,
        public: false,
    },
    {
        path: '/funds/add/select/crypto-v2/disclaimer/:currency',
        Component: CryptoDepositDisclaimerV2,
        public: false,
    },
    {
        path: '/funds/add/select/crypto',
        Component: CryptoDeposit,
        public: false,
    },
    {
        path: '/funds/add/select/crypto-v2',
        Component: CryptoDepositV2,
        public: false,
    },
    {
        path: '/funds/add/select/fiat',
        Component: FiatDeposit,
        public: false,
    },
    {
        path: '/funds/add/select',
        Component: DepositFundsSelect,
        public: false,
    },
    {
        path: '/funds/withdraw/:currencyType/:currency/pending',
        Component: WithdrawPending,
        public: false,
    },
    {
        path: '/funds/withdraw/fiat/2FA',
        Component: BankWithdraw2FA,
        public: false,
    },
    {
        path: '/funds/withdraw/fiat/OTP',
        Component: BankWithdrawOtp,
        public: false,
    },
    {
        path: '/funds/withdraw/fiat/:currency/bank-details-review',
        Component: BankWithdrawDetails,
        public: false,
    },
    {
        path: '/funds/withdraw/fiat/bank-details',
        Component: BankWithdrawDetailsEdit,
        public: false,
    },
    {
        path: '/funds/withdraw/fiat/:currency/complete',
        Component: BankWithdrawComplete,
        public: false,
    },
    {
        path: '/funds/withdraw/fiat/:currency',
        Component: BankWithdraw,
        public: false,
    },
    {
        path: '/funds/withdraw/crypto/:currency/complete',
        Component: CryptoWithdrawComplete,
        public: false,
    },
    {
        path: '/funds/withdraw/crypto/2FA',
        Component: CryptoWithdraw2FA,
        public: false,
    },
    {
        path: '/funds/withdraw/crypto/OTP',
        Component: CryptoWithdrawOtp,
        public: false,
    },
    {
        path: '/funds/withdraw/crypto/:currency/details-edit',
        Component: CryptoWalletView,
        public: false,
    },
    {
        path: '/funds/withdraw/crypto/:currency/details',
        Component: CryptoWithdrawDetails,
        public: false,
    },
    {
        path: '/funds/withdraw/crypto/:currency',
        Component: CryptoWithdraw,
        public: false,
    },
    {
        path: '/funds/withdraw',
        Component: WithdrawFundsSelect,
        public: false,
    },
    {
        path: '/trade/buy/:toProduct/:fromProduct/confirm',
        Component: BuyConfirm,
        public: false,
    },
    {
        path: '/trade/buy/:toProduct/:fromProduct/complete',
        Component: BuyComplete,
        public: false,
    },
    {
        path: '/trade/buy/:toProduct/:fromProduct',
        Component: BuyAmount,
        public: false,
    },
    {
        path: '/trade/buy/:productCode',
        Component: BuyProductsPage,
        public: false,
    },
    // TODO: discuss with Brand to
    // - use the new route above 'trade/buy/:productCode'
    // - deprecate the below route 'funds/invest/:productBundle'
    {
        path: '/funds/invest/:productBundle',
        Component: BuyProductsPage,
        // Component: Invest,
    },
    {
        path: '/trade/sell/:fromProduct/:toProduct/confirm',
        Component: SellConfirm,
        public: false,
    },
    {
        path: '/trade/sell/:fromProduct/:toProduct/complete',
        Component: SellComplete,
        public: false,
    },
    {
        path: '/trade/sell/:fromProduct/:toProduct',
        Component: SellAmount,
        public: false,
    },
    {
        path: '/trade/sell/:productCode',
        Component: SellProductsPage,
        public: false,
    },
    // TODO: discuss with Brand to
    // - use the new route above 'trade/sell/:productCode'
    // - deprecate the below route 'funds/sell/:productBundle'
    {
        path: '/funds/sell/:productBundle',
        Component: SellProductsPage,
    },
    {
        path: '/funds',
        Component: Funds,
        public: false,
    },
    {
        path: '/profile/bitcoin-address',
        Component: BTCView,
        public: false,
    },
    {
        path: '/profile/wallet/:productCode',
        Component: CryptoWalletView,
        public: false,
    },
    {
        path: '/profile/otp',
        Component: EnableOtp,
        public: false,
    },
    {
        path: '/profile/two-factor',
        Component: TwoFactor,
        public: false,
    },
    {
        path: '/profile/update-email/verify',
        Component: UpdateEmailVerification,
        public: false,
    },
    {
        path: '/profile/update-email',
        Component: UpdateEmail,
    },
    {
        path: '/profile/update-password',
        Component: UpdatePassword,
        public: false,
    },
    {
        path: '/profile/login-method',
        Component: ProfileLoginMethod,
    },
    // {
    //    path: '/profile/verification',
    //    Component: ProfileVerification,
    //    public: false,
    // },
    {
        path: '/profile',
        Component: Profile,
        public: false,
    },
    {
        path: '/customer/:verificationType/pending',
        Component: CustomerPending,
        public: false,
    },
    {
        path: '/rewards',
        Component: Rewards,
        public: false,
    },
    {
        path: '/document-uploaded',
        Component: DocumentUploaded,
        public: true,
    },
    {
        path: '/verification-error',
        Component: VerificationError,
        public: true,
    },
    {
        path: '/transactions/pending',
        Component: PendingTransactions,
        public: false,
    },
    {
        path: '/transactions/rewards',
        Component: RewardsTransactions,
        public: false,
    },
    {
        path: '/transactions/completed',
        Component: CompletedTransactions,
        public: false,
    },
    {
        path: '/transactions/all',
        Component: AllTransactions,
        public: false,
    },
    {
        path: '/transactions/vault',
        Component: VaultTransactions,
        public: false,
    },
    {
        path: '/vault/lock/:fromProduct/confirm',
        Component: LockConfirm,
        public: false,
    },
    {
        path: '/vault/lock/:fromProduct/complete',
        Component: LockComplete,
        public: false,
    },
    {
        path: '/vault/lock/:fromProduct',
        Component: LockAmount,
        public: false,
    },
    {
        path: '/vault/unlock/:toProduct/confirm',
        Component: UnlockConfirm,
        public: false,
    },
    {
        path: '/vault/unlock/:toProduct/complete',
        Component: UnlockComplete,
        public: false,
    },
    {
        path: '/vault/unlock/:toProduct',
        Component: UnlockAmount,
        public: false,
    },
];

export default routes;
