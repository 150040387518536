/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BankDetails, BankDetailsResponse } from '../../entities/bankDetails';

export interface BankDetailsState {
    loading: boolean;
    error: boolean;
    errorMessage: string;
    bankDetails: BankDetails;
    bankDetailsResponse: BankDetailsResponse;
}

const initialState: BankDetailsState = {
    loading: false,
    error: false,
    errorMessage: '',
    bankDetails: {
        countryCode: '',
        bankName: '',
        accountId: '',
        twofaPin: '',
    },
    bankDetailsResponse: {
        message: '',
        statusCode: 0,
        userId: '',
    },
};

const bankDetails = createSlice({
    name: 'bankDetails',
    initialState,
    reducers: {
        addBankDetailsStart(state) {
            state.loading = true;
            state.error = false;
            state.errorMessage = '';
        },
        addBankDetailsEnd(state) {
            state.loading = false;
        },
        addBankDetailsError(state, action: PayloadAction<BankDetailsResponse | any>) {
            state.loading = false;
            state.error = true;
            state.errorMessage = action.payload;
            state.bankDetailsResponse = action.payload;
        },
        setAddBankDetailsResponse(state, action: PayloadAction<BankDetailsResponse>) {
            state.bankDetailsResponse = action.payload;
        },
        getBankDetailsStart(state) {
            state.loading = true;
        },
        getBankDetailsEnd(state) {
            state.loading = false;
        },
        getBankDetailsError(state, action: PayloadAction<BankDetails>) {
            state.loading = false;
            state.bankDetails = action.payload;
        },
        getAddBankDetailsData(state, action: PayloadAction<BankDetails>) {
            state.loading = false;
            state.bankDetails = action.payload;
        },
        resetBankDetailsError(state) {
            state.error = false;
            state.errorMessage = '';
        },
    },
});

export const {
    addBankDetailsStart,
    addBankDetailsEnd,
    setAddBankDetailsResponse,
    addBankDetailsError,
    getBankDetailsStart,
    getBankDetailsEnd,
    getAddBankDetailsData,
    getBankDetailsError,
    resetBankDetailsError,
} = bankDetails.actions;

export default bankDetails.reducer;
