import { AxiosError, AxiosResponse } from 'axios';
import api from './api';

export const getUserTerms = async (): Promise<UserTermDto[] | null> => {
    try {
        const result = await api.get(`/terms`, {});

        return result.data;
    } catch (Exception) {
        console.error(Exception);
        return null;
    }
};

export const acceptTerms = async ({
    termCode,
}: {
    termCode: string;
}): Promise<AxiosResponse | AxiosError> => {
    try {
        const result: AxiosResponse = await api.put(`/terms/${termCode}`);
        return result;
    } catch (Exception) {
        return Exception;
    }
};

export interface UserTermDto {
    code: string;
    accepted?: boolean;
}
