import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import mixpanel from 'mixpanel-browser';
import './styles';
import App from './App';
// import './other/reactotron-config';
import * as config from '../package.json';
import Error from './pages/Error';

Bugsnag.start({
    apiKey: process.env.REACT_APP_BUG_SNAG_API_KEY as string,
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.NODE_ENV || 'Development',
    appVersion: config.version,
});

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN as string, {
    debug: process.env.REACT_APP_ENV === 'Development',
    // eslint-disable-next-line @typescript-eslint/camelcase
    ignore_dnt: true,
});

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);

ReactDOM.render(
    <React.StrictMode>
        {typeof ErrorBoundary !== 'undefined' ? (
            <ErrorBoundary FallbackComponent={Error}>
                <App />
            </ErrorBoundary>
        ) : (
            <App />
        )}
    </React.StrictMode>,
    document.getElementById('root')
);
