import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useLogout from 'hooks/use-logout';
import usePageConfig from 'hooks/use-page-config';
import SideNavView from './SideNavView';
import appHelpers from '../../helpers/app-urls';
import { RootState } from '../../store/reducer';

interface SideNavProps {
    children: React.ReactNode;
}

const cdnURL = appHelpers.getCDNUrl();
const portfolioImg = `${cdnURL}assets/navigation/portfolio.svg`;
const productsImg = `${cdnURL}assets/navigation/products.svg`;
const rewardsImg = `${cdnURL}assets/navigation/rewards.svg`;
const profileImg = `${cdnURL}assets/navigation/profile.svg`;
const resourcesImg = `${cdnURL}assets/navigation/resources.svg`;
const transactionsImg = `${cdnURL}assets/navigation/transactions.svg`; // TODO add image to CDN
const savingsImg = `${cdnURL}assets/navigation/savings.svg`;

const closeImg = `${cdnURL}assets/navigation/close.svg`;
const closeDarkImg = `${cdnURL}assets/navigation/close-dark.svg`;
const closeArrowImg = `${cdnURL}assets/navigation/arrow.svg`;
const closeArrowDarkImg = `${cdnURL}assets/navigation/arrow-dark.svg`;
const facebookImg = `${cdnURL}assets/navigation/facebook.svg`;
const instagramImg = `${cdnURL}assets/navigation/instagram.svg`;
const linkedinImg = `${cdnURL}assets/navigation/linkedin.svg`;
const logoutImg = `${cdnURL}assets/navigation/logout.svg`;
const menuImg = `${cdnURL}assets/navigation/menu.svg`;
const menuDarkImg = `${cdnURL}assets/navigation/menu-dark.svg`;
const starImg = `${cdnURL}assets/navigation/star.svg`;
const twitterImg = `${cdnURL}assets/navigation/x.svg`;

// Active nav items
const portfolioActive = `${cdnURL}assets/navigation/portfolio-active.svg`;
const productsActive = `${cdnURL}assets/navigation/products-active.svg`;
const rewardsActive = `${cdnURL}assets/navigation/rewards-active.svg`;
const profileActive = `${cdnURL}assets/navigation/profile-active.svg`;
const resourcesActive = `${cdnURL}assets/navigation/resources-active.svg`;
const transactionsActive = `${cdnURL}assets/navigation/transactions-active.svg`; // TODO add image to CDN
const savingsActive = `${cdnURL}assets/navigation/savings-active.svg`;

// Bottom Navigation
const bottomPortfolioImg = `${cdnURL}assets/navigation/bottomNavigation/revix-logo.svg`;

export default function SideNav(props: SideNavProps) {
    // #region HOOKS
    const logout = useLogout();
    const pageConfig = usePageConfig();
    // #endregion

    const { theme } = useSelector((state: RootState) => state.theme);
    const { rewardsDetails, loading } = useSelector((state: RootState) => state.rewards);
    const { isTourOpen } = useSelector((state: RootState) => state.app);

    const [menuOpen, setMenuOpen] = useState(false);
    const [resourceOpen, setResourceOpen] = useState(false);
    const [hideBottomNav, setHideBottomNav] = useState(false);

    // #region WIP
    const icons = {
        portfolioBottom: {
            normal: bottomPortfolioImg,
            active: bottomPortfolioImg,
        },
        portfolio: {
            normal: portfolioImg,
            active: portfolioActive,
        },
        savings: {
            normal: savingsImg,
            active: savingsActive,
        },
        rewards: {
            normal: rewardsImg,
            active: rewardsActive,
        },
        profile: {
            normal: profileImg,
            active: profileActive,
        },
        resources: {
            normal: resourcesImg,
            active: resourcesActive,
        },
        products: {
            normal: productsImg,
            active: productsActive,
        },
        transactions: {
            normal: transactionsImg,
            active: transactionsActive,
        },
    };

    const createNavItem = (name: string, icon: { normal: any; active: any }, subItems?: any[]) => {
        let path = '';
        const nameLowerCase = name.toLowerCase();
        if (nameLowerCase === 'transactions') {
            path = 'transactions/pending';
        } else if (nameLowerCase === 'savings') {
            path = 'products/USDT/vault';
        } else if (nameLowerCase === 'products' || nameLowerCase === 'explore') {
            path = 'products/explore';
        } else if (nameLowerCase !== 'portfolio') {
            path = nameLowerCase;
        } else if (nameLowerCase === 'portfolio') {
            path = 'dashboard';
        }

        return {
            name,
            route: `/${path}`,
            logo: icon.normal,
            alt: name,
            activeLogo: icon.active,
            active: false,
            subItems,
        };
    };
    // #endregion

    const location = useLocation();

    const ignoreRoutes = ['/funds/invest', 'funds/sell'];

    let navItems = [
        createNavItem('Portfolio', icons.portfolio),
        createNavItem('Explore', icons.products),
        createNavItem('Transactions', icons.transactions),
        createNavItem('Rewards', icons.rewards),
        createNavItem('Profile', icons.profile),
        // createNavItem('Resources', icons.resources),
    ];

    let mobileNavItems = [
        createNavItem('Transactions', icons.transactions),
        // createNavItem('Resources', icons.resources),
    ];

    const activeNavItem = (() => {
        const getRouteName = (path: string) => {
            if (path === '/dashboard') return 'portfolio';
            if (path === '/products/USDT/vault') return 'savings';

            const [, routeName] = path.split('/');
            return routeName;
        };

        if (!ignoreRoutes.some((x) => new RegExp(x, 'i').test(location.pathname))) {
            const pathName = getRouteName(location.pathname);
            let routeName: string;

            if (pathName === 'dashboard') routeName = 'portfolio';
            if (pathName !== 'dashboard') routeName = pathName;

            const route = navItems.find((navItem) => {
                const navRouteName = getRouteName(navItem.route);
                return navRouteName === routeName;
            });
            return route?.name || '';
        }
        return '';
    })();

    const navAssets = [
        { name: 'close', logo: theme === 'dark' ? closeImg : closeDarkImg },
        { name: 'closeArrow', logo: theme === 'dark' ? closeArrowDarkImg : closeArrowImg },
        { name: 'facebook', logo: facebookImg },
        { name: 'instagram', logo: instagramImg },
        { name: 'linkedin', logo: linkedinImg },
        { name: 'logout', logo: logoutImg },
        { name: 'menu', logo: theme === 'dark' ? menuImg : menuDarkImg },
        { name: 'star', logo: starImg },
        { name: 'twitter', logo: twitterImg },
    ];

    let bottomNavItems = [
        createNavItem('Portfolio', icons.portfolio),
        createNavItem('Explore', icons.products),
        createNavItem('Rewards', icons.rewards),
        createNavItem('Profile', icons.profile),
    ];

    navItems = navItems.map((nav) => {
        if (nav.name === activeNavItem) {
            return {
                ...nav,
                active: true,
            };
        }

        return nav;
    });

    bottomNavItems = bottomNavItems.map((nav) => {
        if (nav.name === activeNavItem) {
            return {
                ...nav,
                active: true,
            };
        }

        return nav;
    });

    mobileNavItems = mobileNavItems.map((nav) => {
        if (nav.name === activeNavItem) {
            return {
                ...nav,
                active: true,
            };
        }

        return nav;
    });

    const resourceClick = () => {
        // setResourceOpen(!resourceOpen);
        window.open(pageConfig?.App?.HelpCenterUrl, '_blank');
    };

    const navToResource = (link: string) => {
        window.open(link, '_blank', 'noopener noreferrer');
    };

    const renderStars = () => {
        return Array(rewardsDetails.stars)
            .fill(0)
            .map((_, i) => {
                const imgPrefix = rewardsDetails?.currentTierName
                    ?.toLowerCase()
                    ?.replace(/[%20/\s]/g, '');
                const imgUrl = `${cdnURL}assets/rewards/side-navigation/${imgPrefix}-side-star.svg`;
                return (
                    <img
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                        className="m-1"
                        src={imgUrl}
                        alt="star"
                    />
                );
            });
    };

    useEffect(() => {
        const locationString = location.pathname.split('/');
        if (
            ['products', 'productsV2'].includes(locationString[1]) &&
            locationString[2].length > 0 &&
            locationString[2] !== 'explore'
        ) {
            setHideBottomNav(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <SideNavView
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            navItems={navItems}
            mobileNavItems={mobileNavItems}
            bottomNavItems={bottomNavItems}
            navAssets={navAssets}
            activeItem={activeNavItem}
            logout={logout}
            theme={theme}
            resourceClick={resourceClick}
            resourceOpen={resourceOpen}
            navToResource={navToResource}
            rewardsDetails={rewardsDetails}
            renderStars={renderStars}
            rewardsLoading={loading}
            hideBottomNav={hideBottomNav}
            isTourOpen={false}
        >
            {props.children}
        </SideNavView>
    );
}
