import { Language } from '../../store/slices/language-slice';

interface NotFoundMessages {
    error: {
        errorHeading: string;
        errorMessage: string;
        supportMessagePreLink: string;
        supportMessagePostLink: string;
        buttonText: string;
    };
}

type Message = {
    [key in Language]: NotFoundMessages;
};

const messages: Message = {
    eng: {
        error: {
            errorHeading: 'An Error occurred',
            buttonText: 'Portfolio',
            errorMessage:
                'We have been notified of this error. Please navigate back to your portfolio and try again.',
            supportMessagePreLink: 'If the problem persists please reach out to',
            supportMessagePostLink: 'for further assistance.',
        },
    },
};

export default messages;
