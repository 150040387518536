import { Client } from '../entities/auth';
import { TwoFactorLoginResponse } from '../entities/login';
import authApi from './auth-api';
import api from './api';

export async function clientApplicationSSOs() {
    try {
        const { status, data } = await api.get('references/clientApplicationSsos');
        return data;
    } catch (Exception) {
        return Exception.response.data;
    }
}

export async function login(username: string, password: string, recaptcha: any): Promise<Client> {
    try {
        const { data } = await authApi.post('/authentication/login', {
            clientCode: 'DASHBOARD',
            username,
            password,
            recaptcha,
        });
        return data;
    } catch (Exception) {
        console.error(Exception);
        return Exception.response.data;
    }
}

export const loginWithProviderService = async (payload: {
    provider: 'APPLE' | 'GOOGLE';
    ssoJwt: string;
    // clientCode: string;
    clientApplicationSsoCode: string;
}) => {
    const url = `authentication/loginWithProvider`;
    try {
        const { data } = await authApi.post(url, {
            ssoJwt: payload.ssoJwt,
            clientApplicationSsoCode: payload?.clientApplicationSsoCode,
        });
        if (data) {
            return data;
        }
        return null;
    } catch (Exception) {
        console.error(Exception);
        return Exception.response.data;
    }
};

export async function twoFactorLogin(pin: string, token: string): Promise<TwoFactorLoginResponse> {
    try {
        const { data } = await authApi.post('/authentication/twoFactorLogin', { pin, token });
        return { success: false, ...data };
    } catch (Exception) {
        console.error(Exception);
        return Exception.response.data;
    }
}

export async function refreshToken(): Promise<Client> {
    try {
        const { data } = await authApi.get('/authentication/refreshToken', {
            headers: {
                Authorization: api.defaults.headers.Authorization,
            },
        });
        return data;
    } catch (Exception) {
        return Exception.response.data;
    }
}
