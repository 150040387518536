import { Asset, TimeInterval } from 'entities/productsV2';
import { TimeFrameKey } from 'entities';
import { getGraphs } from 'services/api-graphs';
import { Dispatch } from '@reduxjs/toolkit';
import { AppThunk } from '..';
import {
    setProductsAggregatedList,
    setProductsAggregatedListLoading,
    setProductsAggregatedListError,
    setProductsRewards,
    setProductsRewardsLoading,
    setProductsRewardsError,
    setProductsList,
    setProductsListLoading,
    setProductsListError,
    setProductsRatesGains,
    setProductsRatesGainsLoading,
    setProductsRatesGainsError,
    setProductsVaultGains,
    setProductsVaultGainsLoading,
    setProductsVaultGainsError,
    setProductsReservedAmounts,
    setProductsReservedAmountsLoading,
    setProductsReservedAmountsError,
    setPortfolioTimeInterval,
    setProductsTimeInterval,
    setReferencesAssets,
    setReferencesAssetsLoading,
    setReferencesAssetsError,
    setProductDisplayRates,
    setProductDisplayRatesLoading,
    setProductDisplayRatesError,
    setProductsListPendingOrders,
    setProductsListPendingOrdersLoading,
    setProductsListPendingOrdersError,
    setProductContent,
    setProductContentError,
    setProductContentLoading,
    setAllGraphsData,
} from './productsV2-slice';
import * as productsV2Service from '../../services/api-productsV2';

export const fetchPortfolioV2AggregatedAction = (timeFrame: string): AppThunk => async (
    dispatch
) => {
    dispatch(setProductsAggregatedListLoading(true));
    try {
        const data = await productsV2Service.fetchPortfolioV2Aggregated(timeFrame);
        if (data !== null) {
            dispatch(setProductsAggregatedList(data));
        } else {
            dispatch(setProductsAggregatedListError(data));
        }
        dispatch(setProductsAggregatedListLoading(false));
    } catch (error) {
        dispatch(setProductsAggregatedListError(error));
        dispatch(setProductsAggregatedListLoading(false));
    }
};

export const fetchPortfolioV2RewardsAction = (): AppThunk => async (dispatch) => {
    dispatch(setProductsRewardsLoading(true));
    try {
        const data = await productsV2Service.fetchPortfolioV2Rewards();
        if (data !== null) {
            dispatch(setProductsRewards(data));
        } else {
            dispatch(setProductsRewardsError(data));
        }
        dispatch(setProductsRewardsLoading(false));
    } catch (error) {
        dispatch(setProductsRewardsError(error));
        dispatch(setProductsRewardsLoading(false));
    }
};

export const fetchPortfolioV2ProductsAndHoldingsAction = (): AppThunk => async (dispatch) => {
    dispatch(setProductsListLoading(true));
    try {
        const data = await productsV2Service.fetchPortfolioV2ProductsAndHoldings();
        if (data !== null) {
            dispatch(setProductsList(data));
        } else {
            dispatch(setProductsListError(data));
        }
        dispatch(setProductsListLoading(false));
    } catch (error) {
        dispatch(setProductsListError(error));
        dispatch(setProductsListLoading(false));
    }
};

export const fetchVaultGainsLosses = (): AppThunk => async (dispatch) => {
    dispatch(setProductsVaultGainsLoading(true));
    try {
        const data = await productsV2Service.fetchPortfolioV2VaultGains();
        if (data !== null) {
            dispatch(setProductsVaultGains(data));
        } else {
            dispatch(setProductsVaultGainsError(data));
        }
        dispatch(setProductsVaultGainsLoading(false));
    } catch (error) {
        dispatch(setProductsVaultGainsError(error));
        dispatch(setProductsVaultGainsLoading(false));
    }
};

export const fetchPortfolioV2ReservedAmountsAction = (): AppThunk => async (dispatch) => {
    dispatch(setProductsReservedAmountsLoading(true));
    try {
        const data = await productsV2Service.fetchPortfolioV2ReservedAmounts();
        if (data !== null) {
            dispatch(setProductsReservedAmounts(data));
        } else {
            dispatch(setProductsReservedAmountsError(data));
        }
        dispatch(setProductsReservedAmountsLoading(false));
    } catch (error) {
        dispatch(setProductsReservedAmountsError(error));
        dispatch(setProductsReservedAmountsLoading(false));
    }
};

export const setPortfolioTimeIntervalAction = (timeInterval: TimeInterval): AppThunk => (
    dispatch
) => {
    dispatch(setPortfolioTimeInterval(timeInterval));
};

export const setProductsTimeIntervalAction = (timeInterval: TimeInterval): AppThunk => (
    dispatch
) => {
    dispatch(setProductsTimeInterval(timeInterval));
};

export const fetchReferencesAssetsAction = (fetchContent = false): AppThunk => async (dispatch) => {
    dispatch(setReferencesAssetsLoading(true));
    try {
        const data = await productsV2Service.fetchReferencesAssets();

        if (data !== null) {
            if (fetchContent) {
                // if no item with copy is found, load all copy
                const productArrayWithCopy: any = await Promise.all(
                    data
                        ?.filter((a: Asset) => a.active && a.colors)
                        .map(async (item: any) => {
                            const detailResponse = await productsV2Service.fetchReferencesAssetByCode(
                                item.code
                            );
                            const result = {
                                ...item,
                                productCopy: detailResponse?.productCopy || null,
                            };

                            return result;
                        })
                );
                dispatch(setReferencesAssets(productArrayWithCopy));
            } else {
                dispatch(setReferencesAssets(data));
            }
        } else {
            dispatch(setReferencesAssetsError(data));
        }
        dispatch(setReferencesAssetsLoading(false));
    } catch (error) {
        dispatch(setReferencesAssetsError(error));
        dispatch(setReferencesAssetsLoading(false));
    }
};

export const fetchProductsDisplayRatesAction = (
    companyCode: string,
    targetProductCode: string,
    sourceProductCodes: string[],
    callback?: Function
): AppThunk => async (dispatch) => {
    dispatch(setProductDisplayRatesLoading(true));
    try {
        const data = await productsV2Service.fetchProductsDisplayRates(
            companyCode,
            targetProductCode,
            sourceProductCodes
        );
        dispatch(setProductDisplayRatesLoading(false));
        if (data !== null) {
            dispatch(setProductDisplayRates(data));
        } else {
            dispatch(setProductDisplayRatesError(data));
        }
    } catch (error) {
        dispatch(setProductDisplayRatesLoading(false));
        dispatch(setProductDisplayRatesError(error));
    } finally {
        dispatch(setProductDisplayRatesLoading(false));
        // @ts-ignore
        callback?.();
    }
};

export const fetchPendingOrdersAction = (productCode?: string): AppThunk => async (dispatch) => {
    dispatch(setProductsListPendingOrdersLoading(true));
    try {
        const data = await productsV2Service.fetchPendingOrders(productCode);
        if (data !== null) {
            dispatch(setProductsListPendingOrders(data));
        } else {
            dispatch(setProductsListPendingOrdersError(data));
        }
        dispatch(setProductsListPendingOrdersLoading(false));
    } catch (error) {
        dispatch(setProductsListPendingOrdersError(error));
        dispatch(setProductsListPendingOrdersLoading(false));
    }
};
export const fetchReferencesAssetByCodeAction = (productCode: string): AppThunk => async (
    dispatch
) => {
    dispatch(setProductContentLoading(true));
    try {
        const data = await productsV2Service.fetchReferencesAssetByCode(productCode);
        if (data !== null) {
            if (data?.statusCode && data?.statusCode !== 200) {
                dispatch(setProductContentError(data?.message));
            }
            dispatch(setProductContent(data));
        } else {
            dispatch(setProductContentError(data?.message));
        }
        dispatch(setProductContentLoading(false));
    } catch (error) {
        dispatch(setProductContentError(error));
        dispatch(setProductContentLoading(false));
    }
};

export const getProductGraphsAction = ({
    timeFrame,
    productCodes,
    displayCurrencyCode,
}: {
    timeFrame: TimeFrameKey;
    productCodes: string[];
    displayCurrencyCode: string;
}) => async (dispatch: Dispatch) => {
    // console.log(`fetchProductsMetricsAction.request`);
    const productGraphs: any = await Promise.all(
        productCodes?.map(async (productCode: any) => {
            const result = await getGraphs(productCode, displayCurrencyCode, timeFrame);

            return result;
        })
    );

    dispatch(
        setAllGraphsData({
            timeFrame,
            // code: productCode,
            currency: displayCurrencyCode,
            data: productGraphs,
        })
    );

    // dispatch(setGraphsLoading(false));

    // dispatch(setProductGraphs(productGraphs));
};
