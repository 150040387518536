import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducer';
import ModalView from './ModalView';

interface ModalProps {
    content: JSX.Element;
    hideModal: Function | undefined;
    show: boolean;
    hasBlurredBackground?: boolean;
    hasLogo?: boolean;
    logoURL?: string;
    hasTitle?: boolean;
    title?: JSX.Element;
    titleClassName?: string;
    hasFooter?: boolean;
    footer?: JSX.Element;
    closeModal?: Function;
    isRisk?: boolean;
    width?: string;
    height?: string;
    maxWidth?: boolean;
    largeContent?: boolean;
    noneScrollableContent?: boolean;
    hideBgItems?: boolean;
    preventOverflow?: boolean | undefined;
    background?: string;
    titleMarginBottom?: string;
    isAlt?: boolean | undefined;
    isFullWidth?: boolean;
    innerBackground?: string;
}

const Modal = (props: ModalProps) => {
    const { theme } = useSelector((state: RootState) => state.theme);

    return (
        <ModalView
            show={props.show}
            content={props.content}
            hasLogo={props.hasLogo}
            logoURL={props.logoURL}
            hasTitle={props.hasTitle}
            title={props.title}
            titleClassName={props.titleClassName}
            hasFooter={props.hasFooter}
            footer={props.footer}
            closeModal={props.closeModal}
            hideModal={props.hideModal}
            isRisk={props.isRisk}
            width={props.width}
            noneScrollableContent={props.noneScrollableContent}
            height={props.height}
            theme={theme}
            maxWidth={props.maxWidth}
            largeContent={props.largeContent}
            preventOverflow={props.preventOverflow}
            hideBgItems
            background={props.background}
            titleMarginBottom={props.titleMarginBottom}
            isAlt={props.isAlt}
            hasBlurredBackground={props.hasBlurredBackground}
            isFullWidth={props.isFullWidth}
            innerBackground={props.innerBackground}
        />
    );
};

export default Modal;
