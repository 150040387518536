import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { NavLink, useHistory } from 'react-router-dom';
import { useUserProfile } from 'hooks/use-user-profile';
import Transition from 'hooks/use-is-initial-render';
import useWindowSize from 'hooks/use-window-size';
import { RewardsDetails } from 'entities/rewardsDetails';
import mainLogoWhite from 'assets/images/logo-white.svg';
import mainLogo from 'assets/images/logo.svg';
import { setUrl } from 'store/app/app-slice';
import { LocationTypes } from 'entities/external';
import CurrencyIcon from 'components/CurrencyIcon';
import usePageConfig from 'hooks/use-page-config';
import appHelpers from '../../helpers/app-urls';

const cdnURL = appHelpers.getCDNUrl();
const logoutActiveLogo = `${cdnURL}assets/navigation/logout-active.svg`;

const bottomNavItemProps = [
    {
        name: '',
        route: '',
        logo: '',
        alt: '',
        activeLogo: '',
        active: false,
    },
];

const navAssetsProps = {
    name: '',
    logo: '',
};
interface SideNavViewProps {
    children: React.ReactNode;
    menuOpen: boolean;
    setMenuOpen: Function;
    navItems: any;
    mobileNavItems: any;
    bottomNavItems: typeof bottomNavItemProps;
    navAssets: typeof navAssetsProps[];
    activeItem: string;
    logout?: Function;
    theme: string;
    resourceClick?: Function;
    resourceOpen?: boolean;
    navToResource?: Function;
    rewardsDetails?: RewardsDetails;
    renderStars?: Function;
    rewardsLoading?: boolean;
    hideBottomNav?: boolean;
    isTourOpen?: boolean;
}

export default function SideNavView(props: SideNavViewProps) {
    // #region HOOKS
    const history = useHistory();
    const dispatch = useDispatch();
    const { height } = useWindowSize();
    const userProfileResponse = useUserProfile();
    const pageConfig = usePageConfig();
    const social = pageConfig?.App?.Social;
    // #endregion

    // #region STATE
    const [activeHoverItem, setActiveHoverItem] = useState<string>('');
    const [logoutActive, setLogoutActive] = useState<boolean>(false);
    // #endregion

    // #region HELPERS
    const setStepClass = (index: number) => {
        switch (index) {
            case 1:
                return 'step-4';
            case 2:
                return 'step-5';
            case 4:
                return 'step-9';
            default:
                return '';
        }
    };
    // #endregion

    // #region LIFECYCLE
    useEffect(() => {
        return history.listen((location: any) => {
            dispatch(setUrl(location.pathname));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // #endregion

    // #region STYLES
    const activeStyle = {
        boxShadow: '0px 0px 32px 8px #1750F3',
    };

    const socialIconStyle = {
        border: '1px solid #FFFFFF',
        borderRadius: '50%',
        width: '2rem',
        height: '2rem',
        marginLeft: '0.5rem',
        marginRight: '0.5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
    };
    // #endregion

    return (
        <div style={{ height: `${height}px` }} className="flex bg-body-background overflow-hidden">
            {/* Mobile menu */}
            <div className="block absolute xl:hidden">
                <div className="fixed inset-y-0 right-0 flex z-50">
                    <Transition
                        show={props.menuOpen}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0">
                            <div className="absolute inset-0 opacity-75" />
                        </div>
                    </Transition>

                    <Transition
                        show={props.menuOpen}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="translate-x-full"
                        enterTo="-translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                    >
                        <div className="relative flex-1 flex flex-col w-screen">
                            <div className="absolute top-0 right-0 -mr-0 p-1 z-40">
                                <button
                                    onClick={() => props.setMenuOpen(false)}
                                    type="button"
                                    className="flex flex-col items-center justify-center h-12 w-12 rounded-full focus:outline-none z-10"
                                    aria-label="Close sidebar"
                                >
                                    <img
                                        className="w-4 text-white"
                                        src={props.navAssets[1].logo}
                                        alt="Close menu"
                                    />
                                </button>
                            </div>

                            <div className="relative h-0 flex-1 flex flex-col pt-5 pb-4 overflow-y-auto bg-background">
                                <div className="flex items-start justify-start flex-shrink-0 px-4">
                                    <NavLink to="/">
                                        <img
                                            className="h-6 w-auto"
                                            src={props.theme === 'dark' ? mainLogoWhite : mainLogo}
                                            alt="Workflow"
                                        />
                                    </NavLink>
                                </div>

                                <nav className="mt-5 flex-1 bg-background overflow-x-hidden w-full hidden lg:block">
                                    {props.navItems.map((item: any) => {
                                        if (item.name !== 'Resources') {
                                            return (
                                                <button
                                                    type="button"
                                                    onClick={() => history.push(`${item.route}`)}
                                                    key={item.name}
                                                    className={`
                                                                    ${
                                                                        item.active
                                                                            ? 'text-header-text'
                                                                            : 'text-body-text'
                                                                    } mt-4 group w-full flex items-end justify-end px-4 py-2 text-2xl leading-5 font-normal focus:outline-none transition ease-in-out duration-150
                                                              `}
                                                >
                                                    {item.name}
                                                </button>
                                            );
                                        }

                                        return (
                                            <div key={item.name}>
                                                <button
                                                    type="button"
                                                    className={`
                                                        ${
                                                            item.active &&
                                                            props.activeItem === item.name
                                                                ? 'text-header-text'
                                                                : 'text-body-text'
                                                        } mt-4 group w-full flex items-end justify-end px-4 py-2 text-2xl leading-5 font-normal focus:outline-none transition ease-in-out duration-150
                                                    `}
                                                    onClick={() =>
                                                        props.resourceClick && props.resourceClick()
                                                    }
                                                >
                                                    {item.name}
                                                </button>

                                                {props.resourceOpen
                                                    ? item.subItems.map((subItem: any) => (
                                                          <button
                                                              type="button"
                                                              onClick={() =>
                                                                  props.navToResource &&
                                                                  props.navToResource(subItem.route)
                                                              }
                                                              key={subItem.name}
                                                              className={`
                                                                    ${
                                                                        item.active
                                                                            ? 'text-header-text'
                                                                            : 'text-body-text'
                                                                    } mt-4 group w-full flex items-end justify-end px-4 py-2 text-2xl leading-5 font-normal focus:outline-none transition ease-in-out duration-150
                                                              `}
                                                          >
                                                              {subItem.name}
                                                          </button>
                                                      ))
                                                    : null}
                                            </div>
                                        );
                                    })}
                                </nav>

                                <nav className="mt-5 flex-1 bg-background overflow-x-hidden w-full block lg:hidden xl:hidden">
                                    {props.hideBottomNav &&
                                        props.navItems.map((item: any) => {
                                            if (item.name !== 'Resources') {
                                                return (
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            props.setMenuOpen(false);
                                                            history.push(`${item.route}`);
                                                        }}
                                                        key={item.name}
                                                        className={`
                                                                    ${
                                                                        item.active
                                                                            ? 'text-header-text'
                                                                            : 'text-body-text'
                                                                    } mt-4 group w-full flex items-end justify-end px-4 py-2 text-2xl leading-5 font-normal focus:outline-none transition ease-in-out duration-150
                                                              `}
                                                    >
                                                        {item.name}
                                                    </button>
                                                );
                                            }

                                            return (
                                                <div key={item.name}>
                                                    <button
                                                        type="button"
                                                        className={`
                                                        ${
                                                            item.active // &&
                                                                ? // props.activeItem === item.name
                                                                  'text-header-text'
                                                                : 'text-body-text'
                                                        } mt-4 group w-full flex items-end justify-end px-4 py-2 text-2xl leading-5 font-normal focus:outline-none transition ease-in-out duration-150
                                                    `}
                                                        onClick={() =>
                                                            props.resourceClick &&
                                                            props.resourceClick()
                                                        }
                                                    >
                                                        {item.name}
                                                    </button>

                                                    {props.resourceOpen
                                                        ? item.subItems.map((subItem: any) => (
                                                              <button
                                                                  type="button"
                                                                  onClick={() =>
                                                                      props.navToResource &&
                                                                      props.navToResource(
                                                                          subItem.route
                                                                      )
                                                                  }
                                                                  key={subItem.name}
                                                                  className={`
                                                                    ${
                                                                        item.active
                                                                            ? 'text-header-text'
                                                                            : 'text-body-text'
                                                                    } mt-4 group w-full flex items-end justify-end px-4 py-2 text-2xl leading-5 font-normal focus:outline-none transition ease-in-out duration-150
                                                              `}
                                                              >
                                                                  {subItem.name}
                                                              </button>
                                                          ))
                                                        : null}
                                                </div>
                                            );
                                        })}

                                    {!props.hideBottomNav &&
                                        props.mobileNavItems.map((item: any) => {
                                            if (item.name !== 'Resources') {
                                                return (
                                                    <button
                                                        type="button"
                                                        onClick={() =>
                                                            history.push(`${item.route}`)
                                                        }
                                                        key={item.name}
                                                        className={`
                                                                    ${
                                                                        item.active
                                                                            ? 'text-header-text'
                                                                            : 'text-body-text'
                                                                    } mt-4 group w-full flex items-end justify-end px-4 py-2 text-2xl leading-5 font-normal focus:outline-none transition ease-in-out duration-150
                                                              `}
                                                    >
                                                        {item.name}
                                                    </button>
                                                );
                                            }
                                            return (
                                                <div key={item.name}>
                                                    <button
                                                        type="button"
                                                        className={`
                                                        ${
                                                            item.active &&
                                                            props.activeItem === item.name
                                                                ? 'text-header-text'
                                                                : 'text-body-text'
                                                        } mt-4 group w-full flex items-end justify-end px-4 py-2 text-2xl leading-5 font-normal focus:outline-none transition ease-in-out duration-150
                                                    `}
                                                        onClick={() =>
                                                            props.resourceClick &&
                                                            props.resourceClick()
                                                        }
                                                    >
                                                        {item.name}
                                                    </button>

                                                    {props.resourceOpen
                                                        ? item.subItems.map((subItem: any) => (
                                                              <button
                                                                  type="button"
                                                                  onClick={() =>
                                                                      props.navToResource &&
                                                                      props.navToResource(
                                                                          subItem.route
                                                                      )
                                                                  }
                                                                  key={subItem.name}
                                                                  className={`
                                                                    ${
                                                                        item.active
                                                                            ? 'text-header-text'
                                                                            : 'text-body-text'
                                                                    } mt-4 group w-full flex items-end justify-end px-4 py-2 text-2xl leading-5 font-normal focus:outline-none transition ease-in-out duration-150
                                                              `}
                                                              >
                                                                  {subItem.name}
                                                              </button>
                                                          ))
                                                        : null}
                                                </div>
                                            );
                                        })}
                                </nav>

                                <div className="px-2 py-2 overflow-hidden">
                                    <button
                                        onClick={() => props.logout && props.logout()}
                                        type="button"
                                        className="mt-4 w-full group flex flex-row items-center justify-end px-2 py-2 text-2xl leading-5 font-normal text-header-text hover:text-body-text hover:bg-gray-700 focus:outline-none focus:text-body-text focus:bg-secondary transition ease-in-out duration-150"
                                    >
                                        Log out
                                        <img
                                            className="ml-4 h-6 w-6 text-vivid-blue-500 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150"
                                            src={props.navAssets[5].logo}
                                            alt="Log out"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>

            {/* Desktop menu */}
            <div className="hidden xl:flex">
                <div className="flex flex-col w-64 bg-background">
                    <div className="h-0 flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                        <div className="flex items-center justify-center flex-shrink-0 px-4">
                            <NavLink to="/">
                                <img
                                    className="h-6 w-auto"
                                    src={props.theme === 'dark' ? mainLogoWhite : mainLogo}
                                    alt="Workflow"
                                />
                            </NavLink>
                        </div>

                        <div className="flex flex-col items-center justify-center px-4 pt-8">
                            <div className="text-header-text">
                                {userProfileResponse.firstName}&nbsp;
                                {userProfileResponse.lastName}
                            </div>
                            <div className="text-body-text pt-2 font-semibold">
                                {props.rewardsLoading !== undefined && props.rewardsLoading
                                    ? '...'
                                    : props.rewardsDetails?.currentTierName}
                            </div>
                            <div className="flex flex-row pt-1">
                                {props.renderStars !== undefined && props.renderStars()}
                            </div>

                            {['Development'].includes(process.env.REACT_APP_ENV as string) && (
                                <div className="mt-4">
                                    <CurrencyIcon
                                        currencyCode={userProfileResponse?.baseCurrency?.code}
                                    />
                                    <div className="font-semibold">
                                        {userProfileResponse?.baseCurrency?.code}
                                    </div>
                                </div>
                            )}
                        </div>

                        <nav className="mt-5 flex-1 bg-background">
                            {props.navItems.map((item: any, index: number) => {
                                if (item.name !== 'Resources') {
                                    return (
                                        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
                                        <NavLink
                                            key={item.name}
                                            activeClassName="bg-primary"
                                            isActive={() => props.activeItem === item.name}
                                            to={{ pathname: item.route }}
                                            onMouseEnter={() => setActiveHoverItem(item.name)}
                                            onMouseLeave={() => setActiveHoverItem('')}
                                            className={`
                                        ${
                                            item.active ? 'text-header-text' : 'text-body-text'
                                        } ${setStepClass(
                                                index
                                            )} mt-4 group w-full flex items-center pr-2 pl-8 py-2 text-sm leading-5 font-semibold hover:text-header-text bg-gradient-to-r hover:from-cyan-blue-hover hover:to-vivid-blue-hover focus:outline-none transition ease-in-out duration-150
                                      `}
                                        >
                                            <img
                                                className="mr-3 h-6 w-6 transition ease-in-out duration-150"
                                                src={
                                                    item.active || activeHoverItem === item.name
                                                        ? item.activeLogo
                                                        : item.logo
                                                }
                                                alt={item.alt}
                                            />
                                            {item.name}
                                        </NavLink>
                                    );
                                }

                                return (
                                    <div key={item.name} className="relative w-full">
                                        <div className="absolute bg-background w-full z-40">
                                            <button
                                                type="button"
                                                key={item.name}
                                                onMouseEnter={() => setActiveHoverItem(item.name)}
                                                onMouseLeave={() => setActiveHoverItem('')}
                                                onClick={() =>
                                                    props.resourceClick && props.resourceClick()
                                                }
                                                className={`
                                                    ${
                                                        item.active
                                                            ? 'border-r-2 border-body-text text-header-text'
                                                            : 'text-body-text'
                                                    } relative mt-4 group w-full flex items-center pr-2 pl-8 py-2 text-sm leading-5 font-semibold hover:text-header-text bg-gradient-to-r hover:from-cyan-blue-hover hover:to-vivid-blue-hover focus:outline-none transition ease-in-out duration-150
                                            `}
                                            >
                                                <img
                                                    className="mr-3 h-6 w-6 transition ease-in-out duration-150"
                                                    src={
                                                        item.active || activeHoverItem === item.name
                                                            ? item.activeLogo
                                                            : item.logo
                                                    }
                                                    alt={item.alt}
                                                />
                                                {item.name}
                                            </button>

                                            {props.resourceOpen
                                                ? item.subItems.map((subItem: any) => (
                                                      <button
                                                          type="button"
                                                          onClick={() =>
                                                              props.navToResource &&
                                                              props.navToResource(subItem.route)
                                                          }
                                                          key={subItem.name}
                                                          className={`
                                                                        ${
                                                                            item.active
                                                                                ? 'text-header-text'
                                                                                : 'text-body-text'
                                                                        } z-40 mt-4 group w-full py-2 flex items-center justify-center text-center text-sm text-vivid-blue-50 hover:text-header-text bg-gradient-to-r hover:from-cyan-blue-hover hover:to-vivid-blue-hover leading-5 font-semibold focus:outline-none transition ease-in-out duration-150
                                                                `}
                                                      >
                                                          {subItem.logo} {subItem.name}
                                                      </button>
                                                  ))
                                                : null}
                                        </div>
                                    </div>
                                );
                            })}
                        </nav>
                    </div>

                    <div className="pb-4">
                        <button
                            onMouseEnter={() => setLogoutActive(true)}
                            onMouseLeave={() => setLogoutActive(false)}
                            onClick={() => props.logout && props.logout()}
                            type="button"
                            className="w-full flex items-center pr-2 pl-8 py-2 bg-gradient-to-r hover:from-cyan-blue-hover hover:to-vivid-blue-hover text-sm leading-5 font-semibold text-body-text hover:text-header-text focus:outline-none focus:text-body-text focus:bg-secondary transition ease-in-out duration-150"
                        >
                            <img
                                className="mr-3 h-6 w-6 group-focus:text-gray-300 transition ease-in-out duration-150"
                                src={!logoutActive ? props.navAssets[5].logo : logoutActiveLogo}
                                alt="Log out"
                            />
                            Log out
                        </button>

                        <div className="pt-4 text-vivid-blue-50 flex flex-col text-center items-center">
                            <div>
                                Need help? Contact us at{' '}
                                <a
                                    className="text-header-text no-underline"
                                    href={`mailto:${pageConfig?.App?.SupportEmail}`}
                                >
                                    {pageConfig?.App?.SupportEmail}
                                </a>
                            </div>
                        </div>

                        <div className="pt-2 flex flex-row text-center items-center justify-center">
                            <div className="flex flex-row px-2 py-2 text-center">
                                <a
                                    href={social?.Facebook}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={socialIconStyle}
                                >
                                    <img
                                        className="h-4 w-4"
                                        src={props.navAssets[2].logo}
                                        alt="Facebook"
                                    />
                                </a>

                                <a
                                    href={social?.Instagram}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={socialIconStyle}
                                >
                                    <img
                                        className="h-4 w-4"
                                        src={props.navAssets[3].logo}
                                        alt="Instagram"
                                    />
                                </a>

                                <a
                                    href={social?.LinkedIn}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={socialIconStyle}
                                >
                                    <img
                                        className="h-4 w-4"
                                        src={props.navAssets[4].logo}
                                        alt="Linkedin"
                                    />
                                </a>

                                <a
                                    href={social?.Twitter}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={socialIconStyle}
                                >
                                    <img
                                        className="h-4 w-4"
                                        src={props.navAssets[8].logo}
                                        alt="Twitter"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Body */}
            <div className="flex flex-col justify-center w-0 flex-1 overflow-hidden">
                <div
                    className={`${
                        props.menuOpen
                            ? 'xl:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 opacity-0'
                            : 'xl:hidden pl-1 pt-1 pb-2 sm:pl-3 sm:pt-3 opacity-100 transition ease duration-1000'
                    } fixed z-10 h-12 lg:h-0 bg-body-background inset-x-0 top-0 lg:mr-4 lg:pt-0`}
                >
                    <button
                        onClick={() => props.setMenuOpen(true)}
                        type="button"
                        className={
                            props.menuOpen
                                ? `hidden`
                                : `absolute z-40 right-0 -ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-vivid-blue-500 focus:outline-none transition ease-in-out duration-150`
                        }
                        aria-label="Open sidebar"
                    >
                        <img className="h-6 w-6" src={props.navAssets[6].logo} alt="Menu" />
                    </button>
                </div>

                <main
                    id="main"
                    className={`${
                        props.isTourOpen && isMobile ? 'overflow-y-hidden' : 'overflow-y-auto'
                    } flex-1 focus:outline-none pb-16 pt-10 lg:pb-0 lg:pt-0`}
                >
                    {props.children}
                </main>
            </div>

            {!props.hideBottomNav && (
                // Mobile Bottom Navigation
                <div className="block lg:hidden">
                    <section
                        id="bottom-navigation"
                        className="block fixed inset-x-0 bottom-0 z-10 bg-background rounded-tl-3xl rounded-tr-3xl shadow-bottom-nav"
                    >
                        <div
                            id="tabs"
                            className="flex justify-between rounded-tl-3xl rounded-tr-3xl bg-transparent"
                        >
                            {props.bottomNavItems.map((item, index) => (
                                <NavLink
                                    key={item.name}
                                    activeClassName=""
                                    isActive={() => props.activeItem === item.name}
                                    to={item.route}
                                    className={`${
                                        item.active
                                            ? 'text-header-text hover:text-header-text'
                                            : 'text-body-text hover:text-header-text'
                                    }  group w-full flex flex-col items-center text-center pt-0 pb-1 text-sm leading-5 font-normal focus:outline-none transition ease-in-out duration-150 bottom-nav-item`}
                                >
                                    {item.active && (
                                        <div
                                            className="h-0.5 w-8 rounded-lg"
                                            style={{ backgroundColor: '#1750F3' }}
                                        />
                                    )}

                                    <div className="relative mt-2">
                                        <div
                                            className="w-0.5 h-0.5 absolute -left-1 top-3"
                                            style={item.active ? activeStyle : {}}
                                        />
                                    </div>

                                    <img
                                        className={`h-6 w-6 ${index === 0 && 'mobile-step-3'} ${
                                            index === 1 && 'mobile-step-4'
                                        } ${
                                            index === 4 && 'mobile-step-8'
                                        } transition ease-in-out duration-150 inline-block`}
                                        src={item.active ? item.activeLogo : item.logo}
                                        alt={item.alt}
                                    />
                                    <span
                                        className={`${
                                            item?.active ? 'text-header-text' : 'text-body-text'
                                        } ${
                                            item?.active ? 'opacity-1000' : 'opacity-50'
                                        } mb-2 tab tab-home block text-xs`}
                                    >
                                        {item.name}
                                    </span>
                                </NavLink>
                            ))}
                        </div>
                    </section>
                </div>
            )}
        </div>
    );
}
