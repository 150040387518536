import { Process } from 'entities/otp';

interface TimeFrameDaysTypes {
    [period: string]: string;
}

export const timeFrameDays: TimeFrameDaysTypes = {
    day: '1',
    week: '7',
    month: '30',
    '3months': '90',
    '6months': '180',
    year: '365',
    all: 'all',
};

export const DaysToTimeFrameNames: { [key: string]: string } = {
    '1': '1 day',
    '7': '1 week',
    '30': '1 month',
    '90': '3 months',
    '180': '6 months',
    '365': '1 year',
    all: 'All',
};

export const timeFrameToDataKeys: TimeFrameDaysTypes = {
    day: 'Change_1',
    week: 'Change_7',
    month: 'Change_30',
    '3months': 'Change_90',
    '6months': 'Change_180',
    year: 'Change_365',
    all: 'Change_All',
};

export const otpProcesses: { [key: string]: Process } = {
    WITHDRAW_FIAT: 'WITHDRAW_FIAT',
    WITHDRAW_CRYPTO_BTC: 'WITHDRAW_CRYPTO_BTC',
    WITHDRAW_CRYPTO_USDT: 'WITHDRAW_CRYPTO_USDT',
    SIGNUP_ENABLE_SMS_OTP: 'SIGNUP_ENABLE_SMS_OTP',
    PROFILE_ENABLE_SMS_OTP: 'PROFILE_ENABLE_SMS_OTP',
    PROFILE_CHANGE_PASSWORD: 'PROFILE_CHANGE_PASSWORD',
    PROFILE_BTC_WALLET: 'PROFILE_BTC_WALLET',
    PROFILE_USDT_WALLET: 'PROFILE_USDT_WALLET',
    PROFILE_BANK_DETAILS: 'PROFILE_BANK_DETAILS',
};

export const OTP_LENGTH = 6;

export const otpInterval = 90;

export const maxFileUploadSize = 4500000;

export const maxMobileNumberLength = 12;

export const signupOtpSkipDelay = 5000;

export const acceptedFileUploadFormats = ['jpeg', 'jpg', 'png', 'pdf', 'docx'];

export const productPageTabs = ['crypto', 'savings', 'themes'];

export const productsExploreTabs = ['all', 'crypto', 'savings', 'themes'];
export const metricsFetchRateMinutes = 10;

export const defaultTimeFrameDays = '90';

export const productExploreTabs = {
    eng: [
        { value: 'all', label: 'All' },
        { value: 'crypto', label: 'Crypto' },
        { value: 'alternative', label: 'Themes' },
    ],
};

export const productExploreTabsV3 = {
    eng: [
        { value: 'all', label: 'All' },
        { value: 'crypto', label: 'Crypto' },
        { value: 'bundle', label: 'Crypto Bundles' },
        { value: 'commodities', label: 'Commodities' },
    ],
    de: [
        { value: 'all', label: 'Alle' },
        { value: 'crypto', label: 'Krypto' },
        { value: 'bundle', label: 'Krypto-Bundles' },
        { value: 'commodities', label: 'Waren' },
    ],
};

export const linkedAccountStorageKeys = {
    completedAccount: 'linkedAccountsCompletedAccount',
    hasAttemptedCompletion: 'linkedAccountHasAttemptedCompletion',
    initializePayment: 'linkedAccountInitializePayment',
    initializeAccount: 'linkedAccountInitialize',
    linkedAccounts: 'linkedAccounts',
};

export const searchableListCategories = [
    // BA: This data will need to be replaced by this links(https://revix-dev-cdn-public.azureedge.net/data/page_config.json) response,
    {
        key: 'popular',
        label: {
            en: 'Most popular',
        },
    },
    {
        key: 'stablecoin',
        label: {
            en: 'Stablecoin',
        },
    },
    {
        key: '*',
        label: {
            en: 'All',
        },
    },
];

export const dropdownCurrencyOptions = [
    { value: 'ZAR', label: 'ZAR', name: 'ZAR' },
    { value: 'USD', label: 'USD', name: 'USD' },
    { value: 'GBP', label: 'GBP', name: 'GBP' },
    { value: 'EUR', label: 'EUR', name: 'EUR' }
];

export const dropdownTimeframeOptions = [
    { value: 'All', label: '0', name: 'All' },
    { value: '1', label: '1', name: '24h' },
    { value: '7', label: '7', name: '7d' },
    { value: '30', label: '30', name: '1m' },
    { value: '90', label: '90', name: '3m' },
    // { value: '180', label: '180', name: '6m' },
    { value: '365', label: '365', name: '1y' },
];

export const holdingsDataOptions = [
    { value: 'holdings', label: 'Holdings', name: 'Holdings' },
    { value: 'gainLoss', label: 'Gain/Loss', name: 'Gain/Loss' },
    { value: 'breakdownPercentage', label: 'Weight', name: 'Weighting' },
];

export const productTypesForTabs: { [key in string]: string[] } = {
    crypto: ['Crypto'],
    bundle: ['Bundle'],
    commodities: ['Crypto'],
    all: ['Crypto', 'Bundle'],
};

export const SSOMessages = {
    USER_DOES_NOT_EXIST: 'USER_DOES_NOT_EXIST',
    USER_SIGNED_UP_WITH_PASSWORD: 'USER_SIGNED_UP_WITH_PASSWORD',
    USER_SIGNED_UP_WITH_GOOGLE: 'USER_SIGNED_UP_WITH_GOOGLE',
    USER_SIGNED_UP_WITH_APPLE: 'USER_SIGNED_UP_WITH_APPLE',
};

export const loginTypes = {
    PASSWORD: 'PASSWORD',
    APPLE: 'APPLE',
    GOOGLE: 'GOOGLE',
};

export const storageKeys = {
    showTwoFactorModal: 'showTwoFactorModal',
};

export const MINUTES_UNTIL_GAINS_SHOW = 15;
