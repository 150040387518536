import moment from 'moment';

// Method that checks if the user was created after the 9th of Feb 2021 i.e. site launch
const isCreatedAfterLaunch = (createdDate: string | undefined) => {
    const dateCreated = moment(createdDate).format('YYYY-MM-DD');
    const dateLaunched = moment('2021-02-10').format('YYYY-MM-DD');

    return moment(dateCreated, 'YYYY-MM-DD').isAfter(dateLaunched);
};

export default isCreatedAfterLaunch;
