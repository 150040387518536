type IdType = {
    id: string | number;
  } & {
    [key: string]: any;
  };

declare global {
    interface Window {
        _cio?: {
            page: (pageName: string, data?: object) => void,
            identify: (data: IdType) => void,
        };
    }
}

export function cioTrackPageView(pageName: string, data?: object) {
    window._cio?.page(pageName, data);
}

export function cioIdentify(data: IdType) {
    window._cio?.identify(data);
}