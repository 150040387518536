import cdnApi from './cdn-api';
import { Graph } from '../entities/graphs';
import { TimeFrameKey } from '../entities/time-frame';

// eslint-disable-next-line import/prefer-default-export
export async function getGraphs(
    code: string,
    currency: string,
    timeFrame: TimeFrameKey
): Promise<Graph> {
    const { data } = await cdnApi.get(
        `/${process.env.REACT_APP_CDN_GRAPH_STORAGE}/graphs/${code}/${currency}/rates_${timeFrame}.json`
    );
    return {
        baseAsset: data.BaseAsset,
        counterAsset: data.CounterAsset,
        interval: data.Interval,
        generatedAt: data.GeneratedAt,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        values: data.Values.map((d: any) => ({
            timeStamp: d.TimeStamp,
            date: d.Date,
            value: d.Value,
            normalisedValue: d.NormalisedValue,
            change: d.Change,
        })),
    };
}
