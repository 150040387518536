import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import appUrls from 'helpers/app-urls';
import clsx from 'clsx';
import apiCountryActions from 'services/api-country-actions';
import { RootState } from 'store/reducer';
import { Country } from 'entities/country';
import successTick from './successTick.svg';
import errorCross from './errorCross.svg';

const cdnUrl = appUrls.getCDNUrl();

const flagUrl = `${cdnUrl}assets/flags/rectangle/`;
const inputBackgroundColor = 'rgba(255, 255, 255, 0.1)';
const backgroundNonRGB = '#293155';

const PhoneNumberV2 = ({
    onNumberChange,
    selectedCountry,
    onCountryChange,
    phoneNumber,
    isNumberValid,
    hasNumberError,
}: {
    onNumberChange: (value: string) => void;
    selectedCountry: Country | undefined;
    onCountryChange: (value: Country) => void;
    phoneNumber: string;
    isNumberValid?: boolean;
    hasNumberError?: boolean;
}) => {
    // #region STATE
    const [selectedCountries, setSelectedCountries] = useState<Country[]>([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [allCountries, setAllCountries] = useState<Country[]>([]);
    const [countriesFetchError, setCountriesFetchError] = useState<boolean>(false);
    // endregion

    // #region REDUX
    const { activeCountry } = useSelector((state: RootState) => state.countries);
    // endregion

    // #region FUNCTIONS
    const handleCountryInput = (value: string) => {
        if (!value) {
            setSelectedCountries([]);
        } else {
            const filteredCountries = allCountries.filter((country) =>
                country.name.toLowerCase().includes(value?.toLocaleLowerCase())
            );
            if (filteredCountries.length > 0) setSelectedCountries(filteredCountries);
        }
    };

    const handleSelectCountry = (country: Country) => {
        onCountryChange(country);
        setIsDropdownOpen(false);
        setSelectedCountries([]);
    };
    // endregion

    // #region LIFECYCLE
    useEffect(() => {
        const fetchCountries = async () => {
            const countries = await apiCountryActions.getCountries();
            if (countries) {
                setAllCountries(countries);
                const currentlyActive = countries?.find(
                    (country: Country) => country.code === activeCountry.code
                );
                if (currentlyActive) handleSelectCountry(currentlyActive);
            } else {
                setCountriesFetchError(false);
            }
        };

        fetchCountries();
    }, []);
    // endregion

    return (
        <div className="flex items-center">
            <div className="flex flex-col">
                <div className="flex items-center">
                    <button
                        className={clsx(
                            'rounded-l-md px-3 mr-0.5 flex items-center focus:outline-none',
                            {
                                'border-l border-t border-b border-error': hasNumberError,
                            }
                        )}
                        style={{
                            height: '36px',
                            minWidth: '80px',
                            background: inputBackgroundColor,
                        }}
                        onClick={() => setIsDropdownOpen((prevState) => !prevState)}
                        type="button"
                    >
                        <img
                            src={`${flagUrl}${selectedCountry?.code?.toLowerCase()}.png`}
                            alt="country flag"
                            style={{ width: '30px', height: '20px' }}
                        />
                        <img
                            src={`${cdnUrl}assets/components/modal/chevron-down.svg`}
                            alt="arrow down"
                            className="ml-2"
                        />
                    </button>
                    <div
                        className={clsx(
                            'relative flex items-center text-xs lg:text-base leading-5 tracking-widest form-input placeholder:text-text-grey text-header-text w-full sm:leading-5 rounded-r-md pl-2',
                            { 'border-r border-t border-b border-error': hasNumberError }
                        )}
                        style={{ background: inputBackgroundColor, height: '36px' }}
                    >
                        <label
                            htmlFor="number-input"
                            className="mr-2"
                            style={{ color: 'white' }}
                        >{`(+${selectedCountry?.dialCode || ''})  `}</label>
                        <input
                            id="number-input"
                            type="tel"
                            placeholder="XX XXX XXXX"
                            value={phoneNumber}
                            onChange={(e) => onNumberChange(e.target.value)}
                            style={{ background: 'transparent' }}
                            className={clsx(
                                'focus:outline-none text-xs lg:text-base leading-5 tracking-widest form-input placeholder:text-text-grey text-white w-full rounded-md pl-3 pr-8 border-none'
                            )}
                        />
                        {(isNumberValid || hasNumberError) && (
                            <img
                                src={isNumberValid ? successTick : errorCross}
                                alt="tick"
                                className="absolute right-2 h-5"
                            />
                        )}
                    </div>
                </div>
                {isDropdownOpen && (
                    <div
                        className="flex flex-col p-2 rounded-md fixed top-100% mt-12 z-10"
                        style={{ background: backgroundNonRGB }}
                    >
                        <input
                            type="text"
                            className="focus:outline-none border-grey text-xs lg:text-base leading-5 tracking-widest form-input placeholder:text-grey text-white placeholder-text-grey w-full sm:leading-5 rounded-md pl-3"
                            placeholder="Search country"
                            onChange={(e) => handleCountryInput(e.target.value)}
                            style={{
                                width: '382px',
                                background: 'transparent',
                                outline: 'none',
                            }}
                        />
                        {selectedCountries.length > 0 && (
                            <div
                                className="overflow-y-scroll overflow-x-hidden rounded-md mt-2"
                                style={{
                                    maxHeight: '184px',
                                    background: backgroundNonRGB,
                                }}
                            >
                                {selectedCountries.map((item) => {
                                    return (
                                        <button
                                            className="my-2 w-full py-2 rounded-md px-3 flex items-center focus:outline-none"
                                            type="button"
                                            onClick={() => handleSelectCountry(item)}
                                            style={{
                                                width: '382px',
                                            }}
                                        >
                                            <img
                                                src={`${flagUrl}${item?.code?.toLowerCase()}.png`}
                                                alt="flag"
                                                style={{
                                                    width: '30px',
                                                    height: '20px',
                                                    background: 'transparent',
                                                }}
                                            />
                                            <div
                                                className="ml-4 text-text-grey"
                                                style={{
                                                    background: 'transparent',
                                                }}
                                            >
                                                {item.name}
                                            </div>
                                        </button>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default PhoneNumberV2;
