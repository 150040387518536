import { Language } from '../../store/slices/language-slice';

interface NotFoundMessages {
    seo: {
        title: string;
        description: string;
        keywords: string;
    };
    notFound: {
        pageNotFound: string;
        navToPortfolio: string;
    };
}

type Message = {
    [key in Language]: NotFoundMessages;
};

const messages: Message = {
    eng: {
        seo: {
            title: 'Altify - Invest',
            description:
                'Effortlessly invest in crypto with one of our Bundles. Investing has never been this easy.',
            keywords:
                'investing, investment platform, cryptocrurency, top 10 cryptocurrency, top cryptocurrency, top performing crypto, diversification, diversified investing',
        },
        notFound: {
            pageNotFound: 'Page not found',
            navToPortfolio: 'Please navigate back to your portfolio',
        },
    },
};

export default messages;
