export type AsyncReduxItem<T> = {
    loading: boolean;
    error: any;
    data: T | null;
};

export type AsyncReduxList<T> = {
    loading: boolean;
    error: any;
    data: T[];
};

export const InitAsyncData = <T>(
    data: T
): {
    loading: boolean;
    error: any;
    data: T;
} => ({
    loading: true,
    error: null,
    data,
});
