/* eslint-disable @typescript-eslint/ban-ts-ignore */
import { Process, Channel } from 'entities/otp';

const defaultTimestamps = {
    SMS: {
        WITHDRAW_FIAT: '',
        WITHDRAW_CRYPTO_BTC: '',
        WITHDRAW_CRYPTO_USDT: '',
        PROFILE_BANK_DETAILS: '',
        PROFILE_BTC_WALLET: '',
        PROFILE_USDT_WALLET: '',
        PROFILE_CHANGE_PASSWORD: '',
        SIGNUP_ENABLE_SMS_OTP: '',
        PROFILE_ENABLE_SMS_OTP: '',
    },
    EMAIL: {
        WITHDRAW_FIAT: '',
        WITHDRAW_CRYPTO_BTC: '',
        WITHDRAW_CRYPTO_USDT: '',
        PROFILE_BANK_DETAILS: '',
        PROFILE_BTC_WALLET: '',
        PROFILE_USDT_WALLET: '',
        PROFILE_CHANGE_PASSWORD: '',
    },
};

const localStorageLocation = 'revix/otpTimestamps';

export const setOtpTimestamp = (channel: Channel, process: Process, clear = false) => {
    const localStorageTimestamps = localStorage.getItem(localStorageLocation);
    const timestamps = JSON.parse(localStorageTimestamps || JSON.stringify(defaultTimestamps));
    const otpTimestamp = {
        ...timestamps,
        [channel]: {
            ...timestamps[channel],
            [process]: clear ? null : new Date(),
        },
    };
    localStorage.setItem(localStorageLocation, JSON.stringify(otpTimestamp));
};

export const getOtpTimestamp = (channel: Channel, process: Process) => {
    const localStorageTimestamps = localStorage.getItem(localStorageLocation);
    if (localStorageTimestamps !== null) {
        const parsedTimestamps = JSON.parse(localStorageTimestamps);
        if (parsedTimestamps[channel][process]) return new Date(parsedTimestamps[channel][process]);
    }
    return null;
};

export const removeOtpTimestamps = () => {
    localStorage.removeItem(localStorageLocation);
};
