/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CurrenciesList, ProductType } from 'entities/products';

export interface ProductState {
    activeProductBundle: string;
    activeTimeFrame: string;
    // TODO: rename all this to be `currenciesList` instead of products
    cdnProducts: {
        data: CurrenciesList | null;
        error: string | any;
        loading: boolean;
    }
}

const initialProductState: ProductState = {
    activeProductBundle: '',
    activeTimeFrame: '',
    cdnProducts: {
        data: null,
        error: null,
        loading: false,
    }
};

const product = createSlice({
    name: 'product',
    initialState: initialProductState,
    reducers: {
        setActiveProduct(state: ProductState, action: PayloadAction<string>) {
            state.activeProductBundle = action.payload;
        },
        setActiveTimeFrame(state: ProductState, action: PayloadAction<string>) {
            state.activeTimeFrame = action.payload;
        },
        setCdnCurrenciesData(state: ProductState, action: PayloadAction<CurrenciesList>) {
            if (state.cdnProducts) {
                state.cdnProducts.data = action.payload;
            }
        },
        setCdnCurrenciesError(state: ProductState, action: PayloadAction<string | any>) {
            if (state.cdnProducts) {
                state.cdnProducts.error = action.payload;
            }
        },
        setCdnCurrenciesLoading(state: ProductState, action: PayloadAction<boolean>) {
            if (state.cdnProducts) {
                state.cdnProducts.loading = action.payload;
            }
        },
    },
});

export const {
    setActiveProduct,
    setActiveTimeFrame,
    setCdnCurrenciesData,
    setCdnCurrenciesError,
    setCdnCurrenciesLoading,
} = product.actions;

export default product.reducer;
