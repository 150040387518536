import { Action, configureStore } from '@reduxjs/toolkit';
import fp from 'lodash/fp';
import { ThunkAction } from 'redux-thunk';
import useLogout from 'hooks/use-logout';
import rootReducer, { RootState } from './reducer';
import { getCachedStore, setCachedStore, removeCachedStore } from '../helpers/cache';
import { addUnauthorizedInterceptor, setToken } from '../services/api';
// import ReactotronConfig from '../other/reactotron-config';
import { AppState } from './app/app-slice';
import { CurrencyState } from './currencies/currencies-slice';
import { ProductState } from './products/products-slice';
import { MetricState } from './metrics/metrics-slice';
import { setTheme } from './slices/theme-slice';
import { DEFAULT_THEME } from '../styles/themes';

const preloadedState = getCachedStore();

/**
 * Customize store values that should not be returned from cached store.
 */
if (preloadedState?.auth?.client?.loading) {
    preloadedState.auth.client.loading = false;
}

const env = process.env.NODE_ENV;

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
// const reactotronEnhancer = ReactotronConfig.createEnhancer!();

const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    devTools: true,
    enhancers: env === 'development' ? [] : [],
});

if (preloadedState?.theme?.theme) {
    store.dispatch(setTheme(preloadedState.theme.theme));
} else {
    store.dispatch(setTheme(DEFAULT_THEME));
}

const token = fp.get(['auth', 'client', 'data', 'token'], preloadedState);

if (token) {
    setToken(token);
}

store.subscribe(() => {
    /** Find better way to only save some values */
    const {
        app,
        auth,
        currencies,
        theme,
        products,
        metrics,
        funds,
        trade,
        verification,
        vault,
        rewards,
        availableProducts,
    } = store.getState();

    setCachedStore({
        app,
        auth,
        theme,
        currencies,
        products,
        metrics,
        funds,
        trade,
        verification,
        vault,
        rewards,
        availableProducts,
    });
});

/**
 * If an api request is ever 401, the store will dispatch logout.
 */
addUnauthorizedInterceptor(() => {
    const logout = useLogout();
    removeCachedStore();
    logout();
});

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export interface GlobalApplicationState {
    app: AppState;
    currencies: CurrencyState;
    products: ProductState;
    metrics: MetricState;
}

export default store;
