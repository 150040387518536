/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import Tour, { ReactourStepContentArgs } from 'reactour';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import colors from '../../styles/colors';
import messages from './messages';
import { RootState } from '../../store/reducer';
import './styles.css';
import { setIsTourOpen, setAppLoading } from '../../store/app/app-slice';
import { isAppDoneLoading, setSlide } from '../../store/app/app-actions';

import verification from '../../data/verification';
import isCreatedAfterLaunch from '../../helpers/user-created-after-launch';

interface TourState {
    isTourOpen: boolean;
    isShowingMore: boolean;
    totalSteps: number;
    goToSlide: number;
}

const ProductTour = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const tourContainer = useRef();

    const { language } = useSelector((rootState: RootState) => rootState.language);
    const { tourSlide } = useSelector((rootState: RootState) => rootState.app);
    const appLoading = useSelector((rootState: RootState) => rootState.app.loading);
    const { userProfileResponse, loading } = useSelector(
        (rootState: RootState) => rootState.profile
    );

    const tourMessages = messages[language].tour;

    const accentColor = colors.white;

    //const disableBody = (target: HTMLElement) => disableBodyScroll(target);

    //const enableBody = (target: HTMLElement) => enableBodyScroll(target);

    const [state, setState] = useState<TourState>({
        isTourOpen: false,
        isShowingMore: false,
        totalSteps: 0,
        goToSlide: 0,
    });

    const onTourClose = () => {
        localStorage.setItem('showTour', 'false');
        setState({ ...state, isTourOpen: false });
        dispatch(setIsTourOpen(false));
        dispatch(setSlide(11));
    };

    const goToNextSlide = (stepObj: ReactourStepContentArgs) => {
        const currentStep = stepObj.step - 1;
        if (state.totalSteps !== currentStep) {
            stepObj.goTo(currentStep + 1);
        }
    };

    const goToVerify = (stepObj: ReactourStepContentArgs) => {
        history.push('/profile/verification');
        dispatch(setIsTourOpen(false));
        dispatch(setSlide(10));
        stepObj.goTo(10);
        stepObj.close();
        localStorage.setItem('showTour', 'false');
        setState({ ...state, isTourOpen: false });
    };

    const referAFriend = (stepObj: ReactourStepContentArgs) => {
        dispatch(setIsTourOpen(false));
        dispatch(setSlide(10));
        stepObj.goTo(10);
        stepObj.close();
        localStorage.setItem('showTour', 'false');
        setState({ ...state, isTourOpen: false });
    };

    const getPulsePosition = (index: number | undefined) => {
        switch (index) {
            case 1:
                return 'flex absolute right-0 bottom-0 -mb-12 mr-20';
            case 2:
            case 4:
            case 5:
            case 6:
            case 7:
            case 9:
                return 'flex absolute top-0 left-0 -ml-14';
            case 3:
            case 8:
                return 'flex absolute top-0 right-0 -mr-14';
            case 10:
                return 'flex absolute right-0 bottom-0 -mb-12 mr-20';
            case 11:
                return 'flex absolute left-0 bottom-0 -mb-12';
            case 12:
                return 'flex absolute left-0 bottom-0 -mb-12 ml-24';
            case 13:
                return 'flex absolute left-0 bottom-0 -mb-16 ml-24';
            case 14:
                return 'flex absolute left-0 bottom-0 -mb-16 ml-24';
            case 15:
                return 'flex absolute right-0 bottom-0 -mb-16 mr-2';
            default:
                return 'flex absolute left-0 top-0 -mt-12 -ml-4';
        }
    };

    const stepContent = (message: string, stepObj: ReactourStepContentArgs, step?: number) => {
        return (
            <div className="relative">
                <div className="inline-flex">
                    <div className={`${getPulsePosition(step)}`}>
                        <span className="ping absolute inline-flex rounded-full bg-secondary opacity-50 w-full h-full" />
                        <span className="relative inline-flex rounded-full h-4 w-4 border-2 border-white bg-secondary" />
                    </div>
                </div>
                <div
                    className="font-normal text-base mt-4"
                    dangerouslySetInnerHTML={{
                        __html: message,
                    }}
                />
                <div className="mt-5 flex flex-row justify-between w-full">
                    {(userProfileResponse?.verificationStatus?.includes(verification?.Unverified) &&
                        state.totalSteps === stepObj.step) ||
                    (isMobile &&
                        state.totalSteps === stepObj.step &&
                        userProfileResponse &&
                        userProfileResponse?.verificationStatus?.includes(
                            verification?.Unverified
                        )) ? (
                        <>
                            <button
                                type="button"
                                className="focus: outline-none border-0 p-2 bg-white text-secondary font-semibold rounded-lg"
                                onClick={() => goToVerify(stepObj)}
                            >
                                {tourMessages.verifyBtn}
                            </button>

                            <button
                                type="button"
                                className="focus: outline-none underline text-xs font-normal"
                                onClick={() => onTourClose()}
                            >
                                {tourMessages.endTourBtn}
                            </button>
                        </>
                    ) : (!userProfileResponse?.verificationStatus?.includes(
                          verification?.Unverified
                      ) &&
                          state.totalSteps === stepObj.step) ||
                      (isMobile && state.totalSteps === stepObj.step) ? (
                        <>
                            <button
                                type="button"
                                className="focus: outline-none border-0 p-2 bg-white text-secondary font-semibold rounded-lg"
                                onClick={() => referAFriend(stepObj)}
                            >
                                {tourMessages.referBtn}
                            </button>

                            <button
                                type="button"
                                className="focus: outline-none underline text-xs font-normal"
                                onClick={() => onTourClose()}
                            >
                                {tourMessages.endTourBtn}
                            </button>
                        </>
                    ) : (
                        <>
                            <button
                                type="button"
                                className="focus: outline-none border-0 p-2 bg-white text-secondary font-semibold rounded-lg"
                                onClick={() => goToNextSlide(stepObj)}
                            >
                                {tourMessages.gotItBtn}
                            </button>

                            <button
                                type="button"
                                className="focus: outline-none underline text-xs font-normal"
                                onClick={() => onTourClose()}
                            >
                                {tourMessages.skipTourBtn}
                            </button>
                        </>
                    )}
                </div>
            </div>
        );
    };

    const getCurrentStepByLocation = (location?: string) => {
        if (!tourContainer || !tourContainer.current || !state.isTourOpen) return;

        switch (location) {
            case 'products':
                // @ts-ignore
                tourContainer.current.gotoStep(
                    !isMobile && !isCreatedAfterLaunch(userProfileResponse?.createdAt)
                        ? 2
                        : !isMobile && isCreatedAfterLaunch(userProfileResponse?.createdAt)
                        ? 3
                        : 2
                );
                break;
            case 'funds':
                // @ts-ignore
                tourContainer.current.gotoStep(
                    !isMobile && !isCreatedAfterLaunch(userProfileResponse?.createdAt)
                        ? 3
                        : !isMobile && isCreatedAfterLaunch(userProfileResponse?.createdAt)
                        ? 4
                        : 3
                );
                break;
            case 'rewards':
                // @ts-ignore
                tourContainer.current.gotoStep(tourContainer.current.gotoStep(tourSlide));
                break;
            case 'verify':
            case '404':
                // @ts-ignore
                tourContainer.current.gotoStep(undefined);
                dispatch(setIsTourOpen(false));
                localStorage.setItem('showTour', 'false');
                break;
            default:
                // @ts-ignore
                tourContainer.current.gotoStep(tourSlide);
                break;
        }
    };

    const setStep = (step: number) => {
        if (step === 0 && state.isTourOpen) {
            history.push('/');
        }

        switch (step) {
            case 1:
                history.push('/');
                break;
            case 2:
                if (!isCreatedAfterLaunch(userProfileResponse?.createdAt)) {
                    history.push('/products/explore');
                } else {
                    history.push('/');
                }
                break;
            case 3:
                if (!isCreatedAfterLaunch(userProfileResponse?.createdAt)) {
                    history.push('/funds');
                } else {
                    history.push('/products/explore');
                }
                break;
            case 4:
                if (!isCreatedAfterLaunch(userProfileResponse?.createdAt)) {
                    dispatch(setAppLoading(true));
                    history.push('/rewards');
                } else {
                    history.push('/funds');
                }
                break;
            case 5:
            case 6:
                dispatch(setAppLoading(true));
                history.push('/rewards');
                break;
            case 7:
                if (userProfileResponse?.verificationStatus?.includes(verification?.Unverified)) {
                    history.push('/profile');
                } else {
                    dispatch(setAppLoading(true));
                    history.push('/rewards');
                }
                break;
            case 8:
                if (userProfileResponse?.verificationStatus?.includes(verification?.Unverified)) {
                    history.push('/profile');
                } else {
                    history.push('/rewards');
                    dispatch(setAppLoading(true));
                }
                break;
            default:
                break;
        }

        dispatch(setSlide(step));
    };

    const setStepMobile = (step: number) => {
        if (!tourContainer || !tourContainer.current) return;

        if (step === 0 && state.isTourOpen) {
            history.push('/');
        }

        switch (step) {
            case 1:
                history.push('/');
                break;
            case 2:
                history.push('/products/explore');
                break;
            case 3:
                history.push('/funds');
                break;
            case 4:
                dispatch(setAppLoading(true));
                history.push('/rewards');
                break;
            case 5:
                document.getElementsByClassName('mobile-step-6')[0].scrollIntoView();
                document.getElementsByClassName('mobile-step-6-new')[0].scrollIntoView();
                break;
            case 6:
                document.getElementsByClassName('mobile-step-7')[0].scrollIntoView();
                document.getElementsByClassName('mobile-step-7-new')[0].scrollIntoView();
                break;
            case 7:
                if (userProfileResponse?.verificationStatus?.includes(verification?.Unverified)) {
                    history.push('/profile');
                } else {
                    history.push('/rewards');
                }
                break;
            default:
                break;
        }

        dispatch(setSlide(step));
    };

    const getCurrentStep = (step: number) => {
        if (!tourContainer || !tourContainer.current) return;

        if (step !== tourSlide && state.isTourOpen && !isMobile) {
            setStep(step);
        } else if (step !== tourSlide && state.isTourOpen && isMobile) {
            setStepMobile(step);
        }
    };

    const stepsNewUser = [
        {
            selector: '.step-1',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.newStep1, stepObj, 3),
            position: isMobile ? [0, 0] : [10, 10],
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
        {
            selector: '.step-2',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.newStep2, stepObj, 2),
            position: isMobile ? [0, 0] : null,
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
        {
            selector: '.step-4',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.newStep4, stepObj, 4),
            position: isMobile ? [0, 0] : null,
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
        {
            selector: '.step-5',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.newStep5, stepObj, 5),
            position: isMobile ? [0, 0] : null,
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
        {
            selector: '.step-6',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.newStep6, stepObj, 6),
            position: isMobile ? [0, 0] : null,
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
        {
            selector: '.step-7',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.newStep7, stepObj, 8),
            position: isMobile ? [0, 0] : null,
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
        {
            selector: '.step-8',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.newStep8, stepObj, 7),
            position: isMobile ? [0, 0] : null,
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
        {
            selector: '.step-9',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.newStep9, stepObj, 9),
            position: isMobile ? [0, 0] : null,
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
    ];

    const stepsNewUserMobile = [
        {
            selector: '.mobile-step-1',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.newStep1, stepObj, 1),
            position: 'bottom',
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
        {
            selector: '.mobile-step-2',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.newStep2, stepObj, 10),
            position: 'top',
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
        {
            selector: '.mobile-step-3',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.newStep4, stepObj, 11),
            position: 'top',
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
        {
            selector: '.mobile-step-4',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.newStep5, stepObj, 12),
            position: 'bottom',
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
        {
            selector: '.mobile-step-5',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.newStep6, stepObj, 0),
            position: 'bottom',
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
        {
            selector: '.mobile-step-6',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.newStep7, stepObj, 0),
            position: 'bottom',
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
        {
            selector: '.mobile-step-7',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.newStep8, stepObj, 13),
            position: 'top',
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
        {
            selector: '.mobile-step-8',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.newStep9, stepObj, 15),
            position: 'top',
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
    ];

    // NOTE: Commented out because of the changes on the performance graph being hidden
    // const stepsExistingUser = [
    //     {
    //         selector: '.step-1',
    //         content: (stepObj: ReactourStepContentArgs) =>
    //             stepContent(tourMessages.existingStep1, stepObj, 3),
    //         position: [10, 10],
    //         style: {
    //             backgroundColor: colors['secondary-blue'],
    //             color: colors.white,
    //         },
    //     },
    //     {
    //         selector: '.step-2',
    //         content: (stepObj: ReactourStepContentArgs) =>
    //             stepContent(tourMessages.existingStep2, stepObj, 2),
    //         style: {
    //             backgroundColor: colors['secondary-blue'],
    //             color: colors.white,
    //         },
    //     },
    //     {
    //         selector: '.step-3',
    //         content: (stepObj: ReactourStepContentArgs) =>
    //             stepContent(tourMessages.existingStep3, stepObj, 3),
    //         style: {
    //             backgroundColor: colors['secondary-blue'],
    //             color: colors.white,
    //         },
    //     },
    //     {
    //         selector: '.step-4',
    //         content: (stepObj: ReactourStepContentArgs) =>
    //             stepContent(tourMessages.existingStep4, stepObj, 4),
    //         style: {
    //             backgroundColor: colors['secondary-blue'],
    //             color: colors.white,
    //         },
    //     },
    //     {
    //         selector: '.step-5',
    //         content: (stepObj: ReactourStepContentArgs) =>
    //             stepContent(tourMessages.existingStep5, stepObj, 5),
    //         style: {
    //             backgroundColor: colors['secondary-blue'],
    //             color: colors.white,
    //         },
    //     },
    //     {
    //         selector: '.step-6-new',
    //         content: (stepObj: ReactourStepContentArgs) =>
    //             stepContent(tourMessages.existingStep6, stepObj, 6),
    //         style: {
    //             backgroundColor: colors['secondary-blue'],
    //             color: colors.white,
    //         },
    //     },
    //     {
    //         selector: '.step-7-new',
    //         content: (stepObj: ReactourStepContentArgs) =>
    //             stepContent(tourMessages.existingStep7, stepObj, 8),
    //         style: {
    //             backgroundColor: colors['secondary-blue'],
    //             color: colors.white,
    //         },
    //     },
    //     {
    //         selector: '.step-8-new',
    //         content: (stepObj: ReactourStepContentArgs) =>
    //             stepContent(tourMessages.existingStep8, stepObj, 7),
    //         style: {
    //             backgroundColor: colors['secondary-blue'],
    //             color: colors.white,
    //         },
    //     },
    //     {
    //         selector: '.step-9-new',
    //         content: (stepObj: ReactourStepContentArgs) =>
    //             stepContent(tourMessages.existingStep9, stepObj, 8),
    //         style: {
    //             backgroundColor: colors['secondary-blue'],
    //             color: colors.white,
    //         },
    //     },
    // ];

    const stepExistingUserNoGraph = [
        {
            selector: '.step-1',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.existingStep1, stepObj, 3),
            position: [10, 10],
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
        {
            selector: '.step-2',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.existingStep2, stepObj, 2),
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
        {
            selector: '.step-4',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.existingStep4, stepObj, 4),
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
        {
            selector: '.step-5',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.existingStep5, stepObj, 4),
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
        {
            selector: '.step-6-new',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.existingStep6, stepObj, 5),
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
        {
            selector: '.step-7-new',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.existingStep7, stepObj, 3),
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
        {
            selector: '.step-8-new',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.existingStep8, stepObj, 5),
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
        {
            selector: '.step-9-new',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.existingStep9, stepObj, 3),
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
    ];

    const stepsExistingUserMobile = [
        {
            selector: '.mobile-step-1',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.existingStep1, stepObj, 1),
            position: 'bottom',
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
        {
            selector: '.mobile-step-2',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.existingStep2, stepObj, 10),
            position: 'top',
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
        {
            selector: '.mobile-step-3',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.existingStep4, stepObj, 11),
            position: 'top',
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
        {
            selector: '.mobile-step-4',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.existingStep5, stepObj, 12),
            position: 'top',
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
        {
            selector: '.mobile-step-5-new',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.existingStep6, stepObj, 0),
            position: 'bottom',
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
        {
            selector: '.mobile-step-6-new',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.existingStep7, stepObj, 0),
            position: 'bottom',
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
        {
            selector: '.mobile-step-7-new',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.existingStep8, stepObj, 13),
            position: 'top',
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
        {
            selector: '.mobile-step-8-new',
            content: (stepObj: ReactourStepContentArgs) =>
                stepContent(tourMessages.existingStep9, stepObj, 14),
            position: 'top',
            style: {
                backgroundColor: colors['secondary-blue'],
                color: colors.white,
            },
        },
    ];

    const getStorageValue = (localStorageItem: string | null) => {
        switch (localStorageItem) {
            case 'true':
                return true;
            case 'false':
                return false;
            default:
                return true;
        }
    };

    useEffect(() => {
        const showTour = localStorage.getItem('showTour');
        setState({
            ...state,
            isTourOpen: getStorageValue(showTour),
            totalSteps: stepsNewUser.length,
        });

        dispatch(setIsTourOpen(getStorageValue(showTour)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepsNewUser.length]);

    const locationString = window.location.pathname.split('/');
    getCurrentStepByLocation(locationString[1]);

    // Implemented this so it checks if the app state should be loading
    // It only does a dispatch to check for loading states on the store
    useEffect(() => {
        let interval: any;

        if (state.isTourOpen) {
            interval = setInterval(() => {
                dispatch(isAppDoneLoading());
            }, 1000);
        }

        if (!state.isTourOpen) {
            clearInterval(interval);
            clearAllBodyScrollLocks();
        }

        return () => clearInterval(interval);
    }, [state.isTourOpen]);

    return (
        // @ts-ignore
        <Tour
            ref={tourContainer}
            //onAfterOpen={enableBody}
            //onBeforeClose={enableBody}
            //onRequestClose={() => onTourClose()}
            /*steps={
                !loading &&
                !appLoading &&
                ((isCreatedAfterLaunch(userProfileResponse?.createdAt) &&
                    userProfileResponse?.verificationStatus?.includes(verification?.Unverified)) ||
                    userProfileResponse?.verificationStatus?.includes(verification?.Unverified))
                    ? isMobile
                        ? stepsNewUserMobile
                        : stepsNewUser
                    : isMobile
                    ? stepsExistingUserMobile
                    : stepExistingUserNoGraph
            }*/
            steps={[]}
            getCurrentStep={(curr) => getCurrentStep(curr)}
            //isOpen={state.isTourOpen && !appLoading}
            isOpen={false}
            className="helper"
            rounded={8}
            accentColor={accentColor}
            showCloseButton={false}
            showNumber={false}
            showNavigationNumber={false}
            closeWithMask={false}
            showButtons={false}
            disableKeyboardNavigation
            disableInteraction
            maskClassName={isMobile ? 'mobile-tour-mask' : null}
            highlightedMaskClassName={isMobile ? 'mobile-tour-mask' : null}
        />
    );
};

export default ProductTour;
