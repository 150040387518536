const compare = (a: string, b: string): number => {
    const [aMajor, aMinor, aPatch] = a.split('.').map(Number);
    const [bMajor, bMinor, bPatch] = b.split('.').map(Number);

    if (aMajor > bMajor) {
        return 1;
    }
    if (aMajor < bMajor) {
        return -1;
    }
    if (aMinor > bMinor) {
        return 1;
    }
    if (aMinor < bMinor) {
        return -1;
    }
    if (aPatch > bPatch) {
        return 1;
    }
    if (aPatch < bPatch) {
        return -1;
    }
    return 0;
};

export default {
    compare,
};
