import React from 'react';

export type ColorOrGradient = string | { start: string; end: string };

export function GradientOrColorView({
    colorOrGradient,
    style,
    className,
    children,
}: {
    colorOrGradient: ColorOrGradient;
    style?: any;
    className: string;
    children: React.ReactNode;
}) {
    return typeof colorOrGradient == 'string' ? (
        <div
            style={{ backgroundColor: colorOrGradient, ...(style as object) }}
            className={className}
        >
            {children}
        </div>
    ) : (
        <div
            className={className}
            style={{
                background: `linear-gradient(${colorOrGradient.start}, ${colorOrGradient.end})`,
                ...(style as object),
            }}
        >
            {children}
        </div>
    );
}
