import _ from 'lodash';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPageConfigAction } from 'store/app/app-actions';
import { RootState } from 'store/reducer';

interface PageConfig {
    App: {
        CompanyName?: string;
        SupportEmail?: string;
        SiteBaseUrl?: string;
        SiteBlogUrl?: string;
        PrivacyUrl?: string;
        FeesUrl?: string;
        RiskUrl?: string;
        DisclaimerUrl?: string;
        TermsUrl?: string;
        SupportedCountriesUrl?: string;
        HelpCenterUrl?: string;
        Social?: {
            Facebook?: string;
            Instagram?: string;
            LinkedIn?: string;
            Twitter?: string;
        };
    };
    Animations: {
        Portfolio: string;
        ProductDetails: string;
        Explore: string;
        Rewards: string;
    };
    Portfolio: {
        StakingUrl: string;
        SavingsUrl: string;
        ResourcesUrl: string;
    };
    Products: {
        Defaults: {
            showReservesPill: boolean;
            showReservesLink: boolean;
            reservesLink: string;
            stableCoins: ['BUSD', 'TUSD', 'USDC', 'USDP', 'USDT'];
        };
        USDT: {
            showReservesPill: boolean;
        };
        USDC: {
            showReservesLink: boolean;
        };
        USDP: {
            showReservesLink: boolean;
        };
        TUSD: {
            showReservesLink: boolean;
        };
        BUSD: {
            showReservesLink: boolean;
        };
    };
    Deposits: {
        categories: {
            key: string;
            label: {
                eng: string;
            };
        }[];
    };
    Withdrawals: {
        LockedAssetsModal: {
            title: string;
            description: {
                mobile: string[];
                /** NB: This is an HTML string with an href as a link. */
                dashboard: string;
            };
            /** seems this reference variable here is unused */
            blogLink?: string;
            checkboxText: string;
            submitText: string;
        };
    };
}

const usePageConfig = (): PageConfig => {
    const dispatch = useDispatch();

    const config = useSelector((state: RootState) => state.app.pageConfig);

    const [pageConfig, setPageConfig] = useState<any>({});

    useEffect(() => {
        if (_.isEmpty(config)) {
            dispatch(fetchPageConfigAction());
        }
    }, [config, dispatch]);

    useEffect(() => {
        if (!_.isEmpty(pageConfig)) {
            setPageConfig(config);
        }
    }, [config, pageConfig]);

    return config;
};

export default usePageConfig;
