export const mergeArraysByCommonKeys = (
    primaryArray: any[],
    secondaryArray: any[],
    key: string,
    secondaryKey?: string
) => {
    const consolidatedArray = [...primaryArray];

    secondaryArray.forEach((secondaryItem) => {
        const primaryIndex = consolidatedArray.findIndex(
            (item) => item?.[key] === secondaryItem?.[secondaryKey || key]
        );
        consolidatedArray[primaryIndex] = { ...consolidatedArray[primaryIndex], ...secondaryItem };

        if (secondaryKey && secondaryKey !== key) {
            // eslint-disable-next-line no-param-reassign
            delete consolidatedArray[primaryIndex]?.[secondaryKey];
        }
    });
    return consolidatedArray;
};

export const mergeItemIntoArray = (
    primaryArray: any[],
    item: any,
    key: string,
    secondaryKey?: string
) => {
    const consolidatedArray = primaryArray;
    const primaryIndex = consolidatedArray.findIndex(
        (arrItem) => arrItem[key] === item[secondaryKey || key]
    );

    consolidatedArray[primaryIndex] = {
        ...consolidatedArray[primaryIndex],
        ...item,
    };

    return consolidatedArray;
};
