/* eslint-disable @typescript-eslint/ban-ts-ignore */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReferencesAssetsAction } from 'store/productsV2/productsV2-actions';
import TopNav from '../components/TopNav';
import { RootState } from '../store/reducer';
import { setTheme } from '../store/slices/theme-slice';

const DEFAULT_THEME = 'light';

export default function PublicLayout({ children }: { children: React.ReactNode }) {
    const dispatch = useDispatch();
    const { theme } = useSelector((state: RootState) => state.theme);
    const { assets } = useSelector((state: RootState) => state.productsV2);

    React.useEffect(() => {
        if (theme !== DEFAULT_THEME) {
            dispatch(setTheme(DEFAULT_THEME));
        }
        dispatch(fetchReferencesAssetsAction(assets?.length === 0));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        (() => {
            const d = document;
            const s = d.createElement('script');
            s.id = 'ze-snippet';
            s.src =
                'https://static.zdassets.com/ekr/snippet.js?key=c3406489-488b-403b-8f38-e68a97af7c82';
            s.async = true;
            d.getElementsByTagName('head')[0].appendChild(s);
        })();
    }, []);

    // Quick fix to switch between the different navs
    const client = useSelector((state: RootState) => state.auth.client);
    const userStatus = useSelector((rootState: RootState) => rootState.profile.userStatus);

    if (!client || !client.data || !userStatus?.registered) {
        return <TopNav>{children}</TopNav>;
    }

    return <>{children}</>;
}
