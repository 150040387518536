/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AsyncReduxItem, InitAsyncData } from 'store/types';
import {
    UserProfileStatus,
    UpdateEmailResponse,
    UpdateEmailVerificationResponse,
    UpdatePasswordResponse,
    UserProfileResponse,
    LoginMethod,
} from '../../entities/profile';

export interface ProfileState {
    loading?: boolean;
    userProfileResponse?: UserProfileResponse;
    updateEmailResponse?: UpdateEmailResponse;
    updateEmailVerificationResponse?: UpdateEmailVerificationResponse;
    updatePasswordResponse?: UpdatePasswordResponse;
    pendingLoading: boolean;
    customerValidToken: boolean;
    pendingError: boolean;
    pendingErrorMessage: string;
    userStatus?: UserProfileStatus;
    ssoLinking: AsyncReduxItem<any>;
    loginMethods: AsyncReduxItem<LoginMethod[]>;
}

const initialState: ProfileState = {
    loading: false,
    pendingLoading: false,
    customerValidToken: false,
    pendingError: false,
    pendingErrorMessage: '',
    ssoLinking: InitAsyncData<any>({}),
    loginMethods: InitAsyncData<LoginMethod[]>([]),
};

const register = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setSsoLinkingLoading(state: ProfileState, action: PayloadAction<boolean>) {
            state.ssoLinking.loading = action.payload;
        },
        setSsoLinkingData(state: ProfileState, action: PayloadAction<any>) {
            state.ssoLinking.data = action.payload;
        },
        setSsoLinkingError(state: ProfileState, action: PayloadAction<any>) {
            state.ssoLinking.error = action.payload;
        },
        setLoginMethodsLoading(state: ProfileState, action: PayloadAction<boolean>) {
            state.loginMethods.loading = action.payload;
        },
        setLoginMethodsError(state: ProfileState, action: PayloadAction<any>) {
            state.loginMethods.error = action.payload;
        },
        setLoginMethodsData(state: ProfileState, action: PayloadAction<LoginMethod[]>) {
            state.loginMethods.data = action.payload;
        },
        getUserProfileStart(state) {
            state.loading = true;
        },
        getUserProfileEnd(state) {
            state.loading = false;
        },
        getUserProfileError(state, action: PayloadAction<UserProfileResponse>) {
            state.loading = false;
            state.userProfileResponse = action.payload;
            state.userProfileResponse.lastRefreshedAt = new Date().getTime();
        },
        setUserProfileResponse(state, action: PayloadAction<UserProfileResponse>) {
            state.loading = false;
            state.userProfileResponse = action.payload;
            state.userProfileResponse.lastRefreshedAt = new Date().getTime();
        },
        enableEmailNotificationStart(state) {
            state.loading = true;
        },
        enableEmailNotificationEnd(state) {
            state.loading = false;
        },
        enableEmailNotificationError(state, action: PayloadAction<UserProfileResponse>) {
            state.loading = false;
            state.userProfileResponse = action.payload;
            state.userProfileResponse.lastRefreshedAt = new Date().getTime();
        },
        setEnableEmailNotificationResponse(state, action: PayloadAction<UserProfileResponse>) {
            state.loading = false;
            state.userProfileResponse = action.payload;
            state.userProfileResponse.lastRefreshedAt = new Date().getTime();
        },

        disableEmailNotificationStart(state) {
            state.loading = true;
        },
        disableEmailNotificationEnd(state) {
            state.loading = false;
        },
        disableEmailNotificationError(state, action: PayloadAction<UserProfileResponse>) {
            state.loading = false;
            state.userProfileResponse = action.payload;
            state.userProfileResponse.lastRefreshedAt = new Date().getTime();
        },
        setDisableEmailNotificationResponse(state, action: PayloadAction<UserProfileResponse>) {
            state.loading = false;
            state.userProfileResponse = action.payload;
            state.userProfileResponse.lastRefreshedAt = new Date().getTime();
        },

        updateEmailStart(state) {
            state.loading = true;
        },
        updateEmailEnd(state) {
            state.loading = false;
        },
        updateEmailError(state, action: PayloadAction<UpdateEmailResponse>) {
            state.loading = false;
            state.updateEmailResponse = action.payload;
        },
        setUpdateEmailResponse(state, action: PayloadAction<UpdateEmailResponse>) {
            state.loading = false;
            state.updateEmailResponse = action.payload;
        },

        updateEmailVerificationStart(state) {
            state.loading = true;
        },
        updateEmailVerificationEnd(state) {
            state.loading = false;
        },
        updateEmailVerificationError(
            state,
            action: PayloadAction<UpdateEmailVerificationResponse>
        ) {
            state.loading = false;
            state.updateEmailVerificationResponse = action.payload;
        },
        setUpdateEmailVerificationResponse(
            state,
            action: PayloadAction<UpdateEmailVerificationResponse>
        ) {
            state.loading = false;
            state.updateEmailVerificationResponse = action.payload;
        },
        updatePasswordStart(state) {
            state.loading = true;
        },
        updatePasswordEnd(state) {
            state.loading = false;
        },
        updatePasswordError(state, action: PayloadAction<UpdatePasswordResponse>) {
            state.loading = false;
            state.updatePasswordResponse = action.payload;
        },
        setUpdatePasswordResponse(state, action: PayloadAction<UpdatePasswordResponse>) {
            state.loading = false;
            state.updatePasswordResponse = action.payload;
        },
        setPendingLoading(state, action: PayloadAction<boolean>) {
            state.pendingLoading = action.payload;
        },
        setPendingError(state, action: PayloadAction<boolean>) {
            state.pendingError = action.payload;
        },
        setPendingErrorMessage(state, action: PayloadAction<string>) {
            state.pendingErrorMessage = action.payload;
        },
        setCustomerValidToken(state, action: PayloadAction<boolean>) {
            state.customerValidToken = action.payload;
        },
        setUserStatus(state, action: PayloadAction<UserProfileStatus>) {
            state.userStatus = action.payload;
        },
    },
});

export const {
    getUserProfileStart,
    getUserProfileEnd,
    getUserProfileError,
    setUserProfileResponse,

    enableEmailNotificationStart,
    enableEmailNotificationEnd,
    setEnableEmailNotificationResponse,

    disableEmailNotificationStart,
    disableEmailNotificationEnd,
    setDisableEmailNotificationResponse,

    enableEmailNotificationError,
    disableEmailNotificationError,

    updateEmailStart,
    updateEmailEnd,
    updateEmailError,
    setUpdateEmailResponse,

    setUpdateEmailVerificationResponse,
    updateEmailVerificationEnd,
    updateEmailVerificationError,
    updateEmailVerificationStart,

    updatePasswordStart,
    updatePasswordEnd,
    updatePasswordError,
    setUpdatePasswordResponse,

    setPendingLoading,
    setPendingError,
    setPendingErrorMessage,
    setCustomerValidToken,
    setUserStatus,

    setSsoLinkingLoading,
    setSsoLinkingData,
    setSsoLinkingError,

    setLoginMethodsLoading,
    setLoginMethodsError,
    setLoginMethodsData,
} = register.actions;

export default register.reducer;
