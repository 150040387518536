/* eslint-disable import/prefer-default-export */

/**
 * Joins strings into a relative url:  a, b, c => /a/b/c
 * @param urls
 */
export function joinUrl(...urls: string[]): string {
    /**
     * Remove all slashes from the beginning or ends of urls.
     * @param url
     */
    function parseUrl(url: string): string {
        if (url.startsWith('/')) {
            return parseUrl(url.slice(1));
        }
        if (url.endsWith('/')) {
            return parseUrl(url.slice(0, -1));
        }
        return url;
    }

    const parsedUrl = urls.map((url) => parseUrl(url)).join('/');

    // Changes a/b/c to /a/b/c
    return parsedUrl.startsWith('/') ? parsedUrl : `/${parsedUrl}`;
}
