/* eslint-disable @typescript-eslint/ban-ts-ignore */
/* eslint-disable import/prefer-default-export */
import {
    Asset,
    ProductV2,
    ProductV2Aggregated,
    ProductV2AggregatedHolding,
} from 'entities/productsV2';
import _ from 'lodash';

export const flattenAggregatedProducts = (
    aggregatedResponse: ProductV2Aggregated
): {
    list: ProductV2[];
    portfolioValue: number;
    cashBalance: number;
    gainsAmount: number;
    gainsPercentage: number;
    canWithdraw: boolean;
    canDeposit: boolean;
    cashReserved: number;
} => {
    const list: ProductV2[] = aggregatedResponse?.productHoldings?.map(
        (product: ProductV2AggregatedHolding) => {
            const mappedItem: ProductV2 = {
                code: product?.holdings?.code as string,
                productName: product?.holdings?.productName as string,
                productType: product?.holdings?.productType as string,
                productTypeName: product?.holdings?.productType as string,
                ...product.holdings,
                ...product.gains,
                fiatValue: product?.fiatValue,
                vaultGains: product.vaultGains,
                breakdownPercentage:
                    ((product?.fiatValue as number) / aggregatedResponse.portfolioBalance) * 100,
                reservedAmounts: product?.reservedAmounts,
            };
            return mappedItem;
        }
    );

    const portfolioValue = aggregatedResponse.portfolioBalance as number;
    const { cashBalance, cashReserved, canDeposit, canWithdraw } = aggregatedResponse;
    const gainsAmount = _.sum(
        list
            ?.filter?.((p: ProductV2) => p.gainAmount !== null && p.gainAmount !== undefined)
            .map((p: ProductV2) => p.gainAmount)
    );
    const gainsPercentage = (100 * gainsAmount) / ((portfolioValue - cashBalance) as number);
    return {
        list,
        portfolioValue,
        cashBalance,
        cashReserved,
        canDeposit,
        canWithdraw,
        gainsAmount,
        gainsPercentage,
    };
};

export const mapAssetToProductV2 = (asset: Asset): ProductV2 => {
    const product: ProductV2 = {
        id: asset?.code,
        code: asset.code,
        productType: 'Theme',
        typeCode: asset?.typeCode,
        productName: asset.name as string,
        productTypeName: 'Thematic assets',
        endpointId: asset.endpointId,
        symbol: asset?.code,
        longName: asset?.longName,
        // description: asset?.description,
        colors: asset?.colors,
        color: asset.colors?.secondary || asset?.colors?.primary,
        whitepaper: asset?.whitepaper,
        factsheet: asset?.factsheet,
        hasFacts: asset.hasFacts,
        displayOrder: asset?.displayOrder,
        content: asset?.productCopy,
        rebalancingFrequency: asset?.rebalancingFrequency,
        navigation: false,
        productCopy: asset?.productCopy,
    };

    return product;
};

export const displayProductCode = (assetCode: string) => {
    switch (assetCode?.toUpperCase()) {
        case 'RVX10':
            return 'T10E';
        case 'RVX20':
            return 'T20E';
        case 'RVX5PLAT':
            return 'ECO';
        case 'RVX5PAY':
            return 'PAY';
        case 'RVXDEFI':
            return 'DEFI';
        case 'RVXINFL':
            return 'INFL';
        case 'RVXBC10':
            return 'BLUE';
        default:
            return assetCode;
    }
};
