/* eslint-disable import/no-cycle */
import { combineReducers } from 'redux';
import appReducer from './app/app-slice';
import authReducer from './auth/auth-slice';
import countriesReducer from './countries/countries-slice';
import currencyReducer from './currencies/currencies-slice';
import graphsReducer from './graphs/graphs-slice';
import metricReducer from './metrics/metrics-slice';
import portfolioReducer from './portfolio/portfolio-slice';
import tradeReducer from './trade/trade-slice';
import productsReducer from './product-suggest/products-suggest-slice';
import productReducer from './products/products-slice';
import profileReducer from './profile/profile-slice';
import registerReducer from './register/slice';
import rewardsDetails from './rewardsDetails/rewards-details-slice';
import resetReducer from './reset/slice';
import languageReducer from './slices/language-slice';
import verificationReducer from './verification/verification-slice';
import vaultReducer from './vault/vault-slice';
import themeReducer from './slices/theme-slice';
import fundsReducer from './funds/funds-slice';
import bankDetailsReducer from './bankDetails/bank-details-slice';
import btcReducer from './btcDetails/btc-details-slice';
import productWalletReducer from './productDetails/product-wallet-slice';
import twoFactorReducer from './two-factor/two-factor-slice';
import transactionsReducer from './transactions/transactions-slice';
import otpReducer from './otp/otp-slice';
import accountDetailsReducer from './accountDetails/account-details-slice';
import productsV2Reducer from './productsV2/productsV2-slice';

const combinedReducers = combineReducers({
    app: appReducer,
    auth: authReducer,
    cdnCurrenciesList: productReducer,
    countries: countriesReducer,
    graphs: graphsReducer,
    verification: verificationReducer,
    language: languageReducer,
    portfolio: portfolioReducer,
    funds: fundsReducer,
    trade: tradeReducer,
    vault: vaultReducer,
    products: productsReducer,
    register: registerReducer,
    reset: resetReducer,
    bankDetails: bankDetailsReducer,
    rewards: rewardsDetails,
    productWalletDetails: productWalletReducer,
    btcDetails: btcReducer,
    theme: themeReducer,
    currencies: currencyReducer,
    availableProducts: productReducer,
    metrics: metricReducer,
    profile: profileReducer,
    twoFactor: twoFactorReducer,
    transactions: transactionsReducer,
    otp: otpReducer,
    accountDetails: accountDetailsReducer,
    productsV2: productsV2Reducer,
});

export type RootState = ReturnType<typeof combinedReducers>;

/**
 * This completely resets the redux store (typically used when logging out.)
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: any) => {
    if (action.type === 'RESET') {
        /**
         * Do not change theme on logout.  It must go through "setTheme" action.  (Otherwise body style and store do not match)
         */
        // eslint-disable-next-line no-param-reassign
        state = {
            theme: state.theme,
        };
    }
    return combinedReducers(state, action);
};

export default rootReducer;
