export default function parseError(
    error: any,
    defaultMessage = 'An unknown error has occured'
): string {
    if (!error) return defaultMessage;
    if (typeof error === 'string') return error;
    if (error.message && typeof error.message === 'string') return error.message;
    if (error.response) {
        return error.response.toString();
    }
    return error.toString();
}
