import { Client } from 'entities/auth';
import { SSOAuthProviderKey } from 'entities/sso';
import { TwoFactorLoginResponse } from 'entities/login';
import { removeCachedStore } from 'helpers/cache';
import parseError from 'helpers/parse-error';
import { setToken } from 'services/api';
import * as apiAuthentication from 'services/api-authentication';
import { AppThunk } from '..';
import {
    setClientData,
    setClientDataFromTwoFactorClientData,
    setClientError,
    setClientLoading,
    setSSOLoading,
    setTwoFactorClientData,
    setTwoFactorEmail,
    setTwoFactorLoginError,
    setTwoFactorLoginLoading,
} from './auth-slice';
import { getTermsListAction } from 'store/register/actions';

/**
 * Gets the new token, and saves the new client (just like "login").
 * * Note that "setClientLoading" is not executed (this process should occur in the background)
 */
export const refreshToken = (callback?: (success: boolean) => void): AppThunk => async (
    dispatch
) => {
    try {
        const client = await apiAuthentication.refreshToken();
        if (client.success) {
            if (!client.twoFactorRequired) {
                setToken(client.token);
                dispatch(setClientData(client));
            } else {
                dispatch(setTwoFactorClientData(client));
            }
            if (callback) {
                callback(true);
            }
        }
    } catch (error) {
        if (callback) {
            callback(false);
        }
        dispatch(setClientError(parseError(error)));
    }
};

export const login = (
    username: string,
    password: string,
    recaptcha: string,
    callback?: (client: Client) => void
): AppThunk => async (dispatch) => {
    try {
        dispatch(setClientLoading(true));
        const client = await apiAuthentication.login(username, password, recaptcha);
        if (client.success) {
            if (!client.twoFactorRequired) {
                setToken(client.token);
                dispatch(setClientData(client));
                dispatch(getTermsListAction());
            } else {
                dispatch(setTwoFactorEmail(username));
                dispatch(setTwoFactorClientData(client));
            }
        } else {
            // Potentially handles the 500 response ... need to test
            // dispatch(setClientError(client.message));
            dispatch(setClientLoading(false));
        }
        if (callback) {
            callback(client);
        }
    } catch (error) {
        // Probably handles the 500 response ... need to test
        dispatch(setClientLoading(false));
        dispatch(setClientError(parseError(error)));
    }
};

export const logout = (): AppThunk => async (dispatch) => {
    try {
        dispatch(setClientLoading(true));
        removeCachedStore();
        dispatch({ type: 'RESET' });
    } catch (error) {
        dispatch(setClientError(parseError(error)));
    }
};

export const twoFactorLogin = (
    pin: string,
    token: string,
    callback?: (response: TwoFactorLoginResponse) => void
): AppThunk => async (dispatch) => {
    try {
        dispatch(setTwoFactorLoginLoading());
        const response = await apiAuthentication.twoFactorLogin(pin, token);
        if (response.success) {
            setToken(response.token);
            dispatch(setClientDataFromTwoFactorClientData(response.token));
            dispatch(getTermsListAction());
        }
        if (callback) {
            callback(response);
        }
    } catch (error) {
        dispatch(setTwoFactorLoginError(parseError(error)));
    }
};

export const loginWithProviderAction = (
    payload: {
        provider: SSOAuthProviderKey;
        ssoJwt: string;
        clientApplicationSsoCode: string;
    },
    callback?: Function
): AppThunk => async (dispatch) => {
    try {
        dispatch(setSSOLoading('GOOGLE'));
        const response = await apiAuthentication.loginWithProviderService(payload);

        if (response.success) {
            setToken(response.token);
            dispatch(setClientData(response));
            dispatch(getTermsListAction());
        } else {
            dispatch(setClientError(response.message));
        }

        if (callback) {
            callback(response);
        }
    } catch (error) {
        dispatch(setClientError(parseError(error)));
    } finally {
        dispatch(setSSOLoading(null));
    }
};
