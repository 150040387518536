import { transparentize } from 'polished';
import { Theme } from '.';
import colors from '../colors';

const theme: Theme = {
    'blue-grey': colors['blue-grey'],
    bodyBackground: colors['off-black'],
    background: colors['card-background'],
    background50: colors['bg-dark-50'],
    inputBackground: colors['dark-blue'],
    inputText: colors.white,
    inputBorder: colors['blue-grey'],
    bodyText: colors['text-grey'],
    headerText: colors.white,
    primary: colors['vivid-blue'],
    primaryButton: colors['primary-blue'],
    primaryBlueGradient: colors['primary-blue-gradient'],
    primaryHover: colors['vivid-blue-hover'],
    secondary: colors.secondary,
    secondaryBlue: colors['secondary-blue'],
    secondaryHover: colors['secondary-blue-hover'],
    secondaryDark: colors['blue-grey'],
    tertiary: colors.white,
    tertiaryHover: colors.white,
    success: colors['price-green'],
    error: colors['price-red'],
    buttonText: colors.white,
    outlinedButtonText: colors.white,
    customLoader: colors.white,
    backgroundBlue: colors.tierBlueGradient,
    backgroundSilver: colors.tierSilverGradient,
    backgroundGold: colors.tierGoldGradient,
    backgroundPlatinum: colors.tierPlatinumGradient,
    backgroundVip: colors.tierVIPGradient,
    rewardsDarkBlue: colors['primary-blue'],
    rewardsLightBlue: colors['cyan-light'],
    rewardsGradient: colors.rvxGradient,
    skeletonLoaderFrom: colors['blue-grey'],
    skeletonLoaderTo: transparentize(0.75, colors['blue-grey']),
    txHistoryBackground: colors['blue-bayoux'],
    backgroundBtc: colors.btcGradient,
    secondaryButton: colors['secondary-button'],
    priceRed: colors['price-red'],
    priceGreen: colors['price-green'],
};

export default theme;
