const getEndpointUrl = () => {
    return process.env.REACT_APP_API_URL !== undefined
        ? process.env.REACT_APP_API_URL
        : 'https://dashboard.revix.com/';
};

const getCDNUrl = () => {
    return process.env.REACT_APP_CDN_URL !== undefined
        ? process.env.REACT_APP_CDN_URL
        : 'https://revix-prod-cdn-public.azureedge.net/';
};

const getStagingCDNUrl = () => {
    return process.env.REACT_APP_CDN_URL !== undefined
        ? process.env.REACT_APP_CDN_URL
        : 'https://revix-prod-cdn-public.azureedge.net/';
};

const getSiteUrl = () => {
    return process.env.REACT_APP_BASE_URL !== undefined
        ? process.env.REACT_APP_BASE_URL
        : 'https://app.edge.altify.com/';
};

const getMarketingUrl = () => {
    return process.env.REACT_APP_MARKETING !== undefined
        ? process.env.REACT_APP_MARKETING
        : 'https://altify.com/';
};

const crispUrl = () => {
    return 'https://go.crisp.chat/chat/embed/?website_id=b4abd7ee-3daf-4cc5-8036-2189f605b0ab';
};

export default {
    getEndpointUrl,
    getCDNUrl,
    getStagingCDNUrl,
    getSiteUrl,
    getMarketingUrl,
    crispUrl,
};
