import React, { Suspense } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import routes, { Route as LocalRoute } from './routes';
import store from './store';
import { joinUrl } from './helpers/utils';
import PublicLayout from './layout/PublicLayout';
import PrivateLayout from './layout/PrivateLayout';
import { AuthWrapper } from './helpers/auth';
import CustomLoader from './components/CustomLoader';
import AnalyticsProvider from './AnalyticsProvider';
import AppInfo from './components/AppInfo';

function renderRoute(route: LocalRoute, parentPath = '') {
    const component = (
        <route.Component>
            {route.routes && route.routes.map((r) => renderRoute(r, route.path))}
        </route.Component>
    );

    const Layout = (() => {
        if (parentPath) return ({ children }: { children: React.ReactNode }) => <>{children}</>;
        return route.public ? PublicLayout : PrivateLayout;
    })();

    const Wrapper =
        !route.public && !parentPath
            ? AuthWrapper
            : ({ children }: { children: React.ReactNode }) => <>{children}</>;

    return (
        <Route key={route.path} path={joinUrl(parentPath, route.path)} exact={route.path === '/'}>
            <Wrapper>
                <Layout>
                    <Suspense fallback={<CustomLoader page />}>
                        <AnalyticsProvider>{component}</AnalyticsProvider>
                    </Suspense>
                </Layout>
            </Wrapper>
        </Route>
    );
}

function App() {
    return (
        <div className="h-full w-full">
            <Provider store={store}>
                <Router>
                    <AppInfo />
                    <Switch>
                        {routes.map((route) => renderRoute(route))}
                        <Redirect exact from="/" to="/dashboard" />
                        <Redirect from="/products" to="/products/explore" />
                        <Redirect from="*" to="/404" />
                    </Switch>
                </Router>
            </Provider>
        </div>
    );
}
export default App;
