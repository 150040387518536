/* eslint-disable import/prefer-default-export */
import light from './light';
import dark from './dark';

export type Theme = {
    bodyBackground: string;
    background: string;
    background50: string;
    inputBackground: string;
    inputText: string;
    inputBorder: string;
    bodyText: string;
    headerText: string;
    primary: string;
    primaryButton: string;
    primaryBlueGradient: string;
    primaryHover: string;
    secondary: string;
    secondaryHover: string;
    secondaryDark: string;
    tertiary: string;
    tertiaryHover: string;
    success: string;
    error: string;
    buttonText: string;
    outlinedButtonText: string;
    customLoader: string;
    backgroundBlue: string;
    backgroundSilver: string;
    backgroundGold: string;
    backgroundPlatinum: string;
    backgroundVip: string;
    rewardsDarkBlue: string;
    rewardsLightBlue: string;
    rewardsGradient: string;
    skeletonLoaderFrom: string;
    skeletonLoaderTo: string;
    txHistoryBackground: string;
    backgroundBtc: string;
    secondaryButton: string;
    secondaryBlue: string;
    priceRed: string;
    priceGreen: string;
    'blue-grey': string;
};

export const themes = {
    light,
    dark,
};

export const DEFAULT_THEME = process.env.REACT_APP_DEFAULT_THEME as ThemeName;

export type ThemeName = keyof typeof themes;
