import moment from 'moment';

export const isValidEmail = (email: string | undefined) => {
    if (!email) return false;
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
};

export const isValidName = (name: string | undefined) => {
    // proper name regex validation:
    // https://stackoverflow.com/questions/275160/regex-for-names
    if (!name) return false;
    return name?.trim()?.length > 0;
};

export const isValidDayOfBirth = (day: number | undefined): boolean => {
    if (day === null || day === undefined) return true;
    if (day <= 0) return false;
    if (day > 31) return false;

    return true;
};

export const months = [
    'JANUARY',
    'FEBRUARY',
    'MARCH',
    'APRIL',
    'MAY',
    'JUNE',
    'JULY',
    'AUGUST',
    'SEPTEMBER',
    'OCTOBER',
    'NOVEMBER',
    'DECEMBER',
];

export const isValidMonthOfBirth = (month: string | undefined): boolean => {
    // TODO: cater for translations
    // maybe consider a more generic utility to check if
    // - the value (arg1) is in the colletion (arg2)
    // - with an optional dot.notation.query (arg3?)

    if (!month) return false;
    return months.includes(month.toUpperCase());
};

// TODO: this was all copied from Register/UpdateDetails
// e.g. 2 inconsistent checks: year >= 1000 in once case, and year >= 0 in another
export const isValidYearOfBirth = (year: number | undefined): boolean => {
    const yearsSinceBirth = year ? new Date().getFullYear() - year : 0;
    return year ? year >= 0 && yearsSinceBirth >= 18 : false;
};

export const isValidDateOfBirth = (
    year: number | undefined,
    month: string | undefined,
    day: number | undefined
): boolean => {
    // NOTE: null checks to allow UI input to continue
    if (year === null || year === undefined) return true;
    if (month === null || month === undefined) return true;
    if (day === null || day === undefined) return true;

    if (day <= 0) return false;

    const minAge = 18;
    const maxAge = 120;
    const dateOfBirth = moment(year + month + day, 'YYYYMMMMDD');
    if (!dateOfBirth.isValid()) return false;

    const age = moment().diff(dateOfBirth, 'years');
    if (minAge > age) return false;
    if (age > maxAge) return false;
    return true;
};

export const isValidPhoneNumber = (number: string, countryCode = '') => {
    let minLength = 3;
    if (['GB', 'ZA'].includes(countryCode?.toUpperCase())) minLength = 9;
    return number.length >= minLength;
};

export const includesNumber = (string: string): boolean => {
    if (string === '') return false;
    const regEx = new RegExp('[0-9]');
    return regEx.test(string);
};

export function includesUppercaseLetter(string: string) {
    if (string === '') return false;
    const regEx = new RegExp('[A-Z]');
    return regEx.test(string);
}

export function includesLowercaseLetter(string: string) {
    if (string === '') return false;
    const regEx = new RegExp('[a-z]');
    return regEx.test(string);
}

export const includes6Characters = (string: string): boolean => {
    if (string?.length < 5) return false;
    return true;
};

// eslint-disable-next-line consistent-return
export const passwordsMatch = (string1: string, string2: string): boolean => {
    if (string1 === string2) return true;
    return false;
};

export default {
    isValidName,
    isValidEmail,
    isValidDateOfBirth,
    isValidDayOfBirth,
    isValidMonthOfBirth,
    isValidYearOfBirth,
};
