import { themes, ThemeName, DEFAULT_THEME, Theme } from './index';
import hyphenCase from '../../utils/hyphen-case';

type MappedTheme = {
    [key: string]: string | null;
};

export function mapTheme(variables: Theme): MappedTheme {
    return Object.keys(variables).reduce<{ [key: string]: string }>((theme, key) => {
        // eslint-disable-next-line no-param-reassign
        theme[`--${hyphenCase(key)}`] = variables[key as keyof Theme];
        return theme;
    }, {});
}

export const applyTheme = (themeName: ThemeName): void => {
    const theme = mapTheme(themes[themeName]);
    if (!theme) return;

    const root = document.documentElement;

    Object.keys(theme).forEach((property) => {
        root.style.setProperty(property, theme[property] as string);
    });
};

export function applyDefaultTheme() {
    applyTheme(DEFAULT_THEME);
}
