/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    InitiateResetResponse,
    ResetPasswordResponse,
    ResetTwoFactorResponse,
} from '../../entities/reset';

export interface PasswordResetState {
    loading: boolean;
    resetInitiatedResponse: InitiateResetResponse;
    resetPasswordResponse: ResetPasswordResponse;
    resetTwoFactorResponse: ResetTwoFactorResponse;
    error: boolean;
    errorMessage: string;
}

const initialState: PasswordResetState = {
    loading: false,
    resetInitiatedResponse: {
        success: false,
        message: '',
    },
    resetPasswordResponse: {
        message: '',
        statusCode: 0,
        token: '',
        tokenExpiry: '',
    },
    resetTwoFactorResponse: {
        statusCode: 0,
    },
    error: false,
    errorMessage: '',
};

const reset = createSlice({
    name: 'reset',
    initialState,
    reducers: {
        initiateResetStart(state) {
            state.loading = true;
        },
        initiateResetEnd(state) {
            state.loading = false;
        },
        initiateResetError(state) {
            state.loading = false;
        },
        setInitiateResetResponse(state, action: PayloadAction<InitiateResetResponse>) {
            state.resetInitiatedResponse = action.payload;
        },

        resetPasswordStart(state) {
            state.loading = true;
        },
        resetPasswordEnd(state) {
            state.loading = false;
        },
        resetPasswordError(state, action: PayloadAction<ResetPasswordResponse>) {
            state.loading = false;
            state.resetPasswordResponse = action.payload;
        },
        setResetPasswordResponse(state, action: PayloadAction<ResetPasswordResponse>) {
            state.resetPasswordResponse = action.payload;
        },

        resetTwoFactorStart(state) {
            state.loading = true;
        },
        resetTwoFactorEnd(state) {
            state.loading = false;
        },
        setResetTwoFactorError(state, action?: PayloadAction<string>) {
            state.loading = false;
            state.error = true;
            state.errorMessage = action?.payload || '';
        },
        setResetTwoFactorResponse(state, action: PayloadAction<ResetTwoFactorResponse>) {
            state.resetTwoFactorResponse = action.payload;
            state.error = false;
        },
    },
});

export const {
    initiateResetStart,
    initiateResetEnd,
    initiateResetError,
    setInitiateResetResponse,

    resetPasswordEnd,
    resetPasswordError,
    resetPasswordStart,
    setResetPasswordResponse,

    resetTwoFactorStart,
    resetTwoFactorEnd,
    setResetTwoFactorError,
    setResetTwoFactorResponse,
} = reset.actions;

export default reset.reducer;
