/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransactionResponse, TransactionRebalance } from '../../entities/transactions';

export interface TransactionsState {
    pending: {
        loading: boolean;
        error: any;
        data: TransactionResponse;
    };
    completed: {
        loading: boolean;
        error: any;
        data: TransactionResponse;
    };
    all: {
        loading: boolean;
        error: any;
        data: TransactionResponse;
    };
    rewards: {
        loading: boolean;
        error: any;
        data: TransactionResponse;
    };
    vault: {
        loading: boolean;
        error: any;
        data: TransactionResponse;
    };
    rebalance: {
        loading: boolean;
        error: null;
        data: any;
    };
    downloadable: {
        loading: boolean;
        error: null;
        data: TransactionResponse | any;
    }
}

const initialPaginatedResource = {
    loading: false,
    error: null,
    data: {
        items: [],
    },
};

const initialState: TransactionsState = {
    pending: initialPaginatedResource,
    completed: initialPaginatedResource,
    all: initialPaginatedResource,
    rewards: initialPaginatedResource,
    vault: initialPaginatedResource,
    downloadable: initialPaginatedResource,
    rebalance: {
        loading: false,
        error: null,
        data: {},
    },
};

const transactions = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        // Pending & Completed
        setPendingTransactionsLoading(state, action: PayloadAction<boolean>) {
            state.pending.loading = action.payload;
        },
        setPendingTransactionsData(state, action: PayloadAction<TransactionResponse>) {
            state.pending.data = action.payload;
        },
        setPendingTransactionsError(state, action: PayloadAction<any>) {
            state.pending.error = action.payload;
        },
        // Deposit & Withdraw
        setCompletedTransactionsLoading(state, action: PayloadAction<boolean>) {
            state.completed.loading = action.payload;
        },
        setCompletedTransactionsData(state, action: PayloadAction<TransactionResponse>) {
            state.completed.data = action.payload;
        },
        setCompletedTransactionsError(state, action: PayloadAction<any>) {
            state.completed.error = action.payload;
        },
        // All
        setAllTransactionsLoading(state, action: PayloadAction<boolean>) {
            state.all.loading = action.payload;
        },
        setAllTransactionsData(state, action: PayloadAction<TransactionResponse>) {
            state.all.data = action.payload;
        },
        setAllTransactionsError(state, action: PayloadAction<any>) {
            state.all.error = action.payload;
        },
        // Downloadable Transactions
        setDownloadableTransactionsLoading(state, action: PayloadAction<boolean>) {
            state.downloadable.loading = action.payload;
        },
        setDownloadableTransactionsData(state, action: PayloadAction<any>) {
            state.downloadable.data = action.payload;
        },
        setDownloadableTransactionsError(state, action: PayloadAction<any>) {
            state.downloadable.loading = action.payload;
        },
        // Vault
        setVaultTransactionsLoading(state, action: PayloadAction<any>) {
            state.vault.loading = action.payload;
        },
        setVaultTransactionsData(state, action: PayloadAction<any>) {
            state.vault.data = action.payload;
        },
        setVaultTransactionsError(state, action: PayloadAction<any>) {
            state.vault.error = action.payload;
        },
        // Rewards
        setRewardsTransactionsLoading(state, action: PayloadAction<boolean>) {
            state.rewards.loading = action.payload;
        },
        setRewardsTransactionsData(state, action: PayloadAction<TransactionResponse>) {
            state.rewards.data = action.payload;
        },
        setRewardsTransactionsError(state, action: PayloadAction<any>) {
            state.rewards.error = action.payload;
        },
        // Rebalance
        setTransactionRebalanceLoading(state, action: PayloadAction<boolean>) {
            state.rebalance.loading = action.payload;
        },
        setTransactionRebalanceData(state, action: PayloadAction<TransactionRebalance>) {
            state.rebalance.data = action.payload;
        },
        setTransactionRebalanceError(state, action: PayloadAction<any>) {
            state.rewards.error = action.payload;
        },
    },
});

export const {
    setPendingTransactionsLoading,
    setPendingTransactionsData,
    setPendingTransactionsError,
    setCompletedTransactionsLoading,
    setCompletedTransactionsData,
    setCompletedTransactionsError,
    setAllTransactionsLoading,
    setAllTransactionsData,
    setAllTransactionsError,
    setDownloadableTransactionsLoading,
    setDownloadableTransactionsData,
    setDownloadableTransactionsError,
    setVaultTransactionsLoading,
    setVaultTransactionsData,
    setVaultTransactionsError,
    setRewardsTransactionsLoading,
    setRewardsTransactionsData,
    setRewardsTransactionsError,
    setTransactionRebalanceLoading,
    setTransactionRebalanceData,
    setTransactionRebalanceError,
} = transactions.actions;

export default transactions.reducer;
