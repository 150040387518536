import { transparentize } from 'polished';
import { Theme } from '.';
import colors from '../colors';

const theme: Theme = {
    'blue-grey': colors['blue-grey'],
    bodyBackground: colors.white,
    background: colors['ghost-white'],
    background50: colors['bg-light-50'],
    inputBackground: colors['light-gray'],
    inputText: colors['text-grey'],
    inputBorder: '', // NOTE: inputBorder was added for the dark theme and then added here to stop an ts error
    bodyText: colors['off-black'],
    headerText: colors.black,
    primary: colors['vivid-blue'],
    primaryButton: colors['primary-blue'],
    primaryBlueGradient: colors['primary-blue-gradient'],
    primaryHover: colors['vivid-blue-hover'],
    secondary: colors['secondary-blue'],
    secondaryHover: colors['secondary-blue-hover'],
    secondaryDark: colors['blue-grey'],
    tertiary: colors.white,
    tertiaryHover: colors.white,
    success: colors.spearmint,
    error: colors['orange-red'],
    buttonText: colors.white,
    outlinedButtonText: colors['vivid-blue'],
    customLoader: colors['vivid-blue'],
    backgroundBlue: colors.tierBlueGradient,
    backgroundSilver: colors.tierSilverGradient,
    backgroundGold: colors.tierGoldGradient,
    backgroundPlatinum: colors.tierPlatinumGradient,
    backgroundVip: colors.tierVIPGradient,
    rewardsDarkBlue: colors['vivid-blue'],
    rewardsLightBlue: colors['cyan-light'],
    rewardsGradient: colors.rvxGradient,
    skeletonLoaderFrom: colors['light-gray'],
    skeletonLoaderTo: transparentize(0.75, colors['light-gray']),
    txHistoryBackground: colors['blue-bayoux'],
    backgroundBtc: colors.btcGradient,
    secondaryButton: colors['secondary-button'],
    secondaryBlue: colors['secondary-blue'],
    priceRed: colors['price-red'],
    priceGreen: colors['price-green'],
};

export default theme;
